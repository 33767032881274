<template>
  <!-- 告警详情 -->
  <div class="panel-gjxq">
    <Panel :panelParams="panelParams">
      <Tab :tabData="tabData" />
      <ul class="list-container">
        <li>
          <span class="label">设备名称</span>
          <span>{{gjxqInfo.deviceName}}</span>
        </li>
        <template v-for="item in gjxqInfo.errorMsg">
          <li :key="item.error">
            <span class="label">告警等级:</span>
            <span>{{ item.level }}</span>
          </li>
          <li :key="item.error">
            <span class="label">告警内容:</span>
            <span>{{ item.cnError || item.error }}</span>
          </li>
        </template>
      </ul>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Tab from "@/components/Tab.vue";
import List from "@/components/List.vue";
export default {
  props: ["gjxqInfo"],
  components: {
    Panel,
    Tab,
    List
  },
  data() {
    return {
      tabData: ["告警详情"],
      panelParams: {
        boxClass: "panel-3",
        isShowClose: true,
        closeType: "isShowPanelGjxq"
      },
      listData: [
        { label: "设备名称", value: "", prop: "device" },
        // { label: "设备编号", value: "", prop: "deviceNo" },
        { label: "告警等级", value: "", prop: "errorLevel" },
        // { label: "告警类别", value: "", prop: "errorType" },
        { label: "告警内容", value: "", prop: "errorMessage" },
        { label: "告警时间", value: "", prop: "createDate" }
      ],
      deviceInfo: {}
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.panel-gjxq {
  /deep/ .panel-container {
    background: rgba(112, 12, 1, 0.7);
    padding: 42px 0 0;
    .tab-container {
      background: rgba(194, 29, 11, 0.7);
      border-bottom: 1px solid #fff !important;
      li {
        color: #eef4fd;
      }
      // .active {
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     left: 0;
      //     bottom: 0;
      //     width: 88px;
      //     height: 4px;
      //     @include backgroundImg("../assets/img/title_line_l_r.png");
      //   }
      // }
    }
    .el-icon-close {
      color: rgb(223, 66, 69) !important;
    }
    .list-container {
      width: 100%;
      height: 100%;
      padding: 0 20px 10px;
      overflow-y: auto;
      word-break: break-all;
      span {
        color: #fff;
        &:nth-child(2) {
          flex: 1;
        }
      }
    }
    .el-icon-close {
      color: #fff !important;
    }
  }
}
</style>