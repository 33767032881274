<template>
  <!-- 堆垛机历史总里程 -->
  <div class="chart5-container">
    <Tab :tabData="tabData"></Tab>
    <div class="container">
      <span>5416109</span>
      <span>km</span>
    </div>
  </div>
</template>

<script>
let sylqsChart;
import Tab from "@/components/Tab.vue";
export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabData: ["堆垛机历史总里程"],
    };
  },
  mounted() {},
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
.chart5-container {
  width: 100%;
  height: 120px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 40px;
  background: rgba(30, 45, 75, 0.7);
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 40px;
    > span:nth-child(1){
      font-size: 30px;
      font-weight: normal;
      font-family: pmzd2;
      line-height: 24px;
      color: $font-color;
      margin-right: 20px;
    }
    > span:nth-child(2){
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $font-color4;
    }
  }
}
</style>