<template>
  <div class="bar-container">
    <div class="top-wrap">
      <span>DMU160P-3Z状态摘要</span>
      <span>一周</span>
    </div>
    <div :id="barId" class="echart-box"></div>
    <div class="time">13:53</div>
  </div>
</template>

<script>
export default {
  props: {
    barId: {
      type: String
    },
    flag: {
      type: String
    },
    barList: {
      type: Array,
      default: () => [
       
      ]
    },
    title: {
      type: String
    }
  },
  watch: {
    barList: {
      handler(val) {
        this.initChart();
        // console.log(val)
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 基于准备好的dom，初始化echarts实例
      const barDom = document.getElementById(this.barId);
      this.barEchart = this.$echarts.init(barDom);
      window.addEventListener("resize", this.resize);
      this.initChart();
    });
  },
  methods: {
    resize() {
      this.barEchart.resize();
    },
    initChart() {
      // 绘制图表
      this.barEchart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: "10%",
          left: "3%",
          right: "4%",
          bottom: "6%",
          containLabel: true
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
              fontWeight: "bold",
            //   fontSize: '16px'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EAEAEA"
            }
          },
          splitLine: {
            lineStyle: {
              color: "#EAEAEA",
            //   opacity: 0.1
            },
            show: true
          }
        },
        xAxis: {
          type: "category",
          data: ["06/02", "06/03", "06/04", "06/05", "06/06", "06/07", "06/08"],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
              fontWeight: "bold",
            //   fontSize: '16px'
            },
            interval: 1
            // rotate: 20,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EAEAEA"
            }
          }
        },
        color: [
          "#FF5A7E",
          "#FFA26F",
          "#4BC693",
          "#7F5CFF",
          "#66D2FF",
          "#e6e6e6",
          "#111111"
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            stack: "total",
            label: {
              show: false
            },
            emphasis: {
              focus: "series"
            },
            barWidth: "32px",
            data: [320, 302, 10, 334, 390, 330, 320]
          },
          {
            name: "Mail Ad",
            type: "bar",
            stack: "total",
            label: {
              show: false
            },
            emphasis: {
              focus: "series"
            },
            barWidth: "32px",
            data: [120, 132, 0, 134, 90, 0, 210]
          },
          {
            name: "Affiliate Ad",
            type: "bar",
            stack: "total",
            label: {
              show: false
            },
            emphasis: {
              focus: "series"
            },
            barWidth: "32px",
            data: [0, 182, 191, 234, 290, 330, 310]
          },
          {
            name: "Video Ad",
            type: "bar",
            stack: "total",
            label: {
              show: false
            },
            emphasis: {
              focus: "series"
            },
            barWidth: "32px",
            data: [150, 212, 201, 154, 190, 330, 410]
          },
          {
            name: "Search Engine",
            type: "bar",
            stack: "total",
            label: {
              show: false
            },
            emphasis: {
              focus: "series"
            },
            barWidth: "32px",
            data: [0, 832, 901, 934, 1290, 1330, 1320]
          }
        ]
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px 0;
  position: relative;
  .top-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: Source Han Sans SC VF;
    font-weight: bold;
    color: #333333;
  }
  .echart-box {
    width: 90%;
    height: 250px;
  }
  .time {
    position: absolute;
    right: 13px;
    bottom: 13px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666666;
  }
}
</style>