<template>
  <!-- 加工中心利用率历史 -->
  <div class="chart7-container">
    <Tab :tabData="tabData">
      <el-dropdown class="xf-dropdown" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ selectValue }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="加工中心1">加工中心1</el-dropdown-item>
          <el-dropdown-item command="加工中心2">加工中心2</el-dropdown-item>
          <el-dropdown-item command="加工中心3">加工中心3</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </Tab>
    <div id="chart7"></div>
  </div>
</template>

<script>
let chart7;
import Tab from "@/components/Tab.vue";
import DateSwitch from "@/components/DateSwitch.vue";
export default {
  components: {
    Tab,
    DateSwitch,
  },
  data() {
    return {
      tabData: ["加工中心利用率历史"],
      selectValue: "加工中心1",
    };
  },
  mounted() {
    chart7 = this.$echarts.init(document.getElementById("chart7"));
    this.handleChart(10);
    window.addEventListener("resize", this.chartResize);
  },
  methods: {
    handleCommand(command) {
      this.selectValue = command;
    },
    chartResize() {
      chart7.resize();
    },
    //使用率趋势
    handleChart(dateType) {
      // 绘制图表
      chart7.setOption({
        grid: {
          left: "12%",
          right: "15%",
          bottom: "18%",
          top: "15%",
          containLabel: false,
        },
        tooltip: {
          trigger: "axis",
          //在这里设置
          formatter: "{b0}:{c0}",
          textStyle: {
            // 提示框内容的样式
            color: "#fff",
            fontSize: 14,
          },
        },
        xAxis: {
          type: "category",
          name: "(小时)",
          data: ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
          boundaryGap: false, //坐标轴两边是否留白
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
              color: "rgba(151,217,242,.4)", //x轴颜色
            },
          },
          axisLabel: {
            show: true,
            //坐标轴刻度标签的相关设置
            textStyle: {
              color: "rgba(151,217,242,.4)",
            },
            // interval:0,
            // rotate:40
          },
          axisTick: {
            show: false,
          }, //刻度点数轴
        },
        yAxis: {
          type: "value",
          name: "",
          nameTextStyle: {
            color: "rgba(151,217,242,.4)",
          },
          splitLine: {
            //y轴上的y轴线条相关设置
            show: false,
            lineStyle: {
              color: "#233e64",
            },
          },
          axisLine: {
            //y轴的相关设置
            show: true,
            lineStyle: {
              color: "rgba(151,217,242,.4)", //y轴颜色
            },
          },
          axisLabel: {
            //y轴的标签相关设置
            textStyle: {
              color: "rgba(151,217,242,.4)",
              //   fontSize:18
            },
          },
          axisTick: {
            show: false,
          }, //刻度点数轴
        },
        series: [
          {
            data: ["75", "389", "595", "26", "272", "272", "627", "622", "672"],
            type: "line",
            smooth: true, //是否平滑曲线显示
            symbolSize: 0, //数据点的大小，[0,0]//b表示宽度和高度
            // 背景色渐变色
            lineStyle: {
              //线条的相关设置
              normal: {
                color: "#2EC6FF", // 线条颜色
              },
            },
            areaStyle: {
              // color: '3deaff',
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(46, 198, 255, 0.8)",
                },
                {
                  offset: 0.4,
                  color: "rgba(46, 198, 255, 0.3)",
                },
                {
                  offset: 1,
                  color: "rgba(46, 198, 255, 0.0)",
                },
              ]),
            },
          },
        ],
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.chartResize);
  },
};
</script>

<style lang="scss" scoped>
.chart7-container {
  width: 100%;
  height: 272px;
  margin-bottom: 10px;
  padding-top: 40px;
  position: relative;
  background: rgba(30, 45, 75, 0.7);
  /deep/ .tab-container {
    padding-right: 20px;
    .active {
      flex: 1;
    }
  }
  .xf-dropdown {
    height: 20px;
  }
  #chart7 {
    width: 100%;
    height: 100%;
  }
}
</style>