export default class Xfviewer extends AMRT.APPViewer {

  constructor(container, options = {}, vue) {

    super(container, options);

    this.vue = vue;

    // this.deviceObjList = [];
    // this.materialObjList = [];
    // this.tpObjList = [];

    this.outLineList = [];

    this.copyTp = null;
    this.copyWlhtp = null;

    this.hot = null;
    this.deviceModels = [];

    this.isMove = true; //是否有移动事件
    this.isClick = true; //是否有点击事件
    // this.isHidden = false; //是否在隐藏状态

    this.BASE_URL =
      location.href.indexOf("index.html") > -1 ?
      location.href.split("index.html")[0] :
      location.origin + location.pathname;

    this.BASE_URL = this.BASE_URL + "/model2/";

    this.defaultView = {
      // position: [10.18442741890237, 24.3939015798787, -31.771154099218002],
      // target: [9.86989029677914, -0.7470022155282396, -0.5013092980020437]
      // position: [8.313843672333586, 16.525998203751953, 25.250758419807838],
      // target: [9.86989029677914, -0.7470022155282396, -0.5013092980020437]
      // position: [-25.993489015308256, 18.834067292776467, 25.17486001069125],
      // target: [-26.173940477091712, 0.2893990588912799, -1.735135017293071]

      position: [-25.24363101887076, 24.502878598851577, 29.506435901016026],
      target: [-24.817556349065978, -0.003895803042832144, -2.261529723707793]
    };

    this.roamFreeView = {
      position: [-34.697688052969305, 1.3907009076580685, 9.846590196748446],
      target: [-34.69768805296924, 0.048452673781402944, -0.6414535984999572]
    };

  }

  // 初始化视图
  initView() {
    return new Promise(resolve => {

      this.vue.$parent.loading = true;

      this.loadModel(this.BASE_URL, {
        // this.loadModel('1537279445193330688', {
        offline: true,
        onLoad: (m) => {
          window.model1 = m;
          const XF = this.findModel('XF');
          XF.position.x = XF.position.x -= 49.350;
          XF.position.y = XF.position.y -= 0.45;
          XF.position.z = -2.9048;

          this.copyTp = this.findModel('TP_DiZuo1').clone();
          this.copyWlhtp = this.findModel('whl_1001').clone();

          this.findModel('Cylinder210').visible = false;
          this.findModel('Cylinder209').visible = false;
          // 默认移入装载站底座
          this.findModel('jiqi005').position.y += 1700;
          this.findModel('jiqi004').position.y += 1700;
          // 加工中心台面统一移出一点
          const list = ["JGZXTP030", "JGZXTP031", "JGZXTP032", "JGZXTP033", "JGZXTP034", "JGZXTP035"];
          list.forEach(m => {
            const _m = viewer.findModel(m);
            _m.position.y = -1321;
          })

          viewer.camera.near = 0.1;
          viewer.camera.updateProjectionMatrix();

          const huojiahezi = this.findModel('huojiahezi');
          huojiahezi.parent.remove(huojiahezi);

          this.setCameraView(this.defaultView);

          console.log("模型加载完成");

          //最大俯仰角度
          this.controls.maxPolarAngle = 90;
          //最小俯仰角度
          this.controls.minPolarAngle = 0;

          this.container.onclick = (e) => {
            const obj = this.intersectObject(e);
            if (obj) {
              console.log(obj.object)
              if (obj.object.name.includes("-")) {
                this.vue.$parent.getMachineErrorById(obj.object.userData);
              }
            }
          };

          // // 防止转动模型时，触发move和click事件
          // this.container.onmousedown = (e) => {
          //   this.isMove = false; //是否有移动事件
          //   this.isClick = false; //是否有点击事件
          // };
          // this.container.onmouseup = (e) => {
          //   if (!this.isHidden) {
          //     this.isMove = true; //是否有移动事件
          //     this.isClick = true; //是否有点击事件
          //   }
          // };

          this.vue.$parent.loading = false;

          // this.createInfoHot();

          // this.createPlayHot();

          // this.createVideoTexture();

          // this.modelRename();

          // this.tpBindData();

          // this.animation();

          this.outline.options.thickness = 1;
          this.outline.options.strength = 3;
          this.outline.options.glow = 0.5;

          this.enableOutline = true;

          // setTimeout(() => {
          //   this.findModel("wlj").visible = false;
          // }, 1000)

          // 隐藏锥形体
          this.hiddenCone();

          // 记录毛坯位置，最后放回去
          // const mp0 = this.findModel("maopi_00");
          // mp0.rotation2 = mp0.rotation.clone();
          // mp0.position2 = mp0.position.clone();

          resolve();
        },
        onProgress: (pct) => {
          console.log("模型加载进度：" + pct + "%");
        },
      });

      this.addEventListener(AMRT.Event.UPDATE, (e) => {
        // 滚轮滚动
        if (this.findModel("gunlun008")) {
          // this.findModel("gunlun008").children.forEach((item) => {
          //   item.rotation.x += 0.01;
          // });
          // this.findModel("gunlun007").children.forEach((item) => {
          //   if (!item.name.includes("wlh")) item.rotation.x += 0.01;
          // });
          // this.findModel("gunlun002").children.forEach((item) => {
          //   if (!item.name.includes("wlh")) item.rotation.x += 0.01;
          // });
          // const CSD = this.findModel("CSD");
          // if (CSD && CSD.material[0].albedo) {
          //   CSD.material[0].albedo.offset.y += 0.01;
          // }
        }
      });
    })
  }

  // 对托盘底座编号


  //  设备模型重命名，同时关联上数据
  // modelRename() {
  //   this.vue.deviceList.forEach((item) => {
  //     const obj = this.findModel(item.modelName);
  //     if (obj) {
  //       // 设备改名
  //       obj.name = item.name;
  //       obj.userData = item;
  //       this.deviceObjList.push(obj);
  //     }
  //   });

  // this.vue.wlhs.forEach((obj, index) => {
  // 对物料盒改名
  // obj.name = "wlh" + (index + 1);
  // obj.userData = this.vue.deviceList.find(
  //   (f) => f.name === "wuliaoku"
  // );
  // this.materialObjList.push(obj);
  // 记录在货架上的位置
  // const pos = obj.getWorldPosition(new AMRT.Vector3());
  // obj.worldPosition = pos.clone();
  // obj.children.forEach((citem) => {
  //   const pos2 = citem.getWorldPosition(new AMRT.Vector3());
  //   citem.worldPosition = pos2.clone();
  // });
  // });

  // 托盘
  // this.vue.tps.forEach((obj, index) => {

  //   this.tpObjList.push(obj);

  // });
  // }

  // 给托盘绑定接口数据
  // tpBindData() {
  //   // 托盘
  //   this.findModel("TP").children.forEach((obj, index) => {
  //     const pos = obj.getWorldPosition(new AMRT.Vector3());
  //     obj.worldPosition = pos.clone();
  //     obj.name = "tp" + (index + 1);
  //     obj.userData = this.vue.palletList[index];
  //   });
  // }

  // 模拟堆垛机动画
  // async animation() {
  //   for (
  //     let i = 0,
  //       tpList = this.findModel("TP").children,
  //       bcpList = this.findModel("BCP").children; i < tpList.length; i++
  //   ) {
  //     await craneAnimation2(
  //       tpList[i].name,
  //       bcpList[i] ? bcpList[i].name : bcpList[0].name,
  //       bcpList[i] ?
  //       bcpList[i].children[0].name :
  //       bcpList[0].children[0].name
  //     );

  //     if (i === tpList.length - 1) i = 0;
  //     console.log(i);
  //   }
  // }
  hiddenCone() {
    const models = ['hong', 'lv', 'huang', 'hui'];
    models.forEach(item => {
      this.findModel(item).visible = false;
    });
    // const wlhsAndTps = [...this.vue.wlhs, ...this.vue.tps];
    // wlhsAndTps.forEach(item => {
    //   if (!item.userData || JSON.stringify(item.userData) === '{}') {
    //     item.parent.remove(item);
    //   }
    // });

  }
  // 生成设备状态热点
  createDeviceHot(hotList) {
    //热点标签 lv,hong,huang,hui
    let lv = this.findModel('lv').clone();
    let hong = this.findModel('hong').clone();
    let huang = this.findModel('huang').clone();
    let hui = this.findModel('hui').clone();
    hotList.forEach((obj) => {
      const item = obj.userData
      if (obj) {
        // Manual 手动
        // Busy,Idle,Manual,Automatic,Unknown,NoConnetion,Error
        let sprite = hui.clone();
        if (item.status === "Manual" || item.status === "Automatic" || item.status === "Busy") {
          sprite = lv.clone();
        } else if (item.status === "Unknown" || item.status === "NoConnetion" || item.status === "Idle") {
          sprite = huang.clone();
        } else if (item.status === 'Error') {
          sprite = hong.clone();
        }
        // 单独处理去屑系统和冷却液系统
        if (item.status === true) {
          sprite = lv.clone();
        } else if (item.status === false) {
          sprite = hui.clone();
        }

        //获取当前相机视角
        let pos = new AMRT.Vector3(0, 0, 0);

        // 设备状态图标
        // let sprite = AMRT.EntityFactory.createSprite(img, 1);
        sprite.visible = true;
        sprite.name = item.name + "-sprite";
        sprite.userData = obj;
        sprite.position.copy(pos.clone());

        // sprite.scale.copy(new AMRT.Vector3(1200, 1200, 1200));

        const list = ['CX3', 'CX4', 'CX5', 'CX6', 'CX7', 'CX8'];
        if (item.name === "Crane") {
          sprite.position.y += 350;
        } else if (list.some(s => item.name.includes(s))) {
          sprite.position.y -= 1000;
          sprite.position.z += 1000;
        } else {
          sprite.position.z += 2000;
        }

        obj.add(sprite);

        if (item.param > 0) {
          // 生成进度条
          const num = item.param;
          let color;
          if (num <= 0) {
            color = "#949494";
          } else if (num < 100) {
            color = "#FFDB27";
          } else {
            color = "#2EC6FF";
          }
          const canvas = this.drawProgress(num, color);
          let img = canvas.toDataURL("image/png", 1);
          let sprite2 = AMRT.EntityFactory.createSprite(img, 1);
          sprite2.name = item.name + "_process";
          sprite2.scale.copy(new AMRT.Vector3(4500, 650, 120));
          sprite2.position.copy(new AMRT.Vector3(0, 4000, 500));
          obj.add(sprite2);
        }
      }

    });
  }
  // 移除设备状态热点
  removeSprite() {
    this.vue.deviceList.forEach((item) => {
      const obj = this.findModel(item.name);
      if (obj) {
        this.findModel(item.name).remove(this.findModel(item.name + "-sprite"));
        this.findModel(item.name).remove(this.findModel(item.name + "_process"));
      }
    });
  }
  // 生成info热点
  createInfoHot(i) {
    const ele = document.querySelector(".info-box");
    // //默认添加到默认图层
    this.hot = this.hotSpotManager.add({
      element: ele,
      position: new AMRT.Vector3(0, 0, 0),
      // objectId: obj.id,
      cameraView: this.getCameraView(),
      anchor: AMRT.Anchor.TOP,
      offset: {
        x: 0,
        y: 80
      },
      userData: null,
    });
    this.hot.hide();

    const ele2 = document.querySelector(".tp-info-box");
    // //默认添加到默认图层
    this.tpHot = this.hotSpotManager.add({
      element: ele2,
      position: new AMRT.Vector3(0, 0, 0),
      // objectId: obj.id,
      cameraView: this.getCameraView(),
      anchor: AMRT.Anchor.TOP,
      offset: {
        x: 0,
        y: 80
      },
      userData: null,
    });
    this.tpHot.hide();

    const ele3 = document.querySelector(".wltp-info-box");
    // //默认添加到默认图层
    this.wltpHot = this.hotSpotManager.add({
      element: ele3,
      position: new AMRT.Vector3(0, 0, 0),
      // objectId: obj.id,
      cameraView: this.getCameraView(),
      anchor: AMRT.Anchor.TOP,
      offset: {
        x: 0,
        y: 80
      },
      userData: null,
    });
    this.wltpHot.hide();
  }
  // 生成视频播放热点
  createPlayHot() {
    this.vue.deviceList.forEach(item => {
      if (item.name.includes('MC')) {
        const ele = `
        <img src="${require('../assets/new/play.png')}" title="点击播放加工视频" class="play-hot"></img>`
        //获取当前相机视角
        let cameraView = this.getCameraView();
        const obj = this.findModel(item.name);
        let pos = obj.getWorldPosition(new AMRT.Vector3());
        // //默认添加到默认图层
        const hot = this.hotSpotManager.add({
          element: ele,
          position: pos,
          objectId: obj.id,
          cameraView: cameraView,
          anchor: AMRT.Anchor.CENTER,
          offset: {
            x: 0,
            y: 0
          },
          userData: {
            name: item
          },
          onclick: () => {
            this.vue.$parent.open('isShowPanelVideo', {
              device: item.name
            });
          }
        });
        hot.hide();
        item.videoHot = hot;
      }
    })
  }
  // 移动显示info和outline
  onMousemove(e) {
    this.hot && this.hot.hide();
    this.tpHot && this.tpHot.hide();
    this.wltpHot && this.wltpHot.hide();
    this.outline.clear();

    if (!this.isMove) return;
    for (let item of this.outLineList) {
      let target = this.intersectObject(e, item);
      if (target && item.getObjectById(target.object.id) !== undefined) {

        this.outline.add(item);

        const info = this.vue.infoList.find(f => f.name === item.name);
        if (info) {
          this.vue.equInfo = info;
          this.hot.position.copy(item.getWorldPosition(new AMRT.Vector3()));
          this.hot.show();
        } else if (item.userData.palletId) {
          if (item.userData.palletId < 100) {
            
            this.vue.tpInfo = item.userData;
            console.log(item.userData)
            
            this.tpHot.position.copy(item.getWorldPosition(new AMRT.Vector3()));
            this.tpHot.show();
          } else {
            this.vue.mltpInfo = item.userData;
            console.log(item.userData)
            this.wltpHot.position.copy(item.getWorldPosition(new AMRT.Vector3()));
            this.wltpHot.show();
          }
        
        }
        break;
      }
    }

  }
  commonFn() {
    this.removeSprite();
    this.outline.clear();
    this.isMove = false;
    this.isClick = false;
    if (this.vue.timer) clearInterval(this.vue.timer);
    this.vue.$bus.$emit("on-close");
  }
  // 双击模型
  dblclickModel(e) {
    console.log(e, 'click')
    console.log('双击事件')
    if (!this.isClick) return;

    let obj = this.intersectObject(e);
    if (obj) {
      if (obj.object.name.includes("-sprite")) return;
      obj = obj.object;
    }

    // 物料盒
    for (let item of this.vue.wlhs) {
      let target = this.intersectObject(e, item);
      if (target && target.object.visible && item.getObjectById(target.object.id) !== undefined && !item.parent.name.startsWith('gunlun')) {
        console.log('双击普通物料盒');

        this.commonFn();
        this.hiddenModel(item, 2);
        let box = this.initModelBox3(item);
        const pos = box.getCenter();
        let position = this.controls._fitCamera(item).add(pos);

        this.controls.flyTo({
          target: pos, //目标物体
          position,
          time: 1000, //飞行时间
          complete: function () {
            console.log("飞行结束");
          },
        });
        this.showDialog(item);
        this.pause();
        // this.controls.maxPolarAngle = 180;
        return;
      }
    }

    // 托盘
    for (let item of this.vue.tps) {
      let target = this.intersectObject(e, item);
      let list = ['jiqi', 'jgtp', 'DDJ5-1'];
      if (target && target.object.visible && item.getObjectById(target.object.id) !== undefined && list.every(i => !item.parent.name.startsWith(i))) {
        console.log('双击普通托盘');

        this.commonFn();
        this.hiddenModel(item, 2);
        this.controls.flyTo({
          object: item,
          time: 1000, //飞行时间
          complete: function () {
            console.log("飞行结束");
          },
        });
        this.showDialog(item);
        this.pause();
        // this.controls.maxPolarAngle = 180;
        return;
      }
    }

    // 设备
    for (let item of this.deviceModels) {
      // for (let item of this.vue.deviceList) {
      // item = item;
      let target = this.intersectObject(e, item);
      if (target && target.object.visible && item.getObjectById(target.object.id) !== undefined) {
        console.log('双击设备');

        this.commonFn();

        // 去屑系统做单独处理
        let n = 1;
        if (target.object.parent.name.includes('CX')) n = 2;

        this.hiddenModel(item, n);
        let box = this.initModelBox3(item);
        const pos = box.getCenter();
        let position = this.controls._fitCamera(item).add(pos);

        this.controls.flyTo({
          target: pos, //目标物体
          position,
          // object: item,
          time: 1000, //飞行时间
          complete: function () {
            console.log("飞行结束");
          },
        });
        this.showDialog(item);
        this.pause();

        // this.controls.maxPolarAngle = 180;
        return;
      }
    }
  }
  // 隐藏全部模型
  hiddenModel(obj, type) {
    if (type === 1) {
      this.findModel("XF").children.forEach((item) => {
        if (obj.name !== item.name) {
          item.visible = false;
        }
      });
    } else {
      let name = obj.name;
      console.log(obj, 'obj')
      obj.traverseAncestors(item => {
        if (name === 'XF') return;
        item.children.forEach(cItem => {
          if (cItem.name !== name) {
            cItem.visible = false;
          }
        })
        name = item.name;
      })
    }
    // this.isHidden = true;
  }
  // 显示全部模型
  showModel() {
    this.findModel("XF").children.forEach((item) => {
      const list = ['hong', 'lv', 'huang', 'hui'];
      if (list.some(s => s === item.name)) return;
      item.visible = true;
    });
    this.findModel('wuliaohe00').children.forEach((item) => {
      item.visible = true;
      item.children.forEach(cItem => cItem.visible = true);
    });
    this.findModel('TP').children.forEach((item) => {
      item.visible = true;
      item.children.forEach(cItem => cItem.visible = true);
    });
    this.findModel('CX').children.forEach((item) => {
      item.visible = true;
      item.children.forEach(cItem => cItem.visible = true);
    });
    this.findModel('Crane').traverse(t => t.visible = true);

    // this.isHidden = false;

    this.vue.$parent.percentage = '0';
  }
  // 显示设备弹窗
  showDialog(obj) {
    const devices = ["RFC", "MC1", "MC2", "MC3", "MC4", "MC5", "MC6"];
    const otherDevices = [
      "Crane",
      "ccrsstatus",
      "wuliaoku",
      "fzj",
      "M1",
      "M2",
      "M3",
      "ykj",
      "L1",
      "L2"
    ];
    if (devices.includes(obj.name)) {

      this.vue.$parent.getDataByDevice(obj.userData, 1);

      if (!obj.name.includes('RFC')) {

        this.vue.isShowVideoBtn = true;
        this.vue.device = obj.name;
      }

    } else if (otherDevices.includes(obj.name)) {

      this.vue.$parent.getDataByDevice(obj.userData, 2);

    // } else if (obj.name.includes("whl")) {

    //   // this.$parent.getDataByIndex0(obj.name, obj.userData.name);
    //   this.vue.$parent.getDataByMaterial(obj.userData);

    } else if (obj.name.includes("TP") || obj.name.includes("whl")) {

      this.vue.$parent.getDataByTp(obj.userData);
    }

    window.addEventListener("keydown", this.vue.handleEscEvent, false);
  }

  findModel(name) {
    return this.modelGroup.getObjectByName(name);
  }


  // pallets   Crane.Storage.1020
  // palletatmc  MC5.Table



  //生成模型包围盒
  initModelBox3(model) {
    let box = new AMRT.Box3();
    box.setFromObject(model);
    return box;
  }

  handleDefaultView(view = this.defaultView, cb) {
    this.controls.flyTo({
      ...view,
      time: 1000,
      complete: () => {
        console.log("默认视角");
        cb && cb();
      },
    });
    this.controls.maxPolarAngle = 90;
  }

  // 播放动画
  play() {
    if (window.tween) {
      console.log('开始动画')
      window.tween.start();
    }
  }

  // 暂停动画
  pause() {
    if (window.tween) {
      console.log('暂停动画')
      window.tween.stop();
    }
  }

  //  生成视频贴图
  createVideoTexture() {
    let video = document.createElement("video");
    video.src = process.env.BASE_URL + 'video.mp4';

    video.autoplay = true
    video.muted = true;
    video.loop = true;

    const list = ['shipin005', 'shipin006', 'shipin007', 'shipin008', 'shipin009', 'shipin010'];
    const texture = new AMRT.VideoTexture(video);

    const mat = new AMRT.MeshBasicMaterial({
      side: 1,
      map: texture
    })
    mat.map.center = new AMRT.Vector2(0.5, 0.5)
    mat.map.rotation = -Math.PI / 2;
    list.forEach(item => {
      let object = this.findModel(item);
      // object.position.x += 200

      object.material = [mat];
    })
    // video.play();
  }

  // 画进度条
  drawProgress(progress, color = '#ffdb27') {
    const canvas = document.createElement('canvas');
    canvas.width = 220;
    canvas.height = 18;
    const ctx = canvas.getContext('2d');
    // 清空画布(每次清空画布重新绘制，才能更新)
    // ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.shadowBlur = 20;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowColor = color;
    //前两个参数表示矩形左上角的点的x,y坐标，w,h表示矩形的宽高。
    ctx.strokeStyle = color; // 画笔颜色

    ctx.beginPath();
    ctx.lineWidth = 2; // 设置线宽
    ctx.lineTo(0, 0);
    ctx.lineTo(168, 0);
    ctx.lineTo(168, 18);
    ctx.lineTo(0, 18);
    ctx.lineTo(0, 0);
    ctx.stroke();
    ctx.closePath();

    // // 开始绘制绿色线段
    ctx.beginPath();
    ctx.lineWidth = 14; // 设置线宽
    ctx.lineTo(2, 9);
    ctx.lineTo(166 * progress / 100, 9);
    ctx.stroke();
    ctx.closePath();

    // 开始绘制变动的数字
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.fillStyle = color;
    ctx.font = '16px Source Han Sans CN';
    ctx.fillText(parseInt(progress) + '%', 176, 15);
    ctx.fill();
    ctx.closePath();

    return canvas;
  }

}