<template>
  <div class="container">
    <div class="top-wrap">
      <div class="wrap" v-for="item in topList" :key="item.key">
        <div class="flex-wrap" :style="{'background': item.color}">
          <div class="top">
            <span>{{item.name}}</span>
            <span>{{item.state}}</span>
          </div>
          <div class="middle" v-if="item.num || item.num === 0">
            <div>{{item.num}}</div>
            <img src="../../assets/new2/1.png" alt />
          </div>
          <div class="bottom">
            <div class="line" :style="{'width': item.width}"></div>
            <span v-if="item.name2">{{item.name2}}</span>
            <span v-if="item.num2 || item.num2 === 0">{{item.num2}}</span>
            <div class="time" v-if="item.time">{{item.time}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-wrap">
      <div class="wrap" v-for="item in bottomList" :key="item.key">
        <div class="flex-wrap" :style="{'background': item.color}">
          <div class="top">
            <span>{{item.name}}</span>
            <span>{{item.time2}}</span>
          </div>
          <div class="middle">
            <div v-if="item.des">{{item.des}}</div>
            <div>{{item.time}}</div>
          </div>
          <div class="bottom">
            <div>{{item.des2}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topList: [
        {
          name: "160P-3",
          state: "忙碌",
          num: 2,
          color: "#FF576B",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "未知",
          num: 2,
          color: "#96A7BB",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "手动",
          num: 2,
          color: "#1CDDF9",
          width: "20%",
          name2: "Y955Z0601"
        },
        {
          name: "160P-3",
          state: "未知",
          num: 2,
          color: "#96A7BB",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "手动",
          num: 2,
          color: "#1CDDF9",
          width: "20%",
          name2: "Y955Z0601"
        },
        {
          name: "160P-3",
          state: "空转",
          color: "#FFA26F",
          width: "0%",
          name2: "Y955Z0601"
        }
      ],
      bottomList: [
        {
          name: "160P-3",
          des: "机床将停止",
          des2: "刀具遗矢",
          time: "09:20(19小时内)",
          time2: "24h",
          color: "#7F5CFF"
        },
        {
          name: "160P-3",
          des: "机床将停止",
          des2: "刀具遗矢",
          time: "09:20(19小时内)",
          time2: "24h",
          color: "#7F5CFF"
        },
        {
          name: "160P-3",
          des: "机床将停止",
          des2: "刀具遗矢",
          time: "09:20(19小时内)",
          time2: "24h",
          color: "#7F5CFF"
        },
        {
          name: "160P-3",
          des: "下个24h一切正常",
          time2: "24h",
          color: "#19D089"
        },
        {
          name: "160P-3",
          des: "托盘将被跳过",
          des2: "刀具遗矢",
          time: "09:20(19小时内)",
          time2: "24h",
          color: "#FFA025"
        },

        {
          name: "160P-3",
          des: "机床将停止",
          des2: "队列即将为空",
          time: "09:20(19小时内)",
          time2: "24h",
          color: "#7F5CFF"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 158px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .top-wrap {
    display: flex;
    width: 100%;
    height: 33.33%;
    .wrap {
      width: 20%;
      height: 100%;
      padding: 7.5px;
      .flex-wrap {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          display: flex;
          justify-content: space-between;
          padding: 10px;
        }
        .middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          > div {
            width: 74px;
            height: 74px;
            line-height: 74px;
            text-align: center;
            border: 3px solid #ffffff;
            margin-bottom: 20px;
            font-size: 30px;
            font-family: Source Han Sans SC VF;
            font-weight: bold;
          }
          > img {
            width: 36px;
            height: 36px;
          }
        }

        .bottom {
          width: 100%;
          height: 58px;
          background: rgba(0, 8, 38, 0.2);
          border-radius: 0px 0px 10px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          position: relative;
          .line {
            height: 2px;
            background: #ffffff;
            position: absolute;
            left: 0;
            top: 0;
          }
          .time {
            width: 80px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            background: #ffffff;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
  }
  .bottom-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 66.66%;
    .wrap {
      width: 33.33%;
      height: 50%;
      padding: 7.5px;
      .flex-wrap {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          display: flex;
          justify-content: space-between;
          padding: 10px;
        }
        .middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          > div {
            font-size: 32px;
            font-family: Source Han Sans SC VF;
            font-weight: 500;
            &:nth-of-type(1) {
              margin-bottom: 20px;
            }
          }
        }
        .bottom {
          width: 100%;
          height: 52px;
          background: rgba(0, 8, 38, 0.2);
          border-radius: 0px 0px 10px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          position: relative;
          font-size: 18px;
        }
      }
    }
  }
}
</style>