<template>
  <div class="progress-container">
    <div class="progress" :style="{ borderColor: style.background }">
      <div :style="style"></div>
    </div>
    <div class="per" v-if="isShowNum" :style="{ color: style.background }">
      {{ percentage + "%" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: String,
      require: true,
    },
    isShowNum: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style() {
      const obj = {
        width: this.percentage + "%",
        background: "",
      };
      if (this.percentage <= 0) {
        obj.background = "#949494";
      } else if (this.percentage < 100) {
        obj.background = "#FFDB27";
      } else {
        obj.background = "#2EC6FF";
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  .progress {
    height: 18px;
    flex: 1;
    border: 1px solid $font-color;
    padding: 1px;
    margin-right: 10px;
    > div {
      height: 100%;
    }
  }
  .per {
    width: 30px;
  }
}
</style>