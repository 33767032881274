<template>
  <!-- 加工视频 -->
  <div class="panel-video">
    <Panel :panelParams="panelParams">
      <div class="video-box">
        <div class="title">{{ videoObj.title }}加工视频</div>
        <div class="video">
          <video :src="videoObj.url || url" autoplay loop></video>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
export default {
  components: {
    Panel,
  },
  props: ["videoObj"],
  data() {
    return {
      panelParams: {
        boxClass: "panel-8",
        isShowClose: true,
        closeType: "isShowPanelVideo",
      },
      url: process.env.BASE_URL + 'jiagong2.mp4'
    };
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.panel-video {
  /deep/ .panel-container {
    width: 800px;
    height: 500px;
    padding: 10px;
    .el-icon-close {
      top: 32px;
      right: 40px;
    }
    .video-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        margin-bottom: 10px;
      }
      .title {
        width: 100%;
        padding: 16px;
        border-bottom: 1px solid $font-color;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        color: $font-color;
      }
      .video {
        // width: 100%;
        height: calc(100% - 66px);
        padding: 10px;
        > video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}
</style>
