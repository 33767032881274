<!--
 * @Descripttion: 
 * @version: 
 * @Author: lc
 * @Date: 2022-04-28 17:42:17
 * @LastEditors: lc
 * @LastEditTime: 2022-05-27 14:24:21
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: null
    }
  },
  mounted() {
    this.startTime = new Date().getTime();
    this.refresh();
  },
  methods: {
    refresh() {
      setTimeout(() => {
        const curTime = new Date().getTime();
        if (curTime - this.startTime > 60 * 60 * 1000) {
          window.location.reload();
        } else {
          this.refresh();
        }
      }, 1000)
    }
  }
};
</script>
<style lang="scss">
@import "/style/common.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: Source Han Sans CN;
  font-weight: 400;
  background: $background-color;
}
</style>
