<template>
  <div class="line-container">
    <div class="line"></div>
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    }
  }
};
</script>

<style lang="scss" csoped>
.line-container {
  display: flex;
  align-items: center;
  height: 40px;
  .line{
    width: 3px;
    height: 16px;
    background: $font-color;
    margin-right: 10px;
  }
  .title{
    line-height: 14px;
    color: $font-color;
  }
}
</style>