window.config = require('../utils/config.js');

function findModel(name) {
  return viewer.modelGroup.getObjectByName(name);
}

function posX(x) {
  const DDJ1 = viewer.modelGroup.getObjectByName("Crane");
  const copyDDJ1Pos = DDJ1.position.clone();
  const wPos = DDJ1.getWorldPosition(new AMRT.Vector3());
  wPos.setX(-x / 1000);
  const pos = viewer.modelGroup.getObjectByName("XF").worldToLocal(wPos);
  copyDDJ1Pos.setX(pos.x);
  DDJ1.position.copy(copyDDJ1Pos.clone());
}
// 托盘和物料盒正常+450，其他设备超高了，到时再减去一个合适的高度
function posY(y) {
  const DDJ2 = viewer.modelGroup.getObjectByName("DDJ2");
  const copyDDJ2Pos = DDJ2.position.clone();
  const wPos = DDJ2.getWorldPosition(new AMRT.Vector3());
  wPos.setY(y / 1000);
  const pos = DDJ2.parent.worldToLocal(wPos);
  copyDDJ2Pos.setY(pos.y);
  DDJ2.position.copy(copyDDJ2Pos.clone());
}

function posZ(z) {
  const DDJ41 = findModel("DDJ4-1");
  const DDJ42 = findModel("DDJ4-2");

  const DDJ51 = findModel("DDJ5-1");
  const DDJ52 = findModel("DDJ5-2");

  const copyPos = DDJ41.position.clone();
  const wPos = DDJ41.getWorldPosition(new AMRT.Vector3());
  z = -z;
  wPos.setZ(z / 1000);

  const pos = findModel('DDJ3-1').worldToLocal(wPos);
  pos.y *= 0.7;
  copyPos.setY(pos.y);
  DDJ41.position.setY(copyPos.y);
  DDJ42.position.setY(copyPos.y);
  // 加工中心这边时，加长也一起伸出
  if (z >= 0) {
    let n = copyPos.y;
    n *= 0.45;
    DDJ51.position.setY(n);
    DDJ52.position.setY(n);
  }

}
let t = 1200, t2 = 1000;
// 堆垛机在没有目标时的运动方法
function emptyRunningX(x, type = 1) {
  return new Promise(resolve => {
    const DDJ1 = findModel("Crane");
    const copyDDJ1Pos = DDJ1.position.clone();
    const wPos = DDJ1.getWorldPosition(new AMRT.Vector3());
    wPos.setX(-x / 1000);
    // const dis = Math.abs(
    //   DDJ1.getWorldPosition().x - wPos.x
    // );
    // const t = 1100;
    const start = new Date().getTime();
    // const t = (dis / 3).toFixed(2) * 1000;

    const pos = findModel("XF").worldToLocal(wPos);

    copyDDJ1Pos.setX(pos.x);

    if (type === 1) {
      DDJ1.position.copy(copyDDJ1Pos.clone());
    } else {
      new AMRT.TWEEN.Tween(DDJ1.position)
        .to(copyDDJ1Pos, t)
        .onUpdate(() => {
          const end = new Date().getTime();
          if ((end - start) > t2) resolve();
        })
        .onComplete(() => {

        })
        .start();
    }
  })
}
// 堆垛机在没有目标时的运动方法
function emptyRunningY(y, type = 1) {
  return new Promise(resolve => {
    const DDJ2 = findModel("DDJ2");
    const copyDDJ2Pos = DDJ2.position.clone();
    const wPos = DDJ2.getWorldPosition(new AMRT.Vector3());
    wPos.setY(y / 1000);

    // const dis = Math.abs(
    //   DDJ2.getWorldPosition().y - wPos.y
    // );
    // const t = (dis / 2).toFixed(2) * 1000;
    // const t = 1100;
    const start = new Date().getTime();

    const pos = DDJ2.parent.worldToLocal(wPos);
    copyDDJ2Pos.setY(pos.y);

    if (type === 1) {

      DDJ2.position.copy(copyDDJ2Pos.clone());
    } else {
      new AMRT.TWEEN.Tween(DDJ2.position)
        .to(copyDDJ2Pos, t)
        .onUpdate(() => {
          const end = new Date().getTime();
          if ((end - start) > t2) resolve();
        })
        .onComplete(() => {

        })
        .start();
    }
  })
}
// 堆垛机在没有目标时的运动方法
function emptyRunningZ(z, type = 1) {
  return new Promise(resolve => {
    const DDJ41 = findModel("DDJ4-1");
    const DDJ42 = findModel("DDJ4-2");

    const DDJ51 = findModel("DDJ5-1");
    const DDJ52 = findModel("DDJ5-2");

    const copyPos = DDJ41.position.clone();
    const wPos = DDJ41.getWorldPosition(new AMRT.Vector3());
    z = -z;
    wPos.setZ(z / 1000);
    // const dis = Math.abs(
    //   DDJ41.getWorldPosition().z - wPos.z
    // );
    // const t = (dis / 1.5).toFixed(2) * 1000;
    // const t = 1100;
    const start = new Date().getTime();

    let pos = findModel('DDJ3-1').worldToLocal(wPos);
    pos = pos.clone();
    pos.y *= 0.65;
    copyPos.setY(pos.y);
    if (type === 1) {
      DDJ41.position.setY(copyPos.y);
      DDJ42.position.setY(copyPos.y);
      // 加工中心这边时，加长也一起伸出
      if (z >= 0) {
        let _y = copyPos.y;
        _y *= 0.45;
        DDJ51.position.setY(_y);
        DDJ52.position.setY(_y);
      } else {
        DDJ51.position.setY(copyPos.y);
        DDJ52.position.setY(copyPos.y);
      }
    } else {
      window.tween = new AMRT.TWEEN.Tween(DDJ41.position)
        .to(copyPos, 1200)
        .onUpdate(() => {
          DDJ42.position.setY(DDJ41.position.y);

          // 加工中心这边时，加长也一起伸出
          if (z >= 0) {
            let _y = DDJ41.position.y;
            _y *= 0.45;
            DDJ51.position.setY(_y);
            DDJ52.position.setY(_y);
          } else {
            DDJ51.position.setY(DDJ41.position.y);
            DDJ52.position.setY(DDJ41.position.y);
          }

          const end = new Date().getTime();
          if ((end - start) > 1000) resolve();
        })
        .onComplete(() => {
          resolve()
        })
        .start();
    }
  })
}

// 堆垛机动画
function handleDDJ1({
  name,
  type = 1
}) {
  return new Promise((resolve) => {
    const targetModel = findModel(name);
    if (!targetModel) return;
    const DDJ1 = findModel("Crane");
    const copyDDJ1Pos = DDJ1.position.clone();
    let pos, dis;
    if (type === 1) {
      pos = DDJ1.parent.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()));
      dis = Math.abs(DDJ1.getWorldPosition().x - targetModel.getWorldPosition().x);
    } else if (type === 2) {
      pos = DDJ1.parent.worldToLocal(targetModel.worldPosition.clone());
      dis = Math.abs(DDJ1.getWorldPosition().x - targetModel.worldPosition.x);
    } else {
      const id = name.split('_')[1];
      const dz = findModel('whl_DiZuo' + id);
      pos = DDJ1.parent.worldToLocal(dz.worldPosition.clone());
      dis = Math.abs(DDJ1.getWorldPosition().x - dz.worldPosition.x);

    }

    copyDDJ1Pos.setX(pos.x);


    console.log(dis, 'dis')
    const t = (dis / 3).toFixed(2) * 1000;

    console.log(t)

    new AMRT.TWEEN.Tween(DDJ1.position)
      .to(copyDDJ1Pos, t)
      .onUpdate(() => {})
      .onComplete(async () => {
        resolve()
      })
      .start();
  })
}

window._modelName = null;
window._prevYTime = null;

function handleDDJ2({
  name,
  h = 400,
  type = 1,
  time = 1000
}) {

  return new Promise((resolve) => {
    const targetModel = findModel(name);
    const DDJ2 = findModel("DDJ2");
    const copyDDJ2Pos = DDJ2.position.clone();
    let pos;
    if (type === 1) {
      pos = DDJ2.parent.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()));
    } else if (type === 2) {
      pos = DDJ2.parent.worldToLocal(targetModel.worldPosition.clone());
    } else {
      const id = name.split('_')[1];
      const dz = findModel('whl_DiZuo' + id);
      pos = DDJ2.parent.worldToLocal(dz.worldPosition.clone());

    }
    copyDDJ2Pos.setY(pos.y + h);

    // y轴的运行时间
    const obj = window.config.yTimeConfig.find(f => f.name === name);
    if (obj) {
      const c = Math.abs(obj.yTime - window._prevYTime);
      time = c * 1000;
      window._modelName = obj.name;

      window._prevYTime = obj.yTime;
    }

    new AMRT.TWEEN.Tween(DDJ2.position)
      .to(copyDDJ2Pos, time)
      .onUpdate(() => {})
      .onComplete(() => {
        resolve();
      })
      .start();
  })
}

function handleDDJ3({
  distance = 60,
  time = 500
}) {
  const DDJ31 = findModel("DDJ3-1");
  const DDJ32 = findModel("DDJ3-2");

  return new Promise(resolve => {
    let obj = {
        x: 0
      },
      v = 0;
    new AMRT.TWEEN.Tween(obj)
      .to({
        x: distance
      }, time)
      .onUpdate(() => {
        DDJ31.position.x -= obj.x - v;
        DDJ32.position.x += obj.x - v;
        v = obj.x;
      })
      .onComplete(() => {
        resolve();
      })
      .start();
  })

}

function addModel({
  child,
  parent,
  type = 1
}) {
  return new Promise(resolve => {
    // const targetModel = findModel(name);
    // const parentModel = findModel(parentName);
    if (typeof child === 'string') {
      child = findModel(child);
    }
    if (typeof parent === 'string') {
      parent = findModel(parent);
    }
    // let pos = parentModel.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()));

    // 物料盒
    // if (type === 1) {
    //   targetModel.scale.set(1, 1, 1);
    //   targetModel.rotation.x = 0;
    // } else {
    //   // 托盘
    //   // targetModel.rotation.x = Math.PI / 2;
    // }
    if (type === 1) {
      parent.attach(child);
      // } else if (type === 2) {
      // parentModel.attach(targetModel);
      // 加工中心物料出来时
      // const b = 3.89616632671451;
      // targetModel.scale.set(targetModel.scale.x * b, targetModel.scale.y * b, targetModel.scale.z * b);
      // targetModel.rotation.z = Math.PI / 2;
      // pos.setY(-782);
    } else {
      // 毛坯飞到托盘上面时
      // targetModel.scale.set(targetModel.scale.x / 54.56483459488641, targetModel.scale.y / 1204.0001220827073, targetModel.scale.z / 1004.0000000099981);
      // pos.copy(new AMRT.Vector3(-1,0,0));

      // let v = targetModel.getWorldScale().clone();
      // let m = new AMRT.Matrix4().scale(v);
      // let q = targetModel.getWorldQuaternion();
      // m.makeRotationFromQuaternion(q);



      // parentModel.add(targetModel);
      // targetModel.position.copy(new AMRT.Vector3(-1,0,0));
      // targetModel.applyMatrix(m);
      // targetModel.applyQuaternion(q);
      child.rotation.y += Math.PI / 2;
      parent.attach(child);
      child.position.copy(new AMRT.Vector3(-1, 0, 0));


      // m.setPosition(parentModel.getWorldPosition());
      // let q = targetModel.getWorldQuaternion();

    }

    // targetModel.position.copy(pos);



    // parentModel.attach(targetModel);
    resolve();
  })
}

function removeModel({
  child,
  parent,
  type = 1
}) {
  return new Promise(resolve => {
    // let targetModel = findModel(name);
    // let parentModel = findModel(parentName);
    if (typeof child === 'string') {
      child = findModel(child);
    }
    if (typeof parent === 'string') {
      parent = findModel(parent);
    }
    // let pos;
    // let v = targetModel.getWorldScale().clone();
    // let m = new AMRT.Matrix4().scale(v);
    if (type === 1) {
      // 加工中心物料进入时
      // if (parentModel.scale.x >= 3) {

      // const b = 3.89616632671451;
      // targetModel.scale.set(targetModel.scale.x / b, targetModel.scale.y / b, targetModel.scale.z / b);
      // targetModel.rotation.x = -Math.PI / 2;



      // pos = parentModel.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()));
      // targetModel.position.copy(pos);
      parent.attach(child);

      // targetModel.applyMatrix(m);
      // }

      // targetModel.position.setZ(170);
      // } else {
      //   pos = parentModel.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()));
      //   targetModel.position.copy(pos);
      //   parentModel.add(targetModel);
      // }



      // } else if (type === 2) {
      // pos = parentModel.worldToLocal(targetModel.worldPosition.clone());

      // parentModel.attach(targetModel);


      // if (targetModel.scale.x <= 0.1) {
      //   // 物料放回去时，放大
      //   targetModel.scale.set(1, 1, 1);
      //   // targetModel.applyMatrix(m);
      // }

      // targetModel.position.copy(pos);

    } else {
      const id = name.split('_')[1];
      parentModel = findModel('whl_DiZuo' + id).parent;
      targetModel = findModel('DDJ5-1').children.find(f => f.name.includes('whl_'));
      // pos = parentModel.worldToLocal(targetModel.getWorldPosition(new AMRT.Vector3()).clone());

      parentModel.attach(targetModel);

      // targetModel.position.copy(pos);
    }

    resolve();
  })
}

function menK(name, type) {
  return new Promise(resolve => {
    const obj = viewer.modelGroup.getObjectByName(name);
    const fn = () => {
      if (type === 1) {
        obj.scale.z -= 0.006;
        obj.position.z += 3.32;
      } else {
        obj.scale.z -= 0.003;
        obj.position.z += 1.66;
      }
      if (obj.scale.z <= 0) {
        resolve();
      } else {
        window.requestAnimationFrame(fn);
      }
    }
    fn();
  })
}

function menG(name, type) {
  return new Promise(resolve => {
    const obj = findModel(name);
    const fn = () => {
      if (type === 1) {
        obj.scale.z += 0.006;
        obj.position.z -= 3.32;
      } else {
        obj.scale.z += 0.003;
        obj.position.z -= 1.66;
      }
      if (obj.scale.z >= 1) {
        resolve();
      } else {
        window.requestAnimationFrame(fn);
      }
    }
    fn();
  })
}

// 移动对象
function moveModel({
  name,
  distance = 2080,
  time = 2000,
  shaft = 'y'
}) {
  return new Promise(resolve => {
    const model = findModel(name);
    let obj = {
        y: 0
      },
      v = 0;
  new AMRT.TWEEN.Tween(obj)
      .to({
        y: distance
      }, time)
      .onUpdate(() => {
        model.position[shaft] -= obj.y - v;
        v = obj.y;
      })
      .onComplete(() => {
        resolve()
      })
      .start();
  })
}

// 初始化托盘在堆垛机上位置
function resetModelPos({
  pos,
  pos2,
  time = 5000
}) {
  return new Promise(resolve => {
  new AMRT.TWEEN.Tween(pos)
      .to(pos2, time)
      .onUpdate(() => {
        // model.position.x = pos2.x;
        // model.position.y = pos2.y;
        // model.position.z = pos2.z;
      })
      .onComplete(() => {
        resolve()
      })
      .start();
  })
}

function handleDDJ4({
  distance = -1450,
  time = 19.76 / 2 * 1000
}) {
  if (window._modelName && window._modelName.startsWith('TP')) {
    time = 24.66 / 2 * 1000;
  } else if (window._modelName && window._modelName.startsWith('whl')) {
    time = 19.76 / 2 * 1000;
  } else {
    // time = 19.76 / 4 * 1000
  }
  return new Promise(resolve => {
    const DDJ41 = findModel("DDJ4-1");
    const DDJ42 = findModel("DDJ4-2");


    let obj = {
        y: 0
      },
      v = 0;
   new AMRT.TWEEN.Tween(obj)
      .to({
        y: distance
      }, time)
      .onUpdate(() => {
        DDJ42.position.y = DDJ41.position.y += obj.y - v;
        v = obj.y;

      })
      .onComplete(() => {
        resolve();
      })
      .start();
  })
}

function handleDDJ5({
  distance = -1450,
  time = 19.76 / 4 * 1000
}) {
  // if (window._modelName && window._modelName.startsWith('TP')) {
  //   time = 24.66 / 2 * 1000;
  // } else if (window._modelName && window._modelName.startsWith('whl')) {
  //   time = 19.76 / 2 * 1000;
  // }
  return new Promise(resolve => {
    const DDJ51 = findModel("DDJ5-1");
    const DDJ52 = findModel("DDJ5-2");


    let obj = {
        y: 0
      },
      v = 0;
    new AMRT.TWEEN.Tween(obj)
      .to({
        y: distance
      }, time)
      .onUpdate(() => {
        DDJ52.position.y = DDJ51.position.y += obj.y - v;
        v = obj.y;

      })
      .onComplete(() => {
        resolve();
      })
      .start();
  })
}

function waiting(time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time)
  })
}

export {
  emptyRunningX,
  emptyRunningY,
  emptyRunningZ,
  handleDDJ1,
  handleDDJ2,
  handleDDJ3,
  handleDDJ4,
  handleDDJ5,
  addModel,
  removeModel,
  moveModel,
  menK,
  menG,
  waiting,
  resetModelPos,
  posX,
  posY,
  posZ
}