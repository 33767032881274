<template>
  <!-- 托盘信息 -->
  <div class="panel-tpxx">
    <Panel :panelParams="panelParams">
      <Tab :tabData="tabData" @on-change="onChangeTab" ref="tab"></Tab>
      <div class="wlxx-box" v-show="tabIndex === 0">
        <AreaName title="参数信息" />
        <div class="tp-info-wrap">
          <ul class="list-container" style="padding-top: 10px;">
            <li>
              <span class="label">托盘类型：</span>
              <span>{{ tpInfo.type }}</span>
            </li>
            <li>
              <span class="label">托盘编号：</span>
              <span>{{ tpInfo.palletId }}</span>
            </li>
            <li>
              <span class="label">位置：</span>
              <span>{{ tpInfo.location }}</span>
            </li>
            <li v-if="tpInfo.OrderNbr">
              <span class="label">订单号：</span>
              <span>{{ tpInfo.OrderNbr }}</span>
            </li>
          </ul>
          <ul class="list2-container" style="padding-top: 0;">
            <li v-for="(item, index) in tpInfo.fixtures" :key="index + item.FixtureId">
              <div>
                <span class="label">夹具：</span>
                <span>{{ item.FixtureId }}</span>
              </div>
              <ul class="list-container" style="padding-top: 0;">
                <template v-for="(cItem, index) in item.PartDatas">
                  <li :key="index + cItem.MaterialId">
                    <span class="label">毛坯：</span>
                    <span>{{ cItem.MaterialId }}</span>
                  </li>
                  <li :key="index + cItem.MaterialId">
                    <span class="label">零件：</span>
                    <span>{{ cItem.PartId }}</span>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Tab from "@/components/Tab.vue";
import AreaName from "@/components/AreaName.vue";
export default {
  components: {
    Panel,
    Tab,
    AreaName
  },
  props: ['tpInfo'],
  data() {
    return {
      tabData: ["托盘信息"],
      tabIndex: 0,
      panelParams: {
        boxClass: "panel-1",
        isShowClose: true,
        closeType: "isShowPanelTpxx"
      }
    };
  },
  mounted() {},
  methods: {
    onChangeTab(tabIndex, name) {
      this.tabIndex = tabIndex;
      const hash = {
        物料信息: "panel-1",
        设备信息: "panel-1",
        订单信息: "panel-4",
        历史任务: "panel-4"
      };
      if (hash[name]) {
        this.panelParams.boxClass = hash[name];
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
.panel-tpxx {
  // min-width: 300px;
  padding-bottom: 20px;
  // height: 320px;
  position: absolute;
  background: rgba(12, 19, 35, 0.75);
  li {
    line-height: 30px;
  }
  .list-container {
    width: 100%;
    border-bottom: 1px solid $font-color4;
    .label {
      width: 100px;
    }
  }
  .list2-container {
    width: 100%;
    height: 600px;
    overflow-y: auto;

    .label {
      width: 100px;
      text-align: right;
      margin-right: 14px;
    }
    > li {
      > div {
        display: flex;
        font-size: 13px;
        color: $font-color4;
      }
    }
  }
}
</style>