<template>
  <el-carousel :interval="5000" arrow="always" :loop="true">
    <el-carousel-item :key="1">
      <One></One>
    </el-carousel-item>
    <el-carousel-item :key="2">
      <Two></Two>
    </el-carousel-item>
    <el-carousel-item :key="3">
      <Three></Three>
    </el-carousel-item>
    <el-carousel-item :key="4">
      <Four></Four>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import One from "@/components/statistical/one.vue";
import Two from "@/components/statistical/two.vue";
import Three from "@/components/statistical/three.vue";
import Four from "@/components/statistical/four.vue";
export default {
  components: {
    One,
    Two,
    Three,
    Four
  }
};
</script>

<style lang="scss" scoped>
.el-carousel {
  width: 100%;
  height: 100%;
  background: #010028;
  /deep/.el-carousel__container {
    width: 100%;
    height: 100%;
  }
}
</style>