<template>
  
</template>

<script>
export default {
    name: 'WebSocket',
    data() {
        return {
            websock: null,
            webSockCheckInterval: null,
            websockIsDestroying: false,
            websockErrTimes: 0
        }
    },
    created() {
        if (window.WebSocket) {
            this.initWebSocket();
        } else {
            this.$message.warning('该浏览器不支持');
        }
    },
    methods: {
        initWebSocket() {
            this.createWebSocket();
            this.webSockCheckInterval = window.setInterval(() => {
                // console.log('websocket执行')
                if (!this.websockIsDestroying && this.websock.readyState === 3) {
                    this.websockErrTimes = 0;
                    this.createWebSocket();
                }
            }, 3000);
        },
        createWebSocket() {
            let sid = 'DDY';
            let url = interfaceUrl.replace('http://', '');
            this.websock = new WebSocket(`ws://${url}/websocket`);
            // this.websock = new WebSocket(`ws://192.168.43.91:8081/websocket`);
            this.websock.onmessage = this.webSocketOnMessage;
            this.websock.onopen = this.webSocketOnOpen;
            this.websock.onerror = this.webSocketOnError;
            this.websock.onclose = this.websocketOnCose;
        },
        heartBeatReset() {
            this.heartBeatEnd();
            this.heartBeatStart();
        },
        heartBeatEnd() {
            clearTimeout(this.heartTimeOut);
        },
        heartBeatStart() {
            this.heartTimeOut = window.setTimeout(() => {
                var message = {
                    operType: 'HEART_BEAT',
                    message: 'test'
                }
                this.webSocketSend(JSON.stringify(message));

                if (!this.websockIsDestroying && this.websock.readyState === 3) {
                    this.websockErrTimes = 0;
                    this.createWebSocket();
                }
            }, 3000)
        },
        destroyWebSocket() {
            this.websockIsDestroying = true;
            if (!!this.websock && this.websock.readyState == 1) {
                this.websock.close();
            }
            window.clearInterval(this.webSockCheckInterval);
            this.heartBeatEnd();
        },
        webSocketOnOpen() {
            this.websockErrTimes = 0;
            this.heartBeatReset();
        },
        webSocketOnError() {
            window.setTimeout(() => {
                if (++this.websockErrTimes < 5 && !this.websockIsDestroying && this.websock.readyState === 3) {
                    this.createWebSocket();
                }
            }, 3000);
        },
        webSocketOnMessage(e) {
            this.heartBeatReset();
            let data = e.data
            data = data.replace('收到消息，消息内容：', '')
            data = JSON.parse(data);
            if (data.operType === 'HEART_BEAT') return;
            this.$bus.$emit('myEvent', data);
        },
        websocketOnCose(e) {
            console.log('close websocket', e)
        },
        webSocketSend(Data) {
            this.websock.send(Data);
        }
    },
    destroyed() {
        console.log('退出');
        this.destroyWebSocket();
    }
}
</script>

<style>

</style>