import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const report = {
  // 获取报表信息
  ApiGetReportData(params) {
    return axios({
      method: 'get',
      url: `${base.host}/getReportData`,
      params
    })
  },
  // 获取历史任务柱状图信息
  ApiGetWorkSum(params) {
    return axios({
      method: 'get',
      url: `${base.host}/work/getWorkSum`,
      params
    })
  },
  // 获取各设备的统计数据
  ApiGetStatisticsByDevice(params) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getStatisticsByDevice`,
      params
    })
  },
 
}
export default report