<template>
  <div class="pie-container">
    <div class="top-wrap">
      <span>160P-3-利用率</span>
      <span>1周</span>
    </div>
    <el-progress type="circle" :percentage="25" :width="60" color="#4BC693" :stroke-width="4"></el-progress>
  </div>
</template>

<script>
export default {
  name: "Pie",
  props: {
    
  },
  mounted() {
   
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.pie-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  .top-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-family: Source Han Sans SC VF;
    font-weight: bold;
    color: #333333;
  }
  .el-progress {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/.el-progress__text {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #4bc693;
    }
  }
}
</style>
