<template>
  <ul class="dropdown-box">
    <li
      v-for="item in list"
      :key="item.key"
      @click.stop="changeMenu(item)"
    >
      <i class="icon"></i>
      <span>{{ item.value }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["list"],
  methods: {
    changeMenu(obj) {
      this.$emit("on-menu", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-box {
  width: 130px;
  padding: 10px 0;
  transition: all 0.3s linear;
  background: rgba(0, 21, 64, 0.5);
  border-radius: 2px;
  position: absolute;
  left: 0!important;
  top: 30px;
  z-index: 99999;
  opacity: 0;
  display: none;
  > li {
    width: 100%;
    line-height: 24px;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    > span {
      color: $font-color4;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .icon {
      width: 9px;
      height: 6px;
      margin-right: 6px;
      visibility: hidden;
      @include backgroundImg("../assets/new/top_nav_2_icon.png");
    }
    &:hover,
    &.active {
      > span {
        color: $font-color-hover;
      }
      > i {
        visibility: visible;
      }
    }
  }
}
</style>