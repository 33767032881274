<template>
  <ul class="tab-container">
    <li
      v-for="(name, index) in tabData"
      :key="name"
      :class="tabIndex === index ? 'active' : ''"
      @click="changeTab(index, name)"
    >
      {{ name }}
    </li>
    <slot></slot>
  </ul>
</template>

<script>
export default {
  props: {
    tabData: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    changeTab(index, name) {
      this.tabIndex = index;

      this.$emit("on-change", index, name);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-container {
  width: 100%;
  border-bottom: 1px solid $font-color;
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(12, 19, 35, 0.9);
  > li {
    height: 30px;
    line-height: 24px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin-right: 20px;
    color: $font-color;
    opacity: 1;
    &:not(.active) {
      color: $font-color4;
      opacity: 0.5;
    }
  }
  // > .active {
  //   &:nth-child(1), &:nth-child(2), &:nth-child(3) {
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       left: 0;
  //       bottom: 0;
  //       width: 66px;
  //       height: 4px;
  //       @include backgroundImg("../assets/img/title_line_l.png");
  //     }
  //   }
  //   &:nth-child(4) {
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       left: 0;
  //       bottom: 0;
  //       width: 66px;
  //       height: 4px;
  //       @include backgroundImg("../assets/img/title_line_r.png");
  //     }
  //   }
  // }
}
</style>