<template>
  <div class="header-container">
    <div class="center-box">
      <ul>
        <li>
          <div :class="{ active: yxzt }" @click="switchMenu('yxzt')">
            <span>运行状态</span>
          </div>
          <div :class="{ active: tab === 'sbwl' }" @click="switchMenu('sbwl')">
            <span>设备物料</span>
          </div>
        </li>
        <li>
          <div :class="{ active: tab === 'tjfx' }" @click="switchMenu('tjfx')">
            <span>统计分析</span>
          </div>
          <div :class="{ active: tab === 'lsgjsj' }" @click="switchMenu('lsgjsj')">
            <span>订单信息</span>
          </div>
          <!-- <div
            :class="{ active: tab === 'sjgl' }"
            @mouseenter="show"
            @mouseleave="hidden"
          >
            <span>数据管理</span>
            <dropdown :list="dropData" @on-menu="switchChildMenu"></dropdown>
          </div>-->
        </li>
      </ul>
      <div class="title">
        <img src="../assets/img/logo.png" alt />
        <span>航空结构件中值制造示范线三维可视化仿真系统</span>
      </div>
    </div>
    <div class="time-box">
      <span>{{ getNowDate }}</span>
      <span>{{ getTime }}</span>
    </div>
    <ul class="right-box" v-if="!isShowBtn">
      <li @click="resizeTo" title="播放加工实时动画"></li>
      <li @click="fullScreen" title="播放宣传视频"></li>
      <li @click="switchView(1)" title="默认视角"></li>
      <li @click="switchView(2)" :class="{ active: isMb }" title="漫步视角"></li>
      <li @click="JumpToManagement" title="后台管理系统"></li>
    </ul>
    <div class="video-wrap" v-show="isShowVideo">
      <video :src="url" autoplay muted controls loop></video>
      <i class="el-icon-close" @click="closeFullScreen"></i>
    </div>
    <el-button @click="fullScreen2" v-if="isShowBtn" class="btn-full">全屏</el-button>
  </div>
</template>

<script>
import dropdown from "./dropdown.vue";
import { FullScreen } from "@/utils/tools.js";
let fs, wd;
export default {
  components: {
    dropdown
  },
  data() {
    return {
      tab: "",
      dropData: [
        {
          key: 1,
          value: "数据备份"
        },
        {
          key: 2,
          value: "数据清除"
        },
        {
          key: 3,
          value: "历史告警数据"
        }
      ],
      getNowDate: null,
      getWeek: null,
      getTime: null,
      isDropdown: false,
      isMb: false,
      yxzt: false,
      url: process.env.BASE_URL + "videoUrl.mp4",
      isShowVideo: false,
      isShowBtn: false
    };
  },
  mounted() {
    setInterval(() => {
      this.getNowTime();
    }, 1000);
    this.getNowTime();

    // 全屏操作对象
    fs = new FullScreen();

    // setTimeout(() => {
    //   wd = window.open(
    //     window.location.href,
    //     "航空结构件中值制造示范线三维可视化仿真系统",
    //     "height=1920, width=1080, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no"
    //   );
    // }, 2000);

    window.onresize = () => {
      if (window.innerHeight <= 80) {
        this.isShowBtn = true;
      } else {
        this.isShowBtn = false;
      }
    }
  },
  methods: {
    resizeTo() {
      wd.window.resizeTo(100, 100);
    },
    fullScreen2() {
      this.$nextTick(() => {
        fs.enterFullscreen(document.querySelector("#app"));
      });
    },
    fullScreen() {
      this.isShowVideo = true;
      this.$nextTick(() => {
        fs.enterFullscreen(document.querySelector(".video-wrap"));
      });
    },
    closeFullScreen() {
      this.isShowVideo = false;
      fs.exitFullscreen();
    },
    JumpToManagement() {
      window.open(manageUrl, "_blank");
    },
    switchView(type) {
      this.$bus.$emit("on-view", type);
      if (type === 1) this.isMb = false;
      if (type === 2) this.isMb = !this.isMb;
    },
    switchMenu(type) {
      if (this.tab === type) {
        this.tab = "";
      } else {
        this.tab = type;
      }

      if (type === "yxzt") {
        this.yxzt = !this.yxzt;
        this.$bus.$emit("on-device");
      }

      this.$parent.switchMenu(type);
    },
    // switchChildMenu(obj) {
    //   const { value } = obj;
    //   this.$bus.$emit("on-close");

    //   if (value === "数据清除") {
    //     this.$parent.open("isShowPanelBf", { step: 1 });
    //   } else if (value === "数据备份") {
    //     this.$parent.open("isShowPanelBf", { step: 2 });
    //   } else {
    //     this.$parent.switchMenu("lsgjsj");
    //   }
    //   this.tab = "sjgl";
    // },
    getNowTime() {
      let getDate = new Date();
      let nowYear = getDate.getFullYear();
      let nowMonth = getDate.getMonth() + 1;
      let nowDay = getDate.getDate();
      let nowWeek = getDate.getDay();
      let a = new Array("日", "一", "二", "三", "四", "五", "六");
      let str = "星期" + a[nowWeek];
      let hh =
        new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours();
      let mm =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      this.getNowDate = nowYear + "/" + nowMonth + "/" + nowDay;
      this.getWeek = str;
      this.getTime = hh + ":" + mm + ":" + ss;
    }
    // mouseOperation(e) {
    //   const drop = document.querySelector(".drop-icon");
    //   if (parseFloat(e.target.style.top) === 0) {
    //     e.target.style.top = "-0.49479rem";
    //     drop.style.transform = "rotate(0)";
    //   } else {
    //     e.target.style.top = "0rem";
    //     drop.style.transform = "rotate(180deg)";
    //   }
    // },
    // show(e) {
    //   console.log(e);
    //   e.target.children[1].style.opacity = 1;
    //   e.target.children[1].style.display = "block";
    // },
    // hidden(e) {
    //   e.target.children[1].style.opacity = 0;
    //   e.target.children[1].style.display = "none";
    // },
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  @include backgroundImg("../assets/new/top_nav_bg.png", 98.5%);
  .center-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    > ul {
      width: 1300px;
      padding-top: 60px;
      display: flex;
      justify-content: space-between;
      > li {
        height: 30px;
        display: flex;
        > div {
          // width: 50%;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          position: relative;
          &:nth-child(1) {
            margin-right: 80px;
          }
          > span {
            color: $font-color;
            font-family: pmzd2;
          }
          &:hover,
          &.active {
            > span {
              color: $font-color-hover;
              cursor: pointer;
              text-shadow: 0px 0px 6px $font-color-hover;
              color: $font-color-hover;
            }
          }
        }
        &:nth-child(1) {
          .dropdown-box {
            left: 16px;
          }
        }
        &:nth-child(2) {
          .dropdown-box {
            left: 36px;
          }
        }
      }
    }
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 24px;
      display: flex;
      align-items: center;
      > img {
        width: 40px;
        height: 40px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      > span {
        font-size: 24px;
        color: $font-color;
        font-style: italic;
        font-weight: bold;
        margin-bottom: 6px;
        letter-spacing: 1px;
        // font-family: pmzd2;
        // font-weight: 400;
        line-height: 29px;
      }
    }
  }
  .right-box {
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    > li {
      width: 36px;
      height: 36px;
      margin-left: 10px;
      cursor: pointer;
      &:nth-child(1) {
        @include backgroundImg("../assets/new/left_btn_sj.png");
        &::after {
          display: none;
          content: "";
          width: 36px;
          height: 36px;
          @include backgroundImg("../assets/new/left_btn_sj_y.png");
        }
        &:hover::after,
        &.active::after {
          display: block;
        }
      }
      &:nth-child(2) {
        @include backgroundImg("../assets/new/left_btn_sj.png");
        &::after {
          display: none;
          content: "";
          width: 36px;
          height: 36px;
          @include backgroundImg("../assets/new/left_btn_sj_y.png");
        }
        &:hover::after,
        &.active::after {
          display: block;
        }
      }
      &:nth-child(3) {
        @include backgroundImg("../assets/new/left_btn_sj.png");
        &::after {
          display: none;
          content: "";
          width: 36px;
          height: 36px;
          @include backgroundImg("../assets/new/left_btn_sj_y.png");
        }
        &:hover::after,
        &.active::after {
          display: block;
        }
      }
      &:nth-child(4) {
        @include backgroundImg("../assets/new/left_btn_mb.png");
        &::after {
          display: none;
          content: "";
          width: 36px;
          height: 36px;
          @include backgroundImg("../assets/new/left_btn_mb_y.png");
        }
        &:hover::after,
        &.active::after {
          display: block;
        }
      }
      &:nth-child(5) {
        @include backgroundImg("../assets/new/left_btn_ht.png");
        &::after {
          display: none;
          content: "";
          width: 36px;
          height: 36px;
          @include backgroundImg("../assets/new/left_btn_ht_y.png");
        }
        &:hover::after,
        &.active::after {
          display: block;
        }
      }
    }
  }
  .time-box {
    position: absolute;
    left: 50%;
    top: 58px;
    transform: translateX(-50%);
    > span {
      color: $font-color;
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
  .drop-box {
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    top: 95px;
    .drop-icon {
      width: 23px;
      height: 26px;
      position: absolute;
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%);
      transform: rotate(0deg);
      @include backgroundImg("../assets/img/btn_top.png");
      cursor: pointer;
    }
  }
  .video-wrap {
    width: 100%;
    height: 100%;
    > video {
      width: 100%;
      height: 100%;
    }
    .el-icon-close {
      position: absolute;
      right: 40px;
      top: 40px;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .btn-full{
    width: 100%;
    height: 40%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 9999;
  }
}
</style>