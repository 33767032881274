<template>
  <div class="amodel">
    <div id="model-container"></div>
    <!-- <video src="/demo.mp4" muted autoplay style="    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 500px;
    height: 500px;"></video>-->

    <!-- 单一设备的加工进度 -->
    <div class="processing-progress" v-if="parseInt(percentage)">
      <span>加工进度</span>
      <Progress :percentage="percentage || '0'"></Progress>
    </div>

    <!-- 漫步说明图片 -->
    <img v-show="isShowMb" src="../assets/new/img_tip_mb.png" alt />

    <!-- 设备info -->
    <!-- <div class="info-box" v-for="item in infoList" :key="item.name" :id="item.deviceName + '-info'"> -->
    <div class="info-box">
      <img src="../assets/new/pop_title.png" alt />
      <ul class="list-container">
        <li>
          <span class="label">设备名称：</span>
          <span>{{ equInfo.name }}</span>
        </li>
        <li>
          <span class="label">设备状态：</span>
          <span>{{ equInfo.status }}</span>
        </li>
        <li>
          <span class="label">物料：</span>
          <span>{{ equInfo.partDrawingNumber }}</span>
        </li>
        <li>
          <span class="label">物料状态：</span>
          <span>{{ equInfo.type | materialStateTranscoding }}</span>
        </li>
        <li class="progress">
          <span class="label">工作进度：</span>
          <Progress class="processing-progress3" :percentage="equInfo.param || '0'"></Progress>
        </li>
        <!-- <li>
          <span class="label">后续需求刀具：</span>
          <span>{{ "--" }}</span>
        </li>
        <li>
          <span class="label">缺失物料：</span>
          <span>{{ "--" }}</span>
        </li>-->
      </ul>
    </div>
    <!-- 普通托盘info -->
    <div class="tp-info-box">
      <img src="../assets/new/pop_title.png" alt />
      <ul class="list-container" style="padding-top: 10px;">
        <li>
          <span class="label">托盘类型：</span>
          <span>{{ tpInfo.type }}</span>
        </li>
        <li>
          <span class="label">托盘编号：</span>
          <span>{{ tpInfo.palletId }}</span>
        </li>
        <li>
          <span class="label">位置：</span>
          <span>{{ tpInfo.location }}</span>
        </li>
        <li v-if="tpInfo.OrderNbr">
          <span class="label">订单号：</span>
          <span>{{ tpInfo.OrderNbr }}</span>
        </li>
      </ul>
      <ul class="list2-container" style="padding-top: 0;">
        <li v-for="(item, index) in tpInfo.fixtures" :key="index + item.FixtureId">
          <div>
            <span class="label">夹具：</span>
            <span>{{ item.FixtureId }}</span>
          </div>
          <ul class="list-container" style="padding-top: 0;">
            <template v-for="(cItem, index) in item.PartDatas">
              <li :key="index + cItem.MaterialId">
                <span class="label">毛坯：</span>
                <span>{{ cItem.MaterialId }}</span>
              </li>
              <li :key="index + cItem.MaterialId">
                <span class="label">零件：</span>
                <span>{{ cItem.PartId }}</span>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
    <!-- 物料托盘info -->
    <div class="wltp-info-box">
      <img src="../assets/new/pop_title.png" alt />
      <ul class="list-container" style="padding-top: 10px;">
        <li>
          <span class="label">托盘类型：</span>
          <span>{{ mltpInfo.type }}</span>
        </li>
        <li>
          <span class="label">托盘编号：</span>
          <span>{{ mltpInfo.palletId }}</span>
        </li>
        <li>
          <span class="label">位置：</span>
          <span>{{ mltpInfo.location }}</span>
        </li>
        <li v-if="mltpInfo.OrderNbr">
          <span class="label">订单号：</span>
          <span>{{ mltpInfo.OrderNbr }}</span>
        </li>
      </ul>
      <ul class="list2-container" style="padding-top: 0;">
        <li v-for="(item, index) in mltpInfo.fixtures" :key="index + item.FixtureId">
          <div>
            <span class="label">夹具：</span>
            <span>{{ item.FixtureId }}</span>
          </div>
          <ul class="list-container" style="padding-top: 0;">
            <template v-for="(cItem, index) in item.PartDatas">
              <li :key="index + cItem.MaterialId">
                <span class="label">毛坯：</span>
                <span>{{ cItem.MaterialId }}</span>
              </li>
              <li :key="index + cItem.MaterialId">
                <span class="label">零件：</span>
                <span>{{ cItem.PartId }}</span>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>

    <div class="video-btn" v-if="isShowVideoBtn" @click="openVideoDialog">
      <span>播放机内视频</span>
    </div>
    <!-- <WebSocket></WebSocket> -->
  </div>
</template>

<script>
let viewer, dom;

import Progress from "@/components/Progress.vue";

import { debounce } from "@/utils/tools.js";

import { palletByType, craneMoveData, craneData } from "@/utils/data.js";
import {
  emptyRunningX,
  emptyRunningY,
  emptyRunningZ,
  handleDDJ1,
  handleDDJ2,
  handleDDJ3,
  handleDDJ4,
  handleDDJ5,
  addModel,
  removeModel,
  moveModel,
  menK,
  menG,
  waiting,
  resetModelPos,
  posX,
  posY,
  posZ
} from "./animation.js";
import {
  menConfig,
  tpAndWlhConfig,
  jgmenConfig,
  jgtpConfig,
  zzzAndWljczConfig,
  jgzxgdConfig,
  sjConfig
} from "@/utils/config.js";

import Xfviewer from "./xfviewer.js";
import WebSocket from "@/components/WebSocket.vue";
let isZk = false;
export default {
  components: {
    Progress,
    WebSocket
  },
  props: ["percentage", "deviceAll"],
  data() {
    return {
      isShowMb: false, //是否显示漫游操作指示
      infoList: [], //移入显示的设备info
      equInfo: {},
      tpInfo: {},
      mltpInfo: {},
      debounceFn: null, //防抖函数
      isShowVideoBtn: false,
      device: null,
      deviceList: null,
      timer: null,
      deviceRealTimeData: [],
      devicePosData: [],
      prevTarget: null,
      prevXY: {},
      clickid: 1,
      startTime: null,
      timer2: null,
      state1: false,
      state2: false,

      jgzxlock: false,
      curJgzx: null,

      tplock: false,

      // 保存当前设备的开关门状态
      deviceMenConfig: {
        L1: {},
        L2: {},
        M1: {},
        M2: {},
        M3: {}
      },
      jgzxMenConfig: {
        MC1: false,
        MC2: false,
        MC3: false,
        MC4: false,
        MC5: false,
        MC6: false
      },
      prevName: null,
      lock: false,
      curCranePos: {
        x: 0,
        y: 0,
        z: 0
      },
      palletsLock: false,
      copyTp: null,
      copyWlhTp: null
    };
  },
  mounted() {
    window.posX = posX;
    window.posY = posY;
    window.posZ = posZ;
    document.oncontextmenu = function() {
      return false;
    };

    window.deviceMenConfig = this.deviceMenConfig;

    const param = {
      appkey: "hYCTBPigreIl",
      appsecret: "mJG9gCtQ0WOlj8L5Sx7I1qwBDNfTd3ek"
    };

    dom = document.getElementById("model-container");

    // viewer = window.viewer = new Xfviewer(dom, param, this);

    viewer = window.viewer = new Xfviewer(
      "model-container",
      { offline: true },
      this
    );

    viewer.initView().then(async () => {
      this.setPalletsPosition();
      viewer.createInfoHot();
      let i = 0;
      const fn = async () => {
        this.deviceList = await this.$parent.getDeviceProduct();
        this.infoList = this.deviceList.filter(f => f.isShowHot);
        // viewer.createInfoHot(i);
        setTimeout(() => {
          i++;
          fn();
        }, 10000);
      };

      await fn();
      // 初始化堆垛机的位置
      setTimeout(() => {
        const _crane = this.deviceList.find(f => f.deviceName === "Crane");
        if (!_crane) return;
        const _location = JSON.parse(_crane.location);
        const _x = parseInt(_location.forkX);
        const _y = parseInt(_location.forkY);
        const _z = parseInt(_location.forkZ);
        emptyRunningX(_x);
        emptyRunningY(_y);
        emptyRunningZ(_z);
      }, 1000);

      handleDDJ3({ distance: -40 });

      const res = await this.$parent.getPalletData();
      this.initPallets(res);

      // 获取outline对象数据
      (this.wlhs = []), (this.tps = []);
      // 物料盒托盘1000-1024;
      for (let i = 0; i < 26; i++) {
        const wlh = viewer.findModel("whl_10" + (i < 10 ? "0" + i : i));
        if (wlh) {
          // 初始化时，不存在的移除
          if (!wlh.userData || JSON.stringify(wlh.userData) === "{}") {
            wlh.parent.remove(wlh);
          } else {
            this.wlhs.push(wlh);
          }
        }
      }
      // 普通托盘1-40;
      for (let i = 1; i <= 40; i++) {
        const tp = viewer.findModel("TP_DiZuo" + i);
        if (tp) {
          // 初始化时，不存在的移除
          if (!tp.userData || JSON.stringify(tp.userData) === "{}") {
            tp.parent.remove(tp);
          } else {
            this.tps.push(tp);
          }
        }
      }

      let hots = [];
      this.deviceList.forEach(item => {
        const name = item.modelName || item.name;
        const obj = viewer.findModel(name);
        if (obj) {
          // 设备改名
          obj.name = item.name;
          obj.userData = item;
          hots.push(obj);
        }
      });
      viewer.deviceModels = hots;
      viewer.outLineList = [...this.wlhs, ...this.tps, ...hots];
      setTimeout(() => {
        this.$bus.$on("myEvent", async msg => {
          // console.log(msg, "msgmsg");
          // 堆垛机数据
          const crane = msg.find(f => f.device === "Crane");
          if (crane) {
            let { forkX: x, forkY: y, forkZ: z, targetLocation: tl } = JSON.parse(
              crane.location
            );
            const cranePos = { x: parseInt(x), y: parseInt(y), z: parseInt(z) };

            console.log(JSON.stringify(cranePos), "cranePos");
            // 堆垛机移动
            this.cranePosMove(cranePos);
            // 到了对应的位置取托盘或者放托盘
            this.operateTp(cranePos);
            // 加工中心开关门
            this.operateJgzx(cranePos);
            if (
              this.curCranePos.x === cranePos.x &&
              this.curCranePos.y === cranePos.y &&
              cranePos.z > -10 &&
              cranePos.z < 10
            ) {
              const _tp = viewer.findModel('DDJ5-1').children.find(f => f.name.includes('TP_DiZuo') || f.name.includes('whl_'));
              if (_tp) {
                // _tp.position.copy(new AMRT.Vector3(-200, 0, 20));
                resetModelPos({pos: _tp.position, pos2: {x:-200, y:0, z:20}, time: 3000});
              }

              if (!this.palletsLock) {
                const res = await this.$parent.getPalletData();
                this.updatePalletsInfo(res);
                this.palletsLock = true;

                // // 获取outline对象数据
                // (this.wlhs = []), (this.tps = []);
                // // 物料盒托盘1000-1024;
                // for (let i = 0; i < 26; i++) {
                //   const wlh = viewer.findModel("whl_10" + (i < 10 ? "0" + i : i));
                //   if (wlh) {
                //     // 初始化时，不存在的移除
                //     if (!wlh.userData || JSON.stringify(wlh.userData) === "{}") {
                //       wlh.parent.remove(wlh);
                //     } else {
                //       this.wlhs.push(wlh);
                //     }
                //   }
                // }
                // // 普通托盘1-40;
                // for (let i = 1; i <= 40; i++) {
                //   const tp = viewer.findModel("TP_DiZuo" + i);
                //   if (tp) {
                //     // 初始化时，不存在的移除
                //     if (!tp.userData || JSON.stringify(tp.userData) === "{}") {
                //       tp.parent.remove(tp);
                //     } else {
                //       this.tps.push(tp);
                //     }
                //   }
                // }

                // viewer.outLineList = [...this.wlhs, ...this.tps, ...hots];
              }
            } else {
              this.palletsLock = false;
            }

            this.curCranePos = cranePos;
          }

          // 装载站数据
          const LList = msg.filter(f => f.device === "L1" || f.device === "L2");
          // 物料进出站数据
          const MList = msg.filter(
            f => f.device === "M1" || f.device === "M2" || f.device === "M3"
          );

          // 装载站开关门
          this.operateZzz(LList);
          // 物料进出站开关门
          this.operateWljcz(MList);
        });
      }, 1000);
    });

    viewer.container.addEventListener(
      "dblclick",
      viewer.dblclickModel.bind(viewer),
      false
    );
    viewer.container.addEventListener("touchstart", this.onTouchstart, false);

    this.debounceFn = debounce(viewer.onMousemove.bind(viewer));
    viewer.container.addEventListener("mousemove", this.debounceFn, false);

    // 监听切换默认视角
    this.$bus.$on("on-view", type => {
      if (type === 1) {
        if (viewer.viewMode === "roam_free") {
          viewer.setViewMode("orbit");
          this.isShowMb = false;
        }
        viewer.handleDefaultView();
        viewer.isMove = true;
        viewer.isClick = true;
      } else {
        if (viewer.viewMode === "roam_free") {
          viewer.setViewMode("orbit");
          this.isShowMb = false;
          viewer.isMove = true;
          viewer.isClick = true;
          viewer.handleDefaultView();
        } else {
          this.isShowMb = true;
          viewer.isMove = false;
          viewer.isClick = false;
          viewer.handleDefaultView(viewer.roamFreeView, () => {
            viewer.setViewMode("roam_free");
            viewer.camera.lookAt(
              new AMRT.Vector3().fromArray(viewer.roamFreeView.target)
            ); //设置相机朝向
          });
        }
      }
      viewer.play();
      this.$bus.$emit("on-close");
      viewer.showModel();
      this.isShowVideoBtn = false;
      window.removeEventListener("keydown", this.handleEscEvent, false);
    });

    // 监听运行状态
    this.$bus.$on("on-device", () => {
      if (
        viewer.findModel("Crane-sprite") &&
        viewer.findModel("Crane-sprite").visible
      ) {
        if (this.timer) clearInterval(this.timer);
        this.timer = null;
        viewer.removeSprite();
      } else {
        viewer.createDeviceHot(viewer.deviceModels);
        this.timer = setInterval(() => {
          viewer.removeSprite();
          viewer.createDeviceHot(viewer.deviceModels);
        }, 10000);
      }
      viewer.handleDefaultView();
      viewer.showModel();

      viewer.play();
      viewer.isMove = true;
      viewer.isClick = true;

      this.isShowVideoBtn = false;
      window.removeEventListener("keydown", this.handleEscEvent, false);
    });
  },
  methods: {
    // 模拟移动端双击事件
    onTouchstart(e) {
      if (this.clickid == 1) {
        this.startTime = new Date().getTime();
        this.clickid++;
        this.timer2 = setTimeout(() => {
          this.clickid = 1;
        }, 300);
      }
      if (this.clickid == 2) {
        this.clickid++;
      } else {
        const endTime = new Date().getTime();
        if (endTime - this.startTime < 300) {
          viewer.dblclickModel(e);
          this.clickid = 1;
          clearTimeout(this.timer2);
        }
      }
    },
    // 打开视频窗口
    openVideoDialog() {
      this.$parent.open("isShowPanelVideo", {
        device: this.device
      });
    },
    // 处理esc事件
    handleEscEvent(e) {
      if (e && e.keyCode == 27) {
        viewer.setViewMode("orbit");
        this.isShowMb = false;
        viewer.isMove = true;
        viewer.isClick = true;

        viewer.handleDefaultView();
        viewer.showModel();
        this.$bus.$emit("on-close");

        viewer.play();

        this.$parent.percentage = "0";

        this.isShowVideoBtn = false;
        window.removeEventListener("keydown", this.handleEscEvent, false);
      }
    },
    // 设置托盘在物料架上的位置和父节点名称,用于堆垛机将托盘叉回到物料架上时
    setPalletsPosition() {
      // 物料盒托盘1000-1024;
      for (let i = 0; i < 26; i++) {
        const wlh = viewer.findModel("whl_10" + (i < 10 ? "0" + i : i));
        const dz = viewer.findModel("whl_DiZuo10" + (i < 10 ? "0" + i : i));
        const pos = wlh.getWorldPosition(new AMRT.Vector3());
        dz.worldPosition = pos.clone();
      }
      // 普通托盘1-40;
      for (let i = 1; i <= 40; i++) {
        const tp = viewer.findModel("TP_DiZuo" + i);
        const pos = tp.getWorldPosition(new AMRT.Vector3());
        tp.worldPosition = pos.clone();
        tp.parentName = tp.parent.name;
      }
    },
    // 初始化托盘位置
    initPallets(pallets) {
      pallets.forEach(pallet => {
        // if (pallet.palletId == '19') {
        //   pallet.location = 'MC1'
        // }
        // if (pallet.palletId == '15') {
        //   pallet.location = 'Crane.Forks'
        // }
        if (pallet.fixtures) {
          pallet.fixtures = JSON.parse(pallet.fixtures);
        }
        if (pallet.partDatas) {
          const arr = JSON.parse(pallet.partDatas);
          if (arr.length) {
            pallet.OrderNbr = arr[0].OrderNbr;
          }
        }
        // 普通托盘放到物料架上面
        if (pallet.type === "Machine" && pallet.location.includes("Storage")) {
          const location = pallet.location.split(".")[2];
          let tp = viewer.findModel("TP_DiZuo" + location);
          if (tp) {
            tp.userData = pallet;
          } else {
            // tp = viewer.copyTp.clone();
            // tp.userData = pallet;
            // const p = viewer.findModel("DiZuo" + location).parent;
            // p.attach(tp);
          }
          console.log("普通托盘======");
        } else if (
          pallet.type === "Material" &&
          pallet.location.includes("Storage")
        ) {
          // 物料托盘放到物料架上面
          console.log("物料托盘======");
          const location = pallet.location.split(".")[2];
          let wlh = viewer.findModel("whl_" + location);
          if (wlh) {
            wlh.userData = pallet;
          } else {
            // wlh = viewer.copyWlhtp.clone();
            // wlh.userData = pallet;
            // const p = viewer.findModel("whl_DiZuo" + location).parent;
            // p.attach(wlh);
          }
        } else if (pallet.location.startsWith("L")) {
          // 装载站托盘
          console.log("装载站托盘======");
          const zzzName = pallet.location.split(".")[0];
          let tp = viewer.findModel("TP_DiZuo" + pallet.palletId);
          // if (!tp) {
          //   tp = viewer.copyTp.clone();
          // }
          const z = viewer.findModel(zzzAndWljczConfig[zzzName]);
          const pos = tp.worldToLocal(z.getWorldPosition(new AMRT.Vector3()));
          pos.setZ(850);
          pos.x -= 70;
          tp.position.copy(pos);
          if (tp) {
            tp.userData = pallet;
            z.add(tp);
          }
        } else if (pallet.location.startsWith("MC")) {
          console.log("加工中心托盘======");
          // 加工中心托盘
          const jgzxName = pallet.location.split(".")[0];
          const jgtp = viewer.findModel(jgtpConfig[jgzxName]);
          console.log(jgtp, "jgtpjgtp");
          if (jgtp) {
            const _tp = jgtp.children.find(f => f.name.includes("TP_DiZuo"));
            if (_tp) {
              _tp.userData = pallet;
            } else {
              let tp = viewer.findModel("TP_DiZuo" + pallet.palletId);
              // if (!tp) {
              //   tp = viewer.copyTp.clone();
              // }
              if (tp) {
                const b = 3.89616632671451;
                tp.scale.set(tp.scale.x / b, tp.scale.y / b, tp.scale.z / b);
                tp.rotation.x = -Math.PI / 2;
                tp.userData = pallet;
                tp.position.copy(new AMRT.Vector3(0, 150, 160));
                jgtp.add(tp);
              }
            }
          }
        } else if (pallet.location === "Crane.Forks") {
          // 堆垛机托盘 DDJ5-1
          console.log("堆垛机托盘======");
          const ddj = viewer.findModel("DDJ5-1");
          let tp, n;
          if (pallet.palletId < 100) {
            tp = viewer.findModel("TP_DiZuo" + pallet.palletId);
            // if (!tp) {
            //   tp = viewer.copyTp.clone();
            // }
            n = 40;
          } else {
            tp = viewer.findModel("whl_" + pallet.palletId);
            // if (!tp) {
            //   tp = viewer.copyWlhtp.clone();
            // }
            n = 10;
          }
          if (tp) {
            tp.userData = pallet;
            tp.position.copy(new AMRT.Vector3(-200, 0, n));
            ddj.add(tp);
          }
        } else if (pallet.location.startsWith("M")) {
          // 物料站托盘
          console.log("物料站托盘======");
          const wlzName = pallet.location.split(".")[0];
          let wlh = viewer.findModel("whl_" + pallet.palletId);
          // if (!wlh) {
          //   wlh = viewer.copyWlhtp.clone();
          // }
          const z = viewer.findModel(zzzAndWljczConfig[wlzName]);
          if (wlh) {
            wlh.userData = pallet;
            // wlh.position.copy(new AMRT.Vector3(0, -800, 100));
            wlh.position.copy(new AMRT.Vector3(0, 1200, 100));
            z.add(wlh);
          }
        }
      });
    },
    // 更新托盘信息
    updatePalletsInfo(pallets) {
      pallets.forEach(pallet => {
        if (pallet.fixtures) {
          pallet.fixtures = JSON.parse(pallet.fixtures);
        }
        if (pallet.partDatas) {
          const arr = JSON.parse(pallet.partDatas);
          if (arr.length) {
            pallet.OrderNbr = arr[0].OrderNbr;
          }
        }
        // 普通托盘放到物料架上面
        if (pallet.type === "Machine" && pallet.location.includes("Storage")) {
          const location = pallet.location.split(".")[2];
          let tp = viewer.findModel("TP_DiZuo" + location);
          if (tp) {
            tp.userData = pallet;
          }
        } else if (
          pallet.type === "Material" &&
          pallet.location.includes("Storage")
        ) {
          // 物料托盘放到物料架上面
          const location = pallet.location.split(".")[2];
          let wlh = viewer.findModel("whl_" + location);
          if (wlh) {
            wlh.userData = pallet;
          }
        } else if (pallet.location.startsWith("L")) {
          // 装载站托盘
          let tp = viewer.findModel("TP_DiZuo" + pallet.palletId);
          if (tp) {
            tp.userData = pallet;
          }
        } else if (pallet.location.startsWith("MC")) {
          // 加工中心托盘

          let tp = viewer.findModel("TP_DiZuo" + pallet.palletId);

          if (tp) {
            tp.userData = pallet;
          }
        } else if (pallet.location === "Crane.Forks") {
          // 堆垛机托盘 DDJ5-1
          let tp;
          if (pallet.palletId < 100) {
            tp = viewer.findModel("TP_DiZuo" + pallet.palletId);
          } else {
            tp = viewer.findModel("whl_" + pallet.palletId);
          }
          if (tp) {
            tp.userData = pallet;
          }
        } else if (pallet.location.startsWith("M")) {
          // 物料站托盘
          let wlh = viewer.findModel("whl_" + pallet.palletId);

          if (wlh) {
            wlh.userData = pallet;
          }
        }
      });
    },

    // 视觉识别装置动画
    async handleSj(zName) {
      await moveModel({
        name: sjConfig[zName].xName,
        distance: 280,
        shaft: "x"
      });
      await moveModel({
        name: sjConfig[zName].yName,
        distance: 900,
        shaft: "z"
      });
      await moveModel({
        name: sjConfig[zName].xName,
        distance: -280,
        shaft: "x"
      });
      await moveModel({
        name: sjConfig[zName].yName,
        distance: -900,
        shaft: "z"
      });
    },

    // 机械臂动画
    // 机械臂托盘打组
    // 机械臂：QMC2：y, QMC3：x, QMC4: x, QMC5: z, QMC6: x, QMC7: z

    // 1.先拿实际数据驱动堆垛机xyz的坐标值
    cranePosMove(info) {
      // console.log(this.lock, 'Z轴')
      if (!this.lock) {
        Promise.all([
          emptyRunningX(info.x, 2),
          emptyRunningY(info.y, 2),
          emptyRunningZ(info.z, 2)
        ]);
      } else {
        Promise.all([emptyRunningX(info.x, 2), emptyRunningY(info.y, 2)]);
      }
    },

    // 2.匹配判断xyz全等时对应托盘和设备时，取对应托盘和放对应托盘
    operateTp(info) {
      // console.log(this.tplock, "this.tplock");
      // if (this.tplock) return;
      if (info.z > -10 && info.z < 10) {
        this.prevName = null;
      }
      const num = 20;
      for (let item of tpAndWlhConfig) {
        if (
          info.x + num > item.x &&
          info.x - num < item.x &&
          info.y + num > item.y &&
          info.y - num < item.y &&
          info.z + num > item.z &&
          info.z - num < item.z
        ) {
          // this.tplock = true;
          // setTimeout(() => {
          //   this.tplock = false;
          // }, 25 * 1000);

          let name = item.name;
          if (this.prevName === name) return;
          this.prevName = name;

          // setTimeout(() => {
          //   this.prevName = null;
          // }, 80 * 1000);

          const tp = viewer
            .findModel("DDJ5-1")
            .children.find(
              f => f.name.includes("TP_DiZuo") || f.name.includes("whl_")
            );
          console.log(name, "namename");
          viewer.pause();
          this.lock = true;
          setTimeout(() => {
            this.lock = false;
            viewer.play();
          }, 4000);

          if (name.startsWith("TP_DiZuo") || name.startsWith("whl_")) {
            // 托盘或者物料盒
            if (tp) {
              let id, parent;
              if (name.startsWith("TP_DiZuo")) {
                id = name.split("TP_DiZuo")[1];
                parent = viewer.findModel("DiZuo" + id).parent;
              } else if (name.startsWith("whl_")) {
                id = name.split("whl_")[1];
                parent = viewer.findModel("whl_DiZuo" + id).parent;
              }
              // 堆垛机有托盘时
              removeModel({
                child: tp,
                parent: parent
              });
              if (name.startsWith("TP_DiZuo")) {
                resetModelPos({pos: tp.position, pos2: {x:5.605, y:12.5, z:142}});
              } else if (name.startsWith("whl_")) {
                resetModelPos({pos: tp.position, pos2: {x:0, y:0, z:-168}});
              }
              
              console.log("货架：放托盘======");
            } else {
              // 堆垛机无托盘时
              let id, tpParent, _tp;
              if (name.startsWith("TP_DiZuo")) {
                id = name.split("TP_DiZuo")[1];
                tpParent = viewer.findModel("DiZuo" + id).parent;
                _tp = tpParent.children.find(f => f.name.includes("TP_DiZuo"));
              } else if (name.startsWith("whl_")) {
                id = name.split("whl_")[1];
                tpParent = viewer.findModel("whl_DiZuo" + id).parent;
                _tp = tpParent.children.find(
                  f => f.name.includes("whl_") && !f.name.includes("DiZuo")
                );
              }
              addModel({ child: _tp, parent: "DDJ5-1" });
              // _tp.position.copy(new AMRT.Vector3(-200, 0, 20));
              console.log("货架：取托盘======");
            }
          } else if (name.startsWith("MC")) {
            // 加工中心
            if (tp) {
              // 堆垛机有托盘时
              removeModel({
                child: tp,
                parent: jgtpConfig[name]
              });
              console.log("加工中心：放托盘======");
            } else {
              // 堆垛机无托盘时
              const addObj = viewer
                .findModel(jgtpConfig[name])
                .children.find(f => f.name.includes("TP_DiZuo"));
              if (addObj) {
                addModel({
                  child: addObj,
                  parent: "DDJ5-1"
                });
                // addObj.position.copy(new AMRT.Vector3(-200, 0, 20));
              }
              console.log("加工中心：取托盘======");
            }
          } else if (name.startsWith("M")) {
            // 物料进出站
            if (tp) {
              // 堆垛机有托盘时
              removeModel({
                child: tp,
                parent: zzzAndWljczConfig[name]
              });
              console.log("物料进出站：放托盘======");
            } else {
              // 堆垛机无托盘时
              const addObj = viewer
                .findModel(zzzAndWljczConfig[name])
                .children.find(f => f.name.includes("whl_"));
              if (addObj) {
                addModel({ child: addObj, parent: "DDJ5-1" });
                // addObj.position.copy(new AMRT.Vector3(-200, 0, 20));
              }
              console.log("物料进出站：取托盘======");
            }
          } else if (name.startsWith("L")) {
            // 装载站
            if (tp) {
              // 堆垛机有托盘时
              removeModel({
                child: tp,
                parent: zzzAndWljczConfig[name]
              });
              console.log("装载站：放托盘======");
            } else {
              // 堆垛机无托盘时
              const addObj = viewer
                .findModel(zzzAndWljczConfig[name])
                .children.find(f => f.name.includes("TP_DiZuo"));
              if (addObj) {
                addModel({ child: addObj, parent: "DDJ5-1" });
                // addObj.position.copy(new AMRT.Vector3(-200, 0, 20));
              }
              console.log("装载站：取托盘======");
            }
          }
          break;
        }
      }
    },
    // 3.判断物料进出站的前后开关门和传送带
    operateWljcz(list) {
      list.forEach(item => {
        const z = item.device;

        const addObj = viewer
          .findModel(zzzAndWljczConfig[z])
          .children.find(f => f.name.includes("whl_"));

        const moveDescription = JSON.parse(item.moveDescription);
        for (let key in moveDescription) {
          if (moveDescription[key] == "true") {
            moveDescription[key] = true;
          } else {
            moveDescription[key] = false;
          }
        }
        if (JSON.stringify(this.deviceMenConfig[z]) == "{}") {
          if (moveDescription.CraneDoorOpen) {
            menK(menConfig[z].h, 1);
            console.log(999999);
          }
          if (!moveDescription.CraneDoorOpen) {
            menG(menConfig[z].h, 1);
          }
          if (moveDescription.OpDoorOpen) {
            menK(menConfig[z].q, 1);
            setTimeout(() => {
              if (addObj) {
                moveModel({
                  name: addObj.name,
                  distance: 2000,
                  time: 5000,
                  shaft: "y"
                });
              }
              setTimeout(() => {
                if (addObj) {
                  moveModel({
                    name: addObj.name,
                    distance: -2000,
                    time: 5000,
                    shaft: "y"
                  });
                }
              }, 15000);
            }, 8000);
          }
          if (!moveDescription.OpDoorOpen) {
            menG(menConfig[z].q, 1);
          }
        } else {
          if (
            this.deviceMenConfig[z].CraneDoorOpen !==
              moveDescription.CraneDoorOpen &&
            moveDescription.CraneDoorOpen
          ) {
            menK(menConfig[z].h, 1);
          }
          if (
            this.deviceMenConfig[z].CraneDoorOpen !==
              moveDescription.CraneDoorOpen &&
            !moveDescription.CraneDoorOpen
          ) {
            menG(menConfig[z].h, 1);
          }
          if (
            this.deviceMenConfig[z].OpDoorOpen !== moveDescription.OpDoorOpen &&
            moveDescription.OpDoorOpen
          ) {
            menK(menConfig[z].q, 1);
            setTimeout(() => {
              if (addObj) {
                moveModel({
                  name: addObj.name,
                  distance: 2000,
                  time: 5000,
                  shaft: "y"
                });
              }
              setTimeout(() => {
                if (addObj) {
                  moveModel({
                    name: addObj.name,
                    distance: -2000,
                    time: 5000,
                    shaft: "y"
                  });
                }
              }, 15000);
            }, 8000);
          }
          if (
            this.deviceMenConfig[z].OpDoorOpen !== moveDescription.OpDoorOpen &&
            !moveDescription.OpDoorOpen
          ) {
            menG(menConfig[z].q, 1);
          }
        }

        this.deviceMenConfig[z] = moveDescription;
      });

      // 移出物料盒
      // moveModel({
      //   name: removeObj.name,
      //   distance: 2000,
      //   time: 10000,
      //   shaft: "y"
      // });
      // // 移入物料盒
      // moveModel({
      //   name: addObj.name,
      //   distance: -2000,
      //   time: 10000,
      //   shaft: "y"
      // });
    },
    // 4.判断装载站的前后开关门和传送带
    operateZzz(list) {
      list.forEach(item => {
        const z = item.device;

        const moveDescription = JSON.parse(item.moveDescription);
        for (let key in moveDescription) {
          if (moveDescription[key] == "true") {
            moveDescription[key] = true;
          } else {
            moveDescription[key] = false;
          }
        }
        if (JSON.stringify(this.deviceMenConfig[z]) == "{}") {
          if (moveDescription.CraneDoorOpen) {
            menK(menConfig[z].h, 2);
          }
          if (!moveDescription.CraneDoorOpen) {
            menG(menConfig[z].h, 2);
          }
          if (moveDescription.OpDoorOpen) {
            menK(menConfig[z].q, 2);
            setTimeout(() => {
              moveModel({
                name: zzzAndWljczConfig[z],
                distance: 1700,
                time: 10000,
                shaft: "y"
              });
              setTimeout(() => {
                moveModel({
                  name: zzzAndWljczConfig[z],
                  distance: -1700,
                  time: 10000,
                  shaft: "y"
                });
              }, 15000);
            }, 8000);
          }
          if (!moveDescription.OpDoorOpen) {
            menG(menConfig[z].q, 2);
          }
        } else {
          if (
            this.deviceMenConfig[z].CraneDoorOpen !==
              moveDescription.CraneDoorOpen &&
            moveDescription.CraneDoorOpen
          ) {
            console.log(z + "后门开");
            menK(menConfig[z].h, 2);
          }
          if (
            this.deviceMenConfig[z].CraneDoorOpen !==
              moveDescription.CraneDoorOpen &&
            !moveDescription.CraneDoorOpen
          ) {
            console.log(z + "后门关");
            menG(menConfig[z].h, 2);
          }
          if (
            this.deviceMenConfig[z].OpDoorOpen !== moveDescription.OpDoorOpen &&
            moveDescription.OpDoorOpen
          ) {
            console.log(z + "前门开");
            menK(menConfig[z].q, 2);
            setTimeout(() => {
              moveModel({
                name: zzzAndWljczConfig[z],
                distance: 1700,
                time: 10000,
                shaft: "y"
              });
              setTimeout(() => {
                moveModel({
                  name: zzzAndWljczConfig[z],
                  distance: -1700,
                  time: 10000,
                  shaft: "y"
                });
              }, 15000);
            }, 8000);
          }
          if (
            this.deviceMenConfig[z].OpDoorOpen !== moveDescription.OpDoorOpen &&
            !moveDescription.OpDoorOpen
          ) {
            console.log(z + "前门关");
            menG(menConfig[z].q, 2);
          }
        }

        this.deviceMenConfig[z] = moveDescription;
      });

      // 移出底座
      // moveModel({
      //   name: zzzAndWljczConfig[z],
      //   distance: -2000,
      //   time: 10000,
      //   shaft: "y"
      // });
      // // 移入底座
      // moveModel({
      //   name: zzzAndWljczConfig[z],
      //   distance: 2000,
      //   time: 10000,
      //   shaft: "y"
      // });
    },
    // 5.判断加工中心的开关门
    operateJgzx(info) {
      // if (this.jgzxlock) return;
      const num = 20;
      for (let item of tpAndWlhConfig) {
        if (
          info.x + num > item.x &&
          info.x - num < item.x &&
          // info.y + num > item.y &&
          // info.y - num < item.y &&
          info.z >= 300
        ) {
          // this.jgzxlock = true;
          let name = item.name;
          if (!this.jgzxMenConfig[name]) {
            console.log("加工中心开门");
            this.curJgzx = name;
            moveModel({
              name: jgmenConfig[name],
              distance: 1752,
              shaft: "x",
              time: 1000
            });
            this.jgzxMenConfig[name] = true;

            if (this.curJgzx && this.jgzxMenConfig[this.curJgzx]) {
              setTimeout(() => {
                console.log("加工中心关门");
                moveModel({
                  name: jgmenConfig[this.curJgzx],
                  distance: -1752,
                  shaft: "x",
                  time: 1000
                });
                this.jgzxMenConfig[this.curJgzx] = false;
                this.curJgzx = null;
                // this.jgzxlock = false;
              }, 100 * 1000);
            }
          }

          break;
        }
      }

      // 开门
      // moveModel({
      //   name: jgmenConfig[m],
      //   distance: 1752,
      //   shaft: "x",
      //   time: 16 * 1000
      // }),
      // // 移出加工中心里面平台
      // moveModel({
      //   name: jgzxgdConfig[m],
      //   distance: 900,
      //   shaft: "y",
      //   time: 10 * 1000
      // });
      // // 移入加工中心里面平台
      // moveModel({
      //   name: jgzxgdConfig[m],
      //   distance: -900,
      //   time: 10 * 1000,
      //   shaft: "y"
      // });
      // // 关门
      // moveModel({
      //   name: jgmenConfig[m],
      //   distance: -1752,
      //   time: 25 * 1000,
      //   shaft: "x"
      // });
    }
  },
  beforeDestroy() {
    this.$bus.$off("myEvent");
    this.$bus.$off("on-view");
    this.$bus.$off("on-device");
    viewer.container.removeEventListener(
      "dblclick",
      viewer.dblclickModel.bind(viewer),
      false
    );
    viewer.container.removeEventListener(
      "touchstart",
      this.onTouchstart,
      false
    );
    viewer.container.removeEventListener("mousemove", this.debounceFn, false);
  }
};
</script>

<style lang="scss" scoped>
.amodel {
  width: 100%;
  height: 100%;
  #model-container {
    width: 100%;
    height: 100%;
  }
  .processing-progress {
    display: flex;
    width: 254px;
    position: fixed;
    left: 50%;
    bottom: 180px;
    transform: translateX(-50%);
    z-index: 999;
    /deep/ .progress-container {
      position: static !important;
    }

    > span {
      margin-right: 10px;
      flex: 1;
      color: $font-color4;
    }
    /deep/ .progress {
      width: 148px;
      height: 18px;
      box-shadow: 0px 0px 6px #ffdb27;
      > div {
        box-shadow: 0px 0px 6px #ffdb27;
      }
    }
  }
  /deep/ .state-box {
    width: 45px;
    height: 46px;
    > img {
      width: 45px;
      height: 46px;
    }
  }
  /deep/ .processing-progress2 {
    width: 148px;
    position: absolute;
    display: flex !important;
    align-items: center;
    .progress {
      height: 18px;
      box-shadow: 0px 0px 6px #ffdb27;
      > div {
        box-shadow: 0px 0px 6px #ffdb27;
      }
    }
  }
  > img {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 999;
  }
  .info-box {
    min-width: 300px;
    padding-bottom: 20px;
    // height: 320px;
    position: absolute;
    background: rgba(12, 19, 35, 0.75);
    .list-container {
      width: 90%;
      padding-top: 10px;
      .label {
        width: 100px;
      }
      .processing-progress3 {
        .progress-container {
          width: 90%;
          position: inherit;
        }
      }
    }
  }
  .tp-info-box,
  .wltp-info-box {
    display: none;
    min-width: 300px;
    padding-bottom: 20px;
    // height: 320px;
    position: absolute;
    background: rgba(12, 19, 35, 0.75);
    li {
      line-height: 16px;
    }
    .list-container {
      width: 100%;
      border-bottom: 1px solid $font-color4;
      .label {
        width: 100px;
      }
    }
    .list2-container {
      width: 100%;
      max-height: 190px;
      overflow-y: auto;

      .label {
        width: 100px;
        text-align: right;
        margin-right: 14px;
      }
      > li {
        > div {
          display: flex;
          font-size: 13px;
          color: $font-color4;
        }
      }
    }
  }
  .video-btn {
    width: 163px;
    height: 36px;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 99;
    cursor: pointer;
    @include backgroundImg("../assets/new/btn_play_2.png");
    font-family: pmzd2;
    color: $font-color;
    > span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -44%);
      white-space: nowrap;
    }
    &::before {
      display: none;
      content: "";
      width: 163px;
      height: 36px;
      position: absolute;
      left: 0;
      top: 0;
      @include backgroundImg("../assets/new/btn_play_1.png");
    }

    &:hover::before,
    &.active::before {
      display: block;
    }
    &:hover {
      color: $font-color-hover;
    }
  }
}
</style>