<template>
  <!-- 堆垛机里程 -->
  <div class="chart3-container">
    <Tab :tabData="tabData">
      <DateSwitch @on-date="handleChart"></DateSwitch>
    </Tab>
    <div id="chart3"></div>
  </div>
</template>

<script>
let chart3;
import Tab from "@/components/Tab.vue";
import DateSwitch from "@/components/DateSwitch.vue";
export default {
  components: {
    Tab,
    DateSwitch,
  },
  data() {
    return {
      tabData: ["堆垛机里程"],
    };
  },
  mounted() {
    chart3 = this.$echarts.init(document.getElementById("chart3"));

    this.handleChart(10);
    window.addEventListener("resize", this.chartResize);
  },
  methods: {
    chartResize() {
      chart3.resize();
    },
    //使用率趋势
    handleChart(dateType) {
      // 绘制图表
      const hash = {
        10: "小时",
        20: "天",
        30: "季度",
        40: "月份",
      };
      this.$api.report.ApiGetWorkSum({ dateType }).then((res) => {
        if (res.code === this.$code) {
          let chartData = res.data;
          // 绘制图表
          chart3.setOption({
            grid: {
              left: "12%",
              right: "15%",
              bottom: "18%",
              top: "15%",
              containLabel: false,
            },
            tooltip: {
              // show: true, // 是否显示提示框，默认为true
              trigger: "axis", // 数据项图形触发
              formatter: "{b0}:{c0}",
              // axisPointer: {
              //   // 指示样式
              //   type: "shadow",
              //   axis: "auto",
              // },
              // padding: 5,
              textStyle: {
                // 提示框内容的样式
                color: "#fff",
                fontSize: 14,
              },
            },
            xAxis: {
              type: "category",
              name: hash[dateType],
              data: Object.keys(chartData).map((m) => parseInt(m) + 1),
              boundaryGap: false, //坐标轴两边是否留白
              axisLabel: {
                show: true,
                //坐标轴刻度标签的相关设置
                textStyle: {
                  color: "rgba(151,217,242,.4)",
                },
                // interval:0,
                // rotate:40
              },
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            yAxis: {
              type: "value",

              nameTextStyle: {
                color: "#ffffff",
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
              },
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: "#ffffff",
                },
                show: false,
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            series: [
              {
                data: Object.values(chartData),
                type: "bar",
                barWidth: "40%",
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        { offset: 0, color: "rgba(0,247,252,1)" },
                        { offset: 1, color: "rgba(10,77,129,1)" },
                      ]
                    ),
                  },
                  //   color: "#678df0",
                },
              },
            ],
          });
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.chartResize);
  },
};
</script>

<style lang="scss" scoped>
.chart3-container {
  width: 100%;
  height: 272px;
  padding-top: 40px;
  position: relative;
  background: rgba(30, 45, 75, 0.7);
  #chart3 {
    width: 100%;
    height: 100%;
  }
}
</style>