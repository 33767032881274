<template>
  <!-- 订单信息 -->
  <div class="panel-lsgjxx">
    <div class="lsgjxx-box">
      <div class="search-box xf-input">
        <label for>状态</label>
        <el-select v-model="selectIds" multiple collapse-tags placeholder="请选择" style="margin-right: 20px;">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <label for>订单时间</label>
        <el-date-picker
          type="date"
          placeholder="开始时间"
          v-model="dataForm.beginTime"
          :picker-options="startDatePicker"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          popper-class="popper-class"
        ></el-date-picker>
        <div class="line"></div>
        <el-date-picker
          type="date"
          placeholder="结束时间"
          v-model="dataForm.endTime"
          :picker-options="endDatePicker"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          popper-class="popper-class"
        ></el-date-picker>
        <el-button type="primary" class="xf-button" size="medium" @click="handleQuery">查询</el-button>
      </div>
      <div class="table-box">
        <el-table class="xf-table" ref="multipleTable" stripe :data="tableData">
          <el-table-column label="序号" type="index" width="80" :index="indexMethod"></el-table-column>
          <el-table-column prop="orderNumber" label="订单编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="name" label="主数据编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="quantity" label="总零件数" show-overflow-tooltip center></el-table-column>
          <el-table-column prop="readyQuantity" label="已完成零件数" show-overflow-tooltip center></el-table-column>
          <el-table-column prop="workInProgressQuantity" label="在制数量" show-overflow-tooltip center></el-table-column>
          <el-table-column prop="scrapQuantity" label="报废数量" show-overflow-tooltip center></el-table-column>
          <el-table-column prop="dateStarted" label="开始日期" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-show="scope.row.dateStarted">{{scope.row.dateStarted | dateFormat('yyyy-MM-dd')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dateCompleted" label="结束日期" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-show="scope.row.dateCompleted">{{scope.row.dateCompleted | dateFormat('yyyy-MM-dd')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="priority" label="优先级" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="partDrawingNumber" label="零件图号"></el-table-column> -->
          <!-- <el-table-column prop="batchNumber" label="批次号"></el-table-column> -->
          <el-table-column prop="orderStatus" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.orderStatus | orderStateTranscoding}}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="dueDate" label="订单日期" show-overflow-tooltip></el-table-column> -->
          <!-- <el-table-column
              prop="restoreTime"
              label="恢复时间"
              show-overflow-tooltip
            >
          </el-table-column>-->
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
          class="xf-pagination"
          background
          prev-text="上一页"
          next-text="下一页"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Tab from "@/components/Tab.vue";
export default {
  components: {
    Panel,
    Tab
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "订单暂停"
        },
        {
          value: "1",
          label: "计划订单"
        },
        {
          value: "2",
          label: "订单释放"
        },
        {
          value: "3",
          label: "订单完成"
        },
        {
          value: "4",
          label: "紧急订单"
        }
      ],
      selectIds: [],
      dataForm: {
        beginTime: null,
        endTime: null
      },
      startDatePicker: this.beginDate(), //开始时间控制
      endDatePicker: this.processDate(), //结束时间控制
      tableData: [],
      currentPage: 1,
      pageSize: 13,
      total: 0
    };
  },
  mounted() {
    this.getOrderData();
  },
  methods: {
    handleQuery() {
      this.currentPage = 1;
      this.getOrderData();
    },
    // 获取订单信息
    getOrderData() {
      const params = {
        beginTime: this.dataForm.beginTime,
        endTime: this.dataForm.endTime,
        current: this.currentPage,
        size: this.pageSize
      };
      if (this.selectIds.length > 0) {
        params.type = this.selectIds.join(',');
      }
      this.$api.other.ApiGetOrderData(params).then(res => {
        if (res.code === this.$code) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 获取设备历史故障信息
    // getMachineErrorHistory() {
    //   const params = {
    //     errorCreateDate: this.dataForm.beginTime,
    //     errorCreateDateEnd: this.dataForm.endTime,
    //     pageNum: this.currentPage,
    //     pageSize: this.pageSize
    //   };
    //   this.$api.device.ApiGetMachineErrorHistory(params).then((res) => {
    //     if (res.code === this.$code) {
    //       this.tableData = res.rows;
    //       this.total = res.total;
    //     }
    //   });
    // },
    beginDate() {
      const that = this;
      return {
        disabledDate(time) {
          let endDateVal = that.dataForm.endTime;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
          return time.getTime() > Date.now() - 8.64e6; // 如果没有后面的-8.64e6就是不可以选择今天的
        }
      };
    },
    processDate() {
      const that = this;
      return {
        disabledDate(time) {
          let beginDateVal = that.dataForm.beginTime;
          if (beginDateVal) {
            return (
              time.getTime() < new Date(beginDateVal).getTime() ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
          return time.getTime() > Date.now() - 8.64e6;
        }
      };
    },
    indexMethod(index) {
      index = index + 1 + (this.currentPage - 1) * this.pageSize;
      return index;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOrderData();
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-lsgjxx {
  width: 100%;
  height: 100%;
  padding: 82px 56px 10px;
  overflow-y: auto;
  background: #161f2d !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99 !important;
  /deep/ .xf-input {
    .el-date-editor {
      width: 200px;
      height: 34px;
    }
    .el-input__inner {
      width: 200px;
      height: 34px;
    }
  }
  .lsgjxx-box {
    padding-top: 40px;
    .alarm-level {
      color: $font-color-hover;
    }
    .alarm-level2 {
      color: $font-color3;
    }
    .search-box {
      justify-content: flex-end;
      position: relative;
      padding-bottom: 12px;
      // &::before{
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      //   content: '';
      //   display: inline-block;
      //   width: 198px;
      //   height: 45px;
      //   @include backgroundImg("../assets/new/form_title.png");
      // }
    }
  }
}
</style>