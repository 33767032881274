<template>
  <div :class="['panel-container', panelObj.boxClass]">
    <i class="el-icon-close" v-if="panelObj.isShowClose" @click="close"></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    panelParams: {
      type: Object,
      require: true,
    },
  },
  watch: {
    panelParams: {
      handler(obj) {
        Object.assign(this.panelObj, obj);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      panelObj: {
        boxClass: "panel-2",
        isShowClose: true,
      },
    };
  },
  methods: {
    close() {
      this.$bus.$emit("on-close", this.panelParams.closeType);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  padding: 42px 20px 10px;
  position: relative;
  background: rgba(12,19,35,0.7);
  .el-icon-close {
    position: absolute;
    top: 14px;
    right: 26px;
    z-index: 999;
    cursor: pointer;
    color: #2ec6ff;
    font-size: 20px;
  }
  &.panel-1 {
    width: 420px;
    height: 806px;
    // @include backgroundImg("../assets/img/mb_1.png");
  }
  &.panel-2 {
    width: 380px;
    height: 270px;
    // @include backgroundImg("../assets/img/mb_2.png");
  }
  &.panel-3 {
    width: 320px;
    height: 548px;
    // @include backgroundImg("../assets/img/mb_3.png");
  }
  &.panel-4 {
    width: 740px;
    height: 660px;
    // @include backgroundImg("../assets/img/mb_4.png");
  }
  &.panel-5 {
    width: 860px;
    height: 660px;
    // @include backgroundImg("../assets/img/mb_5.png");
  }
  // &.panel-6 {
  //   width: 360px;
  //   height: 560px;
  //   @include backgroundImg("../assets/img/mb_6.png");
  // }
  &.panel-7 {
    width: 290px;
    height: 780px;
    // @include backgroundImg("../assets/img/mb_7.png");
  }
  &.panel-8 {
    width: 558px;
    height: 268px;
    @include backgroundImg("../assets/new/pop_bg.png");
  }
}
</style>