import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const other = {
  // 获取托盘信息
  ApiGetPalletData(params) {
    return axios({
      method: 'get',
      url: `${base.host}/pallet/getPalletByType`,
      params
    })
  },
  // 获取零件信息
  ApiGetPartData(partID) {
    return axios({
      method: 'get',
      url: `${base.host}/pallet/${partID}`,
    })
  },
  // 获取零件信息
  ApiGetInfoByMaterialId(params) {
    return axios({
      method: 'get',
      url: `${base.host}/part/getInfoByMaterialId`,
      params
    })
  },
  // 根据物料id获取生产进度接口
  ApiGetProductById(materialPartId) {
    return axios({
      method: 'get',
      url: `${base.host}/work/getProductById/${materialPartId}`,
    })
  },
  // 获取订单信息
  // ApiGetOrderData(params) {
  //   return axios({
  //     method: 'get',
  //     url: `${base.host}/order/getOrderData`,
  //     params
  //   })
  // },
  // 获取订单信息
  ApiGetOrderData(params) {
    return axios({
      method: 'get',
      url: `${base.host}/order/getOrderDataNew`,
      params
    })
  },
  // 获取历史任务信息
  ApiGetHistoryWorkList(params) {
    return axios({
      method: 'get',
      url: `${base.host}/work/list`,
      params
    })
  },

  // 备份数据
  ApiBackupHistory(params) {
    return axios({
      method: 'get',
      url: `${base.host}/order/backupHistory?` + qs.stringify(params)
    })
  },
  // 查询是否备份成功
  ApiBackupSuccess(params) {
    return axios({
      method: 'get',
      url: `${base.host}/order/backupSuccess` + qs.stringify(params)
    })
  },
  // 清除数据
  ApiDeleteByTime(params) {
    return axios({
      method: 'get',
      url: `${base.host}/order/deleteByTime?` + qs.stringify(params)
    })
  },
}
export default other