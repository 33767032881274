import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/index'
import echarts from 'echarts'

Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;
Vue.prototype.$bus = new Vue();
Vue.prototype.$code = 200;

Vue.config.productionTip = false

import { Progress, Carousel, CarouselItem, Select, Option, Button, Input, DatePicker, Table, TableColumn, Pagination, Tree, Message, Loading, Dropdown, DropdownMenu, DropdownItem} from 'element-ui';
Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Tree);
Vue.use(Loading);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.prototype.$message = Message;

import * as filters from './utils/filters.js'
Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
