<template>
  <!-- 物料信息 -->
  <div class="panel-sbxx">
    <Panel :panelParams="panelParams">
      <Tab :tabData="tabData" @on-change="onChangeTab" ref="tab">
       
        <DateSwitch v-show="tabIndex === 3" @on-date="handleLsrwChart"></DateSwitch>
      </Tab>
      <div class="wlxx-box" v-show="tabIndex === 0">
        <AreaName v-if="proList.length > 0" title="生产进度" />
        <div class="list-box">
          <div class="list" v-for="item in proList" :key="item.label">
            <div class="label">{{ item.label }}</div>
            <Progress :percentage="item.value" />
          </div>
        </div>

        <AreaName title="参数信息" />
        <List :listData="listData1" />
      </div>
      <div v-show="tabIndex === 1">
        <AreaName title="参数信息" />
        <List :listData="listData2" class="sbxx-list" />
        <!-- <AreaName title="工作安排" />
        <div class="work-content">暂无数据</div> -->
      </div>

      <div class="ddxx-box" v-show="tabIndex === 2">
        <div class="search-box xf-input">
          <label for="">订单日期</label>
          <el-date-picker
            type="date"
            placeholder="开始日期"
            v-model="dataForm.startTime"
            :picker-options="startDatePicker"
            format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
          <div class="line"></div>
          <el-date-picker
            type="date"
            placeholder="结束日期"
            v-model="dataForm.endTime"
            :picker-options="endDatePicker"
            format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
          <el-button class="xf-button" type="primary" size="medium" @click="getOrderData"
            >查询</el-button
          >
        </div>
        <div class="table-box">
          <el-table
            class="xf-table"
            ref="multipleTable"
            stripe
            :data="ddxxTableData"
          >
            <el-table-column
              label="序号"
              width="50"
              type="index"
              :index="indexMethod"
            >
            </el-table-column>
            <el-table-column
              prop="orderNo"
              label="订单编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="partDrawingNumber"
              label="零件图号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="serialNumber"
              label="毛坯批次号"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- <el-table-column
              prop="createDate"
              label="订单日期"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column
              prop="processingStatus"
              label="加工执行状态"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="加工百分比进度条"
              width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <Progress
                  :percentage="scope.row.processingProgress * 100"
                ></Progress>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            class="xf-pagination"
            background
            prev-text="上一页"
            next-text="下一页"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="lsrw-box" v-show="tabIndex === 3">
        <div id="lsrw"></div>
        <div class="line"></div>
        <div class="table-box">
          <el-table
            class="xf-table"
            ref="multipleTable"
            stripe
            :data="lsrwTableData"
          >
            <el-table-column label="序号" type="index" :index="indexMethod2" width="100">
            </el-table-column>
            <el-table-column
              prop="orderNo"
              label="订单编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="partDrawingNumber"
              label="零件图号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="serialNumber"
              label="毛坯批次号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="完成时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="batchCompletedQuantity"
              label="完成量"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- <el-table-column
              prop="address"
              label="历史里程数"
              show-overflow-tooltip
            >
            </el-table-column> -->
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            class="xf-pagination"
            background
            prev-text="上一页"
            next-text="下一页"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize2"
            layout="prev, pager, next, jumper"
            :total="total2"
          >
          </el-pagination>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Tab from "@/components/Tab.vue";
import List from "@/components/List.vue";
import Progress from "@/components/Progress";
import AreaName from "@/components/AreaName.vue";
import DateSwitch from "@/components/DateSwitch.vue";
let lsrw;
export default {
  components: {
    Panel,
    Tab,
    List,
    Progress,
    AreaName,
    DateSwitch
  },
  data() {
    return {
      tabData: ["物料信息", "设备信息"],
      tabIndex: 0,
      panelParams: {
        boxClass: "panel-1",
        isShowClose: true,
        closeType: "isShowPanelSbxx",
      },
      proList: [
        { label: "整体总进度", value: '0' },
        // { label: "第一道工序", value: 0 },
        // { label: "第二道工序", value: 0 },
        // { label: "第三道工序", value: 0 },
        // { label: "第四道工序", value: 0 },
        // { label: "第五道工序", value: 0 },
      ],
      listData1: [
        // { label: "零件号", value: "", prop: "partId" },
        // { label: "零件说明", value: "", prop: "partDescription" },
        // { label: "设备编号", value: "", prop: "deviceId" },
        { label: "设备名称", value: "", prop: "deviceName" },
        // { label: "物料编号", value: "", prop: "materialPartId" },
        // { label: "物料类型", value: "", prop: "materialType" },
        { label: "图号", value: "", prop: "partDrawingNumber" },
        { label: "批次", value: "", prop: "batchNumber" },
        // { label: "物料说明", value: "", prop: "materialPartDescription" },
        { label: "订单号", value: "", prop: "productionOrder" },
        { label: "物料状态", value: "", prop: "type" },
        // { label: "生产订单号", value: "", prop: "productionOrder" },
        // { label: "零件数量", value: "", prop: "quantity" },
        // { label: "是否合格", value: "", prop: "eligibility" },
      ],
      listData2: [
        { label: "设备名称", value: "", prop: "device" },
        // { label: "设备编号", value: "", prop: "deviceNo" },
        // { label: "设备类型", value: "", prop: "deviceType" },
        { label: "状态", value: "", prop: "status" },
        // { label: "设备位置", value: "", prop: "location" },
        { label: "进给速度", value: "", prop: "feedRate" },
        { label: "主轴转速", value: "", prop: "spindleSpeed" },
        // { label: "程序名称", value: "", prop: "processingProcedureName" },
        { label: "轴电流", value: "", prop: "shaftCurrent" },
        // { label: "设备说明", value: "", prop: "moveDescription" },
      ],
      dataForm: {
        startTime: null,
        endTime: null,
      },
      startDatePicker: this.beginDate(), //开始时间控制
      endDatePicker: this.processDate(), //结束时间控制
      ddxxTableData: [],
      lsrwTableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      currentPage2: 1,
      pageSize2: 5,
      total2: 0
    };
  },
  mounted() {
    // this.getOrderData();
    // this.getHistoryWorkList();

    this.handleLsrwChart(10);
    lsrw = this.$echarts.init(document.getElementById("lsrw"));
    window.addEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      lsrw.resize();
    },
    // 获取订单信息
    getOrderData() {
      const params = {
        createDate: this.dataForm.startTime,
        createDateEnd: this.dataForm.endTime,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.other.ApiGetOrderData(params).then((res) => {
        if (res.code === this.$code) {
          this.ddxxTableData = res.rows;
          this.total = res.total;
        }
      });
    },
    // 获取历史任务信息
    getHistoryWorkList() {
      const params = {
        pageNum: this.currentPage2,
        pageSize: this.pageSize2,
      };
      this.$api.other.ApiGetHistoryWorkList(params).then((res) => {
        if (res.code === this.$code) {
          res.rows.forEach(item => {
            item.orderNo = item.orderNo || '--';
            item.partDrawingNumber = item.partDrawingNumber || '--';
            item.serialNumber = item.serialNumber || '--';
          });
          this.lsrwTableData = res.rows;
          this.total2 = res.total;
        }
      });
    },
    onChangeTab(tabIndex, name) {
      this.tabIndex = tabIndex;
      const hash = {
        物料信息: "panel-1",
        设备信息: "panel-1",
        订单信息: "panel-4",
        历史任务: "panel-4",
      };
      if (hash[name]) {
        this.panelParams.boxClass = hash[name];
      }
    },
    // 历史任务图表
    handleLsrwChart(dateType) {
      const hash = {
        10: "小时",
        20: "天",
        30: "季度",
        40: "月份",
      };
      this.$api.report.ApiGetWorkSum({ dateType }).then((res) => {
        if (res.code === this.$code) {
          let chartData = res.data;
          // 绘制图表
          lsrw.setOption({
            grid: {
              left: "10%",
              right: "10%",
              bottom: "10%",
              top: "15%",
              containLabel: false,
            },
            tooltip: {
              show: true, // 是否显示提示框，默认为true
              trigger: "item", // 数据项图形触发
              formatter: "{c0}个",
              axisPointer: {
                // 指示样式
                type: "shadow",
                axis: "auto",
              },
              padding: 5,
              textStyle: {
                // 提示框内容的样式
                color: "#fff",
                fontSize: 18,
              },
            },
            xAxis: {
              type: "category",
              name: hash[dateType],
              data: Object.keys(chartData).map((m) => parseInt(m) + 1),
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
                interval: 0,
                // rotate: 40,
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            yAxis: {
              type: "value",

              nameTextStyle: {
                color: "#ffffff",
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
              },
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: "#ffffff",
                },
                show: false,
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            series: [
              {
                data: Object.values(chartData),
                type: "bar",
                barWidth: "40%",
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        { offset: 0, color: "rgba(0,247,252,1)" },
                        { offset: 1, color: "rgba(10,77,129,1)" },
                      ]
                    ),
                  },
                  //   color: "#678df0",
                },
              },
            ],
          });
        }
      });
    },
    beginDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.dataForm.endTime) {
            //如果结束时间不为空，则小于结束时间
            return new Date(self.dataForm.endTime).getTime() < time.getTime();
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    processDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.dataForm.startTime) {
            //如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.dataForm.startTime).getTime() > time.getTime();
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    indexMethod(index) {
      index = index + 1 + (this.currentPage - 1) * this.pageSize;
      return index;
    },
    indexMethod2(index) {
      index = index + 1 + (this.currentPage2 - 1) * this.pageSize2;
      return index;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOrderData();
    },
    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.getHistoryWorkList();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.getHistoryWorkList();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss" scoped>
.panel-sbxx {
  .wlxx-box {
    height: 92%;
    overflow-y: auto;
    .list-box {
      width: 94%;
      .list {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        .label {
          width: 80px;
          margin-right: 10px;
          line-height: 14px;
          color: $font-color4;
        }
      }
    }
  }
  .ddxx-box {
    padding-top: 20px;
  }
  .work-content {
    padding-left: 14px;
    color: $font-color4;
  }
  /deep/ .progress-container {
    width: 130px;
    .progress {
      height: 16px;
    }
  }
  .lsrw-box {
    #lsrw {
      width: 680px;
      height: 200px;
    }
    .line {
      width: 680px;
      height: 1px;
      margin-top: 14px;
      background: rgba(46, 198, 255, 0.4);
    }
  }
  .date-switch-box {
    right: 80px;
  }
}
</style>