<template>
  <div class="four-container">
    <div class="content-wrap" v-for="item in 6" :key="item">
      <div class="left-wrap">
        <div>DMU160P-3</div>
      </div>
      <div class="middle-wrap">
        <div class="pie-wrap">
          <Pie></Pie>
        </div>
        <div class="pie-wrap">
          <Pie></Pie>
        </div>
      </div>
      <div class="right-wrap">
        <Bar :barId="'bar-one' + item"></Bar>
      </div>
    </div>
  </div>
</template>

<script>
import Pie from "./pie.vue";
import Bar from "./bar.vue";
export default {
  components: {
    Pie,
    Bar
  }
};
</script>

<style lang="scss" scoped>
.four-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .content-wrap {
    height: 33.33%;
    display: flex;
    justify-content: center;
    .left-wrap {
      width: 165px;
      height: 100%;
      padding: 7.5px;
      > div {
        width: 100%;
        height: 100%;
        background: #0a093e;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: Source Han Sans SC VF;
        font-weight: 500;
        color: #e7e7ff;
      }
    }
    .middle-wrap {
      width: 275px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .pie-wrap {
        width: 100%;
        height: 100%;
        padding: 7.5px;
      }
    }
    .right-wrap {
      width: 445px;
      height: 100%;
      padding: 7.5px;
    }
  }
}
</style>