/**
 * 接口域名的管理
 */
const base = {
    host: interfaceUrl
    // host: 'http://xifei-admin.laozicloud.net/prod-api'
    // host: 'http://172.10.188.16:8082/prod-api' //园区网
    // host: 'http://172.20.20.28:8082/prod-api' //工控网

    // 192.168.100.11 杨剑笔记本
    // 192.168.100.12 台式机 法斯顿
    // 192.10.10.90 工控网IP
    // 192.10.10.254 网关

    // host: 'http://172.16.10.157:8080' // 杨建
    // host: 'http://172.16.10.220:8080' // 公司杨建
    // host: 'http://192.168.100.11:8080' // 本地
    // host: 'http://59.110.47.169:8081/prod-api' //测试服务器
    
}

export default base;