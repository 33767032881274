<template>
  <div class="pie-container">
    <div :id="pieId" class="echart-box"></div>
  </div>
</template>

<script>
export default {
  name: "Pie",
  props: {
    pieId: {
      type: String
    },
    flag: {
      type: String
    },
    pieList: {
      type: Array,
      default: () => [
        // { name: "闲置", value: 20, color: "#87CA69" },
        // { name: "使用", value: 30, color: "#FF5757" },
        // { name: "借出", value: 40, color: "#4E64FC" },
        // { name: "维修", value: 20, color: "#4EACFC" },
        // { name: "报废", value: 50, color: "#FAC858" },
      ]
    },
    // type: {
    //   type: Number,
    //   default: 3
    // },
    title: {
      type: String
    }
  },
  watch: {
    pieList: {
      handler(val) {
        this.initChart();
        // console.log(val)
      },
      deep: true
    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.pieEchart = this.$echarts.init(document.getElementById(this.pieId));
    window.addEventListener("resize", this.resize);
    this.initChart();
  },
  methods: {
    resize() {
      this.pieEchart.resize();
    },
    initChart() {
      let percentage;
      let val = this.pieList[0].value;
      if (this.flag == 1) {
        percentage = val;
      } else {
        percentage = val > 0 ? `${val}%` : '0%';
      }

      // 绘制图表
      this.pieEchart.setOption({
        tooltip: {
          trigger: "item",
          transitionDuration: 0,
          position: ["46%", "10%"],
          formatter: params => {
              if (!params.data.name) return;
            if (this.flag == 1) {
              return `
                    ${params.data.name}：${params.data.value}
                `;
            } else {
              return `
                ${params.data.name}：${params.data.value}%
               `;
            }
          }
        },
        legend: {
          show: false,
          top: "5%",
          left: "center"
        },
        color: this.pieList.map(m => m.color),
        title: {
          show: true,
          text: this.title, //主标题
          subtext: percentage, //副标题：企业数量值，此处用标题方式来显示
          left: "center",
          top: "40%",
          z: 0,
          zlevel: 100,
          textStyle: {
            color: "#fff",
            fontSize: 20,
            align: "center"
          },
          subtextStyle: {
            //设置副标题的样式
            textAlign: "center",
            color: "#fff",
            fontSize: 16,
            fontWeight: 400
          }
        },
        graphic: {
          type: "text",
          left: "center",
          top: "40%",
          style: {
            // text: "运动达标率",
            textAlign: "center",
            fill: "#333",
            fontSize: 12
            // fontWeight: 700,
          }
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["60%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: this.pieList
          }
        ]
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
.pie-container {
  width: 100%;
  height: 100%;
  display: flex;
  .echart-box {
    width: 100%;
    height: 100%;
  }
}
</style>
