<template>
  <!-- 数据概览 -->
  <div class="chart1-container">
    <Tab :tabData="tabData"></Tab>
    <div class="container">
      <div class="item-box">
        <div class="value">{{value + '%'}}</div>
        <el-dropdown
          class="xf-dropdown"
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            {{ selectValue }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1月">1月</el-dropdown-item>
            <el-dropdown-item command="2月">2月</el-dropdown-item>
            <el-dropdown-item command="3月">3月</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="title">累计平均利用率</div>
      </div>
      <div class="item-box">
        <div class="value">{{value2 + '%'}}</div>
        <el-dropdown
          class="xf-dropdown"
          trigger="click"
          @command="handleCommand2"
        >
          <span class="el-dropdown-link">
            {{ selectValue2 }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="第一周">第一周</el-dropdown-item>
            <el-dropdown-item command="第二周">第二周</el-dropdown-item>
            <el-dropdown-item command="第三周">第三周</el-dropdown-item>
            <el-dropdown-item command="第四周">第四周</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="title">8760速度计</div>
      </div>
      <div class="item-box">
        <div class="value">{{value3 + '%'}}</div>
        <el-dropdown
          class="xf-dropdown"
          trigger="click"
          @command="handleCommand3"
        >
          <span class="el-dropdown-link">
            {{ selectValue3 }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="设备1">设备1</el-dropdown-item>
            <el-dropdown-item command="设备2">设备2</el-dropdown-item>
            <el-dropdown-item command="设备3">设备3</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="title">法斯顿OEE</div>
      </div>
    </div>
  </div>
</template>

<script>
let sylqsChart;
import Tab from "@/components/Tab.vue";
import DateSwitch from "@/components/DateSwitch.vue";
export default {
  components: {
    Tab,
    DateSwitch,
  },
  data() {
    return {
      tabData: ["数据概览"],
      selectValue: "1月",
      selectValue2: "下一周",
      selectValue3: "设备1",
      value: 10,
      value2: 20,
      value3: 15
    };
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      this.selectValue = command;
      this.value = parseInt(Math.random() * 100);
    },
    handleCommand2(command) {
      this.selectValue2 = command;
      this.value2 = parseInt(Math.random() * 100);
    },
    handleCommand3(command) {
      this.selectValue3 = command;
      this.value3 = parseInt(Math.random() * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart1-container {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 40px;
  background: rgba(30, 45, 75, 0.7);
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item-box {
      height: 76%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .value {
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include backgroundImg("../../assets/new/img_c_b.png");
        line-height: 32px;
        font-size: 18px;
        font-weight: normal;
        font-family: pmzd2;
        color: $font-color;
      }
      &:nth-child(2) {
        .value {
          color: #ffdb27;
          @include backgroundImg("../../assets/new/img_c_g.png");
        }
      }
      &:nth-child(3) {
        .value {
          color: #5fffd3;
          @include backgroundImg("../../assets/new/img_c_y.png");
        }
      }
      .title {
        font-size: 16px;
        color: $font-color4;
      }
    }
  }
}
</style>