<template>
  <div class="container" @click="hideColDialog">
    <div class="top-wrap">
      <div class="wrap" v-for="item in topList" :key="item.key">
        <div class="flex-wrap" :style="{'background': '#fff'}">
          <div class="top">
            <span>160P-3</span>
            <span>1周</span>
          </div>
          <div class="middle">
            <div class="progress-wrap">
              <el-progress
                type="dashboard"
                stroke-linecap="butt"
                :format="format"
                :percentage="30"
                :stroke-width="10"
                color="#FF576B"
              ></el-progress>
              <span>0</span>
              <span>小时/年</span>
              <span>8760</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-wrap">
      <div class="wrap">
        <h1>计划</h1>
        <div class="content">
          <ul class="left-list">
            <li>
              <i></i>
              <span>160P-3</span>
            </li>
            <li>
              <i></i>
              <span>160P-3</span>
            </li>
            <li>
              <i></i>
              <span>160P-3</span>
            </li>
          </ul>
          <div class="right-item">
            <ul class="calibration">
              <li v-for="item in calibList" :key="item.name">
                <span>{{item.name}}</span>
                <div></div>
              </li>
            </ul>
            <div class="box-wrap">
              <div class="row" v-for="(row, index) in boxList" :key="index">
                <div
                  class="col"
                  v-for="(col, index) in row"
                  :key="index"
                  :style="{'width': col.width}"
                  @click.prevent="showColDialog($event, col)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-dialog">
      <span>名称xxx</span>
    </div>
  </div>
</template>

<script>
let dom;
export default {
  data() {
    return {
      topList: [
        {
          name: "160P-3",
          state: "忙碌",
          num: 2,
          color: "#FF576B",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "未知",
          num: 2,
          color: "#96A7BB",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "手动",
          num: 2,
          color: "#1CDDF9",
          width: "20%",
          name2: "Y955Z0601"
        },
        {
          name: "160P-3",
          state: "未知",
          num: 2,
          color: "#96A7BB",
          width: "20%",
          name2: "Y955Z0601",
          num2: 0,
          time: "+8:07:04"
        },
        {
          name: "160P-3",
          state: "手动",
          num: 2,
          color: "#1CDDF9",
          width: "20%",
          name2: "Y955Z0601"
        },
        {
          name: "160P-3",
          state: "空转",
          color: "#FFA26F",
          width: "0%",
          name2: "Y955Z0601"
        }
      ],
      calibList: [
        {
          name: "0h",
          left: "5%"
        },
        {
          name: "3h",
          left: "5%"
        },
        {
          name: "6h",
          left: "5%"
        },
        {
          name: "9h",
          left: "5%"
        },
        {
          name: "12h",
          left: "5%"
        },
        {
          name: "15h",
          left: "5%"
        },
        {
          name: "18h",
          left: "5%"
        },
        {
          name: "21h",
          left: "5%"
        },
        {
          name: "24h",
          left: "5%"
        }
      ],
      boxList: [
        [
          {
            width: "5%"
          },
          {
            width: "5%"
          },
          {
            width: "5%"
          }
        ],
        [
          {
            width: "7%"
          },
          {
            width: "5%"
          },
          {
            width: "2%"
          }
        ],
        [
          {
            width: "75%"
          },
          {
            width: "15%"
          },
          {
            width: "6%"
          }
        ]
      ],
    };
  },
  methods: {
    format() {
      return "2304";
    },
    showColDialog(e, col) {
      console.log(e, col)
      dom = document.querySelector('.col-dialog');
      dom.style.left = e.clientX + 'px';
      dom.style.top = e.clientY + 'px';
      dom.style.display = 'block';
    },
    hideColDialog(e) {
      if (e.target.className !== 'col') {

        dom.style.display = 'none';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 158px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .top-wrap {
    display: flex;
    width: 100%;
    height: 33.33%;
    .wrap {
      width: 20%;
      height: 100%;
      padding: 7.5px;
      .flex-wrap {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        .top {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          color: #333333;
        }
        .middle {
          width: 100%;
          height: 252px;
          padding: 40px;
          .progress-wrap {
            width: 100%;
            height: 100%;
            position: relative;
            .el-progress {
              width: 100%;
              height: 100%;
              /deep/.el-progress-circle {
                width: 100% !important;
                height: 100% !important;
              }
              /deep/.el-progress__text {
                font-size: 36px !important;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
              }
            }
            > span {
              position: absolute;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            > span:nth-of-type(1) {
              left: 10px;
              bottom: 0;
            }
            > span:nth-of-type(2) {
              left: 50%;
              bottom: 50px;
              transform: translateX(-50%);
            }
            > span:nth-of-type(3) {
              right: 10px;
              bottom: 0;
            }
          }
        }
      }
    }
  }
  .bottom-wrap {
    width: 100%;
    height: 66.66%;
    padding: 7.5px;
    .wrap {
      width: 100%;
      height: 100%;
      background: #0a093e;
      border-radius: 10px;
      padding: 10px;
      > h1 {
        font-size: 18px;
        font-family: Source Han Sans SC VF;
        font-weight: 500;
        color: #fffefe;
        line-height: 24px;
      }
      .content {
        height: calc(100% - 24px);
        padding: 20px;
        display: flex;
        > ul {
          width: 145px;
          padding-top: 24px;
          > li {
            height: 30px;
            line-height: 30px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            > i {
              width: 8px;
              height: 8px;
              background: #2bb960;
              margin-right: 6px;
            }
            > span {
              font-weight: 500;
              color: #c3c2f3;
              line-height: 24px;
            }
          }
        }
        .right-item {
          width: calc(100% - 100px);
          height: 100%;
          position: relative;
          .calibration {
            height: 100%;
            display: flex;
            justify-content: space-around;
            > li {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              > span {
                position: absolute;
                left: 50%;
                top: -24px;
                transform: translateX(-50%);
                line-height: 24px;
                font-weight: 500;
                color: #c3c2f3;
              }
              > div {
                width: 2px !important;
                height: 100%;
                border: 1px solid #4e4d93;
                background: #4e4d93;
              }
            }
          }
          .box-wrap {
            width: 100%;
            height: 100%;
            padding-top: 22px;
            position: absolute;
            left: 0;
            top: 0;
            .row {
              display: flex;
              margin-bottom: 30px;
              .col {
                height: 30px;
                background: #9795e7;
                border-radius: 2px;
                margin-right: 3px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .col-dialog {
    display: none;
    padding: 10px;
    color: #333333;
    border-radius: 3px;
    position: absolute;
    background: #fff;
  }
}
</style>