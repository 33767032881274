<template>
  <!-- 预计停机倒计时 -->
  <div class="chart4-container">
    <Tab :tabData="tabData">
      <el-dropdown class="xf-dropdown" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ selectValue }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="加工中心1">加工中心1</el-dropdown-item>
          <el-dropdown-item command="加工中心2">加工中心2</el-dropdown-item>
          <el-dropdown-item command="加工中心3">加工中心3</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </Tab>
    <div class="container">
      <span>{{dateObj.h}}</span>
      <span>小时</span>
      <span>{{dateObj.m}}</span>
      <span>分钟</span>
      <span>{{dateObj.s}}</span>
      <span>秒</span>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/Tab.vue";
export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabData: ["预计停机倒计时"],
      selectValue: "加工中心1",
      dateObj: {
        h: '09',
        m: '09',
        s: '56'
      }
    };
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      this.selectValue = command;
      this.dateObj = {
        h: new Date().getHours(),
        m: new Date().getMinutes(),
        s: new Date().getSeconds()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chart4-container {
  width: 100%;
  height: 120px;
  margin-bottom: 10px;
  position: relative;
  padding-top: 40px;
  background: rgba(30, 45, 75, 0.7);
  /deep/ .tab-container {
    padding-right: 20px;
    .active {
      flex: 1;
    }
  }
  .xf-dropdown {
    height: 20px;
  }
  .container {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > span:nth-child(odd){
      font-size: 30px;
      font-weight: normal;
      font-family: pmzd2;
      line-height: 24px;
      color: #FF5555;
    }
    > span:nth-child(even){
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $font-color4;
    }
  }
}
</style>