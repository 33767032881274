<template>
  <div class="panel-chart">
      <chart1></chart1>
      <chart2></chart2>
      <chart3></chart3>
  </div>
</template>

<script>
import chart1 from "@/components/chart/chart1.vue";
import chart2 from "@/components/chart/chart2.vue";
import chart3 from "@/components/chart/chart3.vue";
export default {
  components: {
    chart1,
    chart2,
    chart3,
  },
  // props: ['chartIndex'],
  data() {
    return {

    };
  },
  // watch: {
  //   chartIndex: {
  //     handler(index) {
  //       if (index) {
  //       this.chart = () => import(`../components/chart/chart${index}.vue`);
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  mounted() {
    
  },
  methods: {
   
  },
 
};
</script>

<style lang="scss" scoped>
.panel-chart {
  width: 400px;
}
</style>