<template>
  <div class="panel-tjfx">
    <div class="search-box xf-input">
      <label for>设备</label>
      <el-select v-model="selectIds" placeholder="请选择" style="margin-right: 20px;">
        <el-option
          v-for="item in deviceTypeList"
          :key="item.deviceName"
          :label="item.deviceName"
          :value="item.deviceName"
        ></el-option>
      </el-select>
      <label for>时间</label>
      <el-select v-model="dateType" placeholder="请选择">
        <el-option
          v-for="item in dateTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" class="xf-button" size="medium" @click="getStatisticsByDevice">查询</el-button>
    </div>
    <div class="top-wrap">
      <Pie pieId="availability" title="可用率" :pieList="availability"></Pie>
      <Pie pieId="oee" title="法斯顿OEE" :pieList="oee"></Pie>
      <Pie pieId="speedometer" flag="1" title="8760速度计" :pieList="speedometer"></Pie>
    </div>
    <div class="bottom-wrap">
      <Pie pieId="usageTrends" title="使用率趋势" :pieList="usageTrends"></Pie>
      <Pie pieId="utilizationRate" title="利用率" :pieList="utilizationRate"></Pie>
    </div>
  </div>
</template>

<script>
import Pie from "@/components/Pie.vue";
export default {
  components: {
    Pie
  },
  data() {
    return {
      deviceTypeList: [],
      dateTypeList: [
        {
          label: "日",
          value: 1
        },
        {
          label: "周",
          value: 2
        },
        {
          label: "月",
          value: 3
        },
        {
          label: "季度",
          value: 4
        },
        {
          label: "年",
          value: 5
        }
      ],
      dateType: 2,
      availability: [
        { name: "可用率", value: null, color: "#87CA69" },
        { name: "", value: 0, color: "#e6e6e6" }
      ],
      oee: [
        { name: "法斯顿OEE", value: null, color: "#FF5757" },
        { name: "", value: 0, color: "#e6e6e6" }
      ],
      speedometer: [
        { name: "8760速度计", value: null, color: "#4E64FC" },
        { name: "", value: 0, color: "#e6e6e6" }
      ],
      usageTrends: [
        { name: "使用率趋势", value: null, color: "#4EACFC" },
        { name: "", value: 0, color: "#e6e6e6" }
      ],
      utilizationRate: [
        { name: "利用率", value: null, color: "#FAC858" },
        { name: "", value: 0, color: "#e6e6e6" }
      ],
      selectIds: null
    };
  },
  mounted() {
    this.getDeviceProduct();
  },
  methods: {
    // 获取所有设备状态和生产进度信息
    getDeviceProduct() {
      this.$api.device.ApiGetDeviceProduct().then(res => {
        if (res.code === this.$code) {
          this.deviceTypeList = res.data;
          this.selectIds = "MC6" || this.deviceTypeList[0].deviceName;
          this.getStatisticsByDevice();
        }
      });
    },
    getStatisticsByDevice() {
      this.$api.report
        .ApiGetStatisticsByDevice({
          type: this.dateType,
          device: this.selectIds
        })
        .then(res => {
          if (res.data.length) {
            const obj = res.data[0];
            this.availability[0].value = parseInt(obj.availability * 100);
            if (this.availability[0].value < 100) {
              this.availability[1].value = 100 - this.availability[0].value;
            } else {
              this.availability[1].value = null;
            }

            this.oee[0].value = parseInt(obj.oee * 100);
            if (this.oee[0].value < 100) {
              this.oee[1].value = 100 - this.oee[0].value;
            } else {
              this.oee[1].value = null;
            }

            this.speedometer[0].value = obj.speedometer;
            this.speedometer[1].value = null;

            this.usageTrends[0].value = parseInt(obj.usageTrends * 100);
            if (this.usageTrends[0].value < 100) {
              this.usageTrends[1].value = 100 - this.usageTrends[0].value;
            } else {
              this.usageTrends[1].value = null;
            }

            this.utilizationRate[0].value = parseInt(obj.utilizationRate * 100);
            if (this.utilizationRate[0].value < 100) {
              this.utilizationRate[1].value =
                100 - this.utilizationRate[0].value;
            } else {
              this.utilizationRate[1].value = null;
            }
          } else {
            this.availability = [
              { name: "可用率", value: null, color: "#87CA69" },
              { name: "", value: 0, color: "#e6e6e6" }
            ],
            this.oee = [
              { name: "法斯顿OEE", value: null, color: "#FF5757" },
              { name: "", value: 0, color: "#e6e6e6" }
            ],
            this.speedometer = [
              { name: "8760速度计", value: null, color: "#4E64FC" },
              { name: "", value: 0, color: "#e6e6e6" }
            ],
            this.usageTrends = [
              { name: "使用率趋势", value: null, color: "#4EACFC" },
              { name: "", value: 0, color: "#e6e6e6" }
            ],
            this.utilizationRate = [
              { name: "利用率", value: null, color: "#FAC858" },
              { name: "", value: 0, color: "#e6e6e6" }
            ]
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-tjfx {
  width: 100%;
  height: 100%;
  padding: 82px 56px 10px;
  overflow-y: auto;
  background: #161f2d !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .search-box {
    justify-content: flex-end;
    position: relative;
    padding-bottom: 12px;
  }
  .top-wrap,
  .bottom-wrap {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    > div {
      width: 33.33%;
      height: 100%;
    }
  }
}
</style>