<template>
  <div class="date-switch-box">
    <span @click="dateSwitch(10)" :class="{ active: chartType === 10 }"
      >日</span
    >
    /
    <span @click="dateSwitch(20)" :class="{ active: chartType === 20 }"
      >周</span
    >
    /
    <span @click="dateSwitch(30)" :class="{ active: chartType === 30 }"
      >季</span
    >
    /
    <span @click="dateSwitch(40)" :class="{ active: chartType === 40 }"
      >年</span
    >
    <!-- <span>自定义</span> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartType: 10
    }
  },
  methods: {
    dateSwitch(dateType) {
      this.chartType = dateType;
      this.$emit('on-date', dateType);
    }
  }
};
</script>

<style>
</style>