<template>
  <!-- 是否数据备份提示弹窗 -->
  <div class="panel-Bf">
    <Panel :panelParams="panelParams">
      <div class="bf-box time-box" v-show="step === 1">
        <div class="title">数据清除</div>
        <div class="xf-input">
          <label for="">清除时间</label>
          <el-date-picker
            type="date"
            placeholder="开始时间"
            v-model="deleteData.startTime"
            :picker-options="startDatePicker"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
          <div class="line"></div>
          <el-date-picker
            type="date"
            placeholder="结束时间"
            v-model="deleteData.endTime"
            :picker-options="endDatePicker"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
        </div>
        <div class="btn-box">
          <el-button class="xf-button-cancel" size="medium" @click="close">取消</el-button>
          <el-button class="xf-button" size="medium" type="primary" @click="queryIsBackup">确定</el-button>
        </div>
      </div>
      <div class="bf-box time-box" v-show="step === 2">
        <div class="title">数据备份</div>
        <div class="xf-input">
          <label for="">备份时间</label>
          <el-date-picker
            type="date"
            placeholder="开始时间"
            v-model="bfData.startTime"
            :picker-options="startDatePicker2"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
          <div class="line"></div>
          <el-date-picker
            type="date"
            placeholder="结束时间"
            v-model="bfData.endTime"
            :picker-options="endDatePicker2"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            popper-class="popper-class"
          >
          </el-date-picker>
        </div>
        <div class="btn-box">
          <el-button class="xf-button-cancel" size="medium" @click="close">取消</el-button>
          <el-button class="xf-button" size="medium" type="primary" @click="backupData">确定</el-button>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
export default {
  components: {
    Panel,
  },
  props: ["step"],
  data() {
    return {
      panelParams: {
        boxClass: "panel-8",
        isShowClose: true,
        closeType: "isShowPanelBf",
      },
      deleteData: {
        startTime: "2011-08-04",
        endTime: "2011-08-19",
      },
      bfData: {
        startTime: "2011-08-04",
        endTime: "2011-08-19",
      },
      startDatePicker: this.beginDate(1), //开始时间控制
      endDatePicker: this.processDate(1), //结束时间控制
      startDatePicker2: this.beginDate(2), //开始时间控制
      endDatePicker2: this.processDate(2), //结束时间控制
    };
  },
  mounted() {},
  methods: {
    close() {
      this.$bus.$emit("on-close", this.panelParams.closeType);
    },
    queryIsBackup() {
      // 查询是否已经备份
      this.$api.other
        .ApiBackupSuccess({
          params: {
            beginCreateDate: this.deleteData.startTime,
            endCreateDate: this.deleteData.endTime,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code === this.$code) {
            this.remodeData();
          } else {
            this.close();
            this.$parent.isShowBfTip = true;
          }
        });
    },
    // 删除数据
    remodeData() {
      this.$api.other
        .ApiDeleteByTime({
          params: {
            beginCreateDate: this.deleteData.startTime,
            endCreateDate: this.deleteData.endTime,
          },
        })
        .then((res) => {
          if (res.code === this.$code) {
            this.close();
            this.$message.success("数据清除成功！");
          } else {
            this.$message.error("数据清除失败！");
          }
        });
    },
    // 数据备份
    backupData() {
      this.$api.other
        .ApiBackupHistory({
          params: {
            beginCreateDate: this.bfData.startTime,
            endCreateDate: this.bfData.endTime,
          },
        })
        .then((res) => {
          if (res.code === this.$code) {
            this.close();
            this.$message.success("数据备份成功！");
          } else {
            this.$message.error("数据备份失败！");
          }
        });
    },
    beginDate(type) {
      const self = this;
      return {
        disabledDate(time) {
          let date = type === 1 ? self.deleteData.endTime : self.bfData.endTime;
          if (date) {
            //如果结束时间不为空，则小于结束时间
            return new Date(date).getTime() < time.getTime();
          } else {
            return time.getTime() > Date.now(); //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    processDate(type) {
      const self = this;
      return {
        disabledDate(time) {
          let date =
            type === 1 ? self.deleteData.startTime : self.bfData.startTime;
          if (date) {
            //如果开始时间不为空，则结束时间大于开始时间
            return new Date(date).getTime() > time.getTime();
          } else {
            return time.getTime() > Date.now(); //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-Bf {
  /deep/ .panel-container {
    padding: 10px;
    .el-icon-close {
      top: 32px;
      right: 40px;
    }
    .bf-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        margin-bottom: 10px;
      }
      .title {
        width: 100%;
        padding: 16px;
        border-bottom: 1px solid $font-color;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        color: $font-color;
      }
    }
    .time-box {
      .line {
        width: 14px;
        height: 2px;
        background: #2ec6ff;
        margin: 0 10px;
      }
      .xf-input {
        display: flex;
        align-items: center;
        margin-top: 35px;
        > label {
          padding-right: 10px;
          color: $font-color4;
        }
        .el-date-editor {
          width: 140px;
          height: 34px;
        }
        .el-input__inner {
          width: 140px;
          height: 34px;
        }
      }
    }
    .btn-box {
      width: 180px;
      padding-top: 35px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
