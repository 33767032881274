<template>
  <!-- 设备物料列表 -->
  <div class="panel-sblb">
    <Panel :panelParams="panelParams">
      <Tab :tabData="tabData" />
      <div class="xf-input">
        <el-input
          v-model="search"
          size="middle"
          placeholder="请输入设备名称"
          @keyup.enter.native="getDeviceProduct"
        >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="getDeviceProduct"></i>
        </el-input>
      </div>
      <el-tree
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        class="xf-tree"
      >
        <div class="tree-node" slot-scope="{ node, data }">
          <img v-if="data.status === 'Manual'" src="../assets/img/state-small1.png" alt />
          <img v-else-if="data.status === 'Service'" src="../assets/img/state-small2.png" alt />
          <img v-if="data.status" src="../assets/img/state-small3.png" alt />
          <span>{{ node.label }}</span>
          <Progress
            v-if="!data.isHideParam"
            :percentage="data.param || '0'"
            :isShowNum="false"
            :title="(data.param || '0') + '%'"
          ></Progress>
        </div>
      </el-tree>
    </Panel>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Tab from "@/components/Tab.vue";
import AreaName from "@/components/AreaName.vue";
import Progress from "@/components/Progress.vue";
export default {
  // props: ["deviceMatList"],
  components: {
    Panel,
    Tab,
    AreaName,
    Progress
  },
  data() {
    return {
      tabData: ["设备物料列表"],
      panelParams: {
        boxClass: "panel-7",
        isShowClose: true,
        closeType: "isShowPanelSblb"
      },
      search: null,
      defaultProps: {
        children: "children",
        label: "deviceName"
      },
      treeData: [],
      timer: null
    };
  },
  mounted() {
    this.getDeviceProduct();
  },
  methods: {
    // 获取所有设备状态和生产进度信息
    getDeviceProduct() {
      this.$api.device
        .ApiGetDeviceProduct({ device: this.search })
        .then(res => {
          if (res.code === this.$code) {
            this.treeData = res.data.map(m => {
              const p = m.param || "0";
              if (m.partDrawingNumber) {
                // const list = JSON.parse(m.materialId);
                // if (typeof list === "object") {
                //   m.children = list.map((device) => {
                //     return {
                //       id: device,
                //       device,
                //       param: p,
                //       // status: m.status,
                //     };
                //   });
                // } else {
                m.children = [
                  {
                    id: m.partDrawingNumber,
                    deviceName: m.partDrawingNumber,
                    isHideParam: true
                    // param: p,
                    // status: m.status,
                  }
                ];
                // }
                m.param = p;
                return m;
              } else {
                m.id = m.deviceName;
                m.param = p;
                m.children = [];
                return m;
              }
            });
          }
          this.timer = setTimeout(() => {
            this.getDeviceProduct();
          }, 10000)
        });
    }
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.panel-sblb {
  .xf-input {
    padding: 20px 0;
    /deep/ .el-input {
      display: flex;
      justify-content: center;
      > input {
        width: 248px;
      }
      .el-input__suffix {
        right: 8px;
        top: -2px;
        .el-input__suffix-inner {
          color: $font-color;
          font-size: 20px;
        }
      }
    }
  }
  .xf-tree {
    position: absolute;
    width: 92%;
    height: 620px;
    left: 8px;
    overflow-y: auto;
    /deep/ .el-tree-node__children .tree-node {
      padding-left: 16px;
    }
    .tree-node {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      > img {
        position: absolute;
        left: -38px;
      }
      /deep/ .progress-container {
        flex: 0 1 60px;
        .progress {
          height: 10px;
        }
      }
    }
    /deep/ .el-tree-node__content {
      padding-left: 34px !important;
    }
    /deep/ .el-tree-node__content:hover,
    /deep/ .el-tree-node.is-current > .el-tree-node__content {
      background: rgba(0, 103, 255, 0.2) !important;
    }
  }
}
</style>