<template>
  <div class="panel-chart">
      <chart4></chart4>
      <chart5></chart5>
      <chart6></chart6>
      <chart7></chart7>
    
  </div>
</template>

<script>
import chart4 from "@/components/chart/chart4.vue";
import chart5 from "@/components/chart/chart5.vue";
import chart6 from "@/components/chart/chart6.vue";
import chart7 from "@/components/chart/chart7.vue";
export default {
  components: {
    chart4,
    chart5,
    chart6,
    chart7
  },
  // props: ['chartIndex'],
  data() {
    return {

    };
  },
  // watch: {
  //   chartIndex: {
  //     handler(index) {
  //       if (index) {
  //       this.chart = () => import(`../components/chart/chart${index}.vue`);
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  mounted() {
    
  },
  methods: {
   
  },
 
};
</script>

<style lang="scss" scoped>
.panel-chart {
  width: 400px;
}
</style>