<template>
  <!-- 使用率趋势 -->
  <div class="chart2-container">
    <Tab :tabData="tabData">
      <DateSwitch @on-date="handleChart"></DateSwitch>
    </Tab>
    <div id="chart2"></div>
  </div>
</template>

<script>
let chart2;
import Tab from "@/components/Tab.vue";
import DateSwitch from "@/components/DateSwitch.vue";
export default {
  components: {
    Tab,
    DateSwitch,
  },
  data() {
    return {
      tabData: ["使用率趋势"],
    };
  },
  mounted() {
    chart2 = this.$echarts.init(document.getElementById("chart2"));
    this.handleChart(10);
    window.addEventListener("resize", this.chartResize);
  },
  methods: {
    chartResize() {
      chart2.resize();
    },
    //使用率趋势
    handleChart(dateType) {      
      // 绘制图表
      const hash = {
        10: "小时",
        20: "天",
        30: "季度",
        40: "月份",
      };
      this.$api.report.ApiGetWorkSum({ dateType }).then((res) => {
        if (res.code === this.$code) {
          let chartData = res.data;
          // 绘制图表
          chart2.setOption({
            grid: {
              left: "12%",
              right: "15%",
              bottom: "18%",
              top: "15%",
              containLabel: false,
            },
            tooltip: {
              trigger: "axis",
              //在这里设置
              formatter: "{b0}:{c0}",
              textStyle: {
                // 提示框内容的样式
                color: "#fff",
                fontSize: 14,
              },
            },
            xAxis: {
              type: "category",
              name: hash[dateType],
              data: Object.keys(chartData).map((m) => parseInt(m) + 1),
              boundaryGap: false, //坐标轴两边是否留白
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //x轴颜色
                },
              },
              axisLabel: {
                show: true,
                //坐标轴刻度标签的相关设置
                textStyle: {
                  color: "rgba(151,217,242,.4)",
                },
                // interval:0,
                // rotate:40
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            yAxis: {
              type: "value",
              name: "",
              nameTextStyle: {
                color: "rgba(151,217,242,.4)",
              },
              splitLine: {
                //y轴上的y轴线条相关设置
                show: false,
                lineStyle: {
                  color: "#233e64",
                },
              },
              axisLine: {
                //y轴的相关设置
                show: true,
                lineStyle: {
                  color: "rgba(151,217,242,.4)", //y轴颜色
                },
              },
              axisLabel: {
                //y轴的标签相关设置
                textStyle: {
                  color: "rgba(151,217,242,.4)",
                  //   fontSize:18
                },
              },
              axisTick: {
                show: false,
              }, //刻度点数轴
            },
            series: [
              {
                data: Object.values(chartData),
                type: "line",
                smooth: true, //是否平滑曲线显示
                symbolSize: 0, //数据点的大小，[0,0]//b表示宽度和高度
                // 背景色渐变色
                lineStyle: {
                  //线条的相关设置
                  normal: {
                    color: "#2EC6FF", // 线条颜色
                  },
                },
                areaStyle: {
                  // color: '3deaff',
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(46, 198, 255, 0.8)",
                    },
                    {
                      offset: 0.4,
                      color: "rgba(46, 198, 255, 0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(46, 198, 255, 0.0)",
                    },
                  ]),
                },
              },
            ],
          });
        }
      })
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.chartResize);
  },
};
</script>

<style lang="scss" scoped>
.chart2-container {
  width: 100%;
  height: 272px;
  margin-bottom: 10px;
  padding-top: 40px;
  position: relative;
  background: rgba(30, 45, 75, 0.7);
  #chart2 {
    width: 100%;
    height: 100%;
  }
}
</style>