// 物料状态转码
export const materialStateTranscoding = function (code) {
    const statusMap = {
        0: '毛坯',
        1: '半成品',
        2: '成品'
    };
    return statusMap[code] || '';
}
// 订单状态转码
export const orderStateTranscoding = function (code) {
    const statusMap = {
        0: '订单暂停',
        1: '计划订单',
        2: '订单释放',
        3: '订单完成',
        4: '紧急订单'
    };
    return statusMap[code] || '';
}

/**
 * 时间戳转换日期
 * @param time
 * @param format
 * @returns {string | * | void}
 */
export const dateFormat = function (time, format) {
    var t = new Date(time);
    var tf = function(i){return (i < 10 ? '0' : '') + i};
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
        switch(a){
            case 'yyyy':
                return tf(t.getFullYear());
                break;
            case 'MM':
                return tf(t.getMonth() + 1);
                break;
            case 'mm':
                return tf(t.getMinutes());
                break;
            case 'dd':
                return tf(t.getDate());
                break;
            case 'HH':
                return tf(t.getHours());
                break;
            case 'ss':
                return tf(t.getSeconds());
                break;
        }
    })
}
