// name 接口返回的设备名称
// modelName 模型名称
export const modelConfig = [
  // 物料进出站
  {
    modelName: 'M1',
    name: "M1",
    isShowHot: true
  },
  {
    modelName: 'M2',
    name: "M2",
    isShowHot: true
  },
  {
    modelName: 'M3',
    name: "M3",
    isShowHot: true
  },
  // 压块机
  // {
  //   modelName: 'ykj',
  //   name: "ykj",
  // },
  // 物料装载站
  {
    modelName: 'L1',
    name: "L1",
    isShowHot: true
  },
  {
    modelName: 'L2',
    name: "L2",
    isShowHot: true
  },
  // 堆垛机
  {
    modelName: "DDJ1",
    name: "Crane",
    isShowHot: true
  },
  // 加工中心
  {
    modelName: "MC001",
    name: "MC1",
    isShowHot: true
  },
  {
    modelName: "MC002",
    name: "MC2",
    isShowHot: true
  },
  {
    modelName: "MC003",
    name: "MC3",
    isShowHot: true
  },
  {
    modelName: "MC004",
    name: "MC4",
    isShowHot: true
  },
  {
    modelName: "MC005",
    name: "MC5",
    isShowHot: true
  },
  {
    modelName: "MC006",
    name: "MC6",
    isShowHot: true
  },
  // 去毛刺单元
  {
    modelName: "QMC",
    name: "RFC",
    isShowHot: true
  },
  // 排屑系统
  // {
  //   modelName: "cxxt",
  //   name: "ccrsstatus",
  // },

  // 翻转机
  {
    modelName: "fangzhuangji_26",
    name: "fzj",
    // isShowHot: true
  },
  {
    modelName: "CX1",
    name: "CX1",
    isShowHot: true
  },
  {
    modelName: "CX2",
    name: "CX2",
    isShowHot: true
  },
  {
    modelName: "CX3",
    name: "CX3",
    isShowHot: true
  },
  {
    modelName: "CX4",
    name: "CX4",
    isShowHot: true
  },
  {
    modelName: "CX5",
    name: "CX5",
    isShowHot: true
  },
  {
    modelName: "CX6",
    name: "CX6",
    isShowHot: true
  },
  {
    modelName: "CX7",
    name: "CX7",
    isShowHot: true
  },
  {
    modelName: "CX8",
    name: "CX8",
    isShowHot: true
  },
  {
    modelName: "CX9",
    name: "CX9",
    isShowHot: true
  },
  {
    modelName: "CX10",
    name: "CX10",
    isShowHot: true
  },
  {
    modelName: "CX11",
    name: "CX11",
    isShowHot: true
  },

  // 视觉识别装置
  // {
  //   modelName: "SBZZ",
  //   name: "txsb",
  // },
  // 冷却液系统 模型上面暂无
  {
    modelName: "组307",
    name: "coolantsystemstatus",
    isShowHot: true
  },
];

// 装载站和物料进出站开关门
export const menConfig = {
  'M1': {
    'q': 'Box550',
    'h': 'Box551'
  },
  'M2': {
    'q': 'Box548',
    'h': 'Box549'
  },
  'M3': {
    'q': 'Box540',
    'h': 'Box541'
  },
  'L1': {
    'q': 'Box553',
    'h': 'Box552'
  },
  'L2': {
    'q': 'Box547',
    'h': 'Box546'
  }
}

// 加工中心门
export const jgmenConfig = {
  'MC1': 'JGZX_door_151',
  'MC2': 'JGZX_door_155',
  'MC3': 'JGZX_door_159',
  'MC4': 'JGZX_door_163',
  'MC5': 'JGZX_door_167',
  'MC6': 'JGZX_door_171'
}

// 加工中心托盘
export const jgtpConfig = {
  'MC1': "jgtp01",
  'MC2': "jgtp02",
  'MC3': "jgtp03",
  'MC4': "jgtp04",
  'MC5': "jgtp05",
  'MC6': "jgtp06"
}

// 加工中心轨道
export const jgzxgdConfig = {
  'MC1': "JGZXTP030",
  'MC2': "JGZXTP031",
  'MC3': "JGZXTP032",
  'MC4': "JGZXTP033",
  'MC5': "JGZXTP034",
  'MC6': "JGZXTP035"
}

// 视觉识别装置
export const sjConfig = {
  'L1': {
    xName: 'SB2',
    yName: 'shijueshibie_donghua1'
  },
  'L2': {
    xName: 'SB1',
    yName: 'shijueshibie_donghua002'
  },
}

// 装载站和物料进出站
export const zzzAndWljczConfig = {
  'L1': 'jiqi005',
  'L2': 'jiqi004',
  'M1': 'gunlun002',
  'M2': 'gunlun007',
  'M3': 'gunlun008'
}

export const tpAndWlhConfig = [
  {
    name: 'TP_DiZuo1',
    x: '3045',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo2',
    x: '3045',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo3',
    x: '4465',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo4',
    x: '4465',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo5',
    x: '5885',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo6',
    x: '5885',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo7',
    x: '7305',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo8',
    x: '7305',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo9',
    x: '8725',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo10',
    x: '8725',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo11',
    x: '10145',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo12',
    x: '10145',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo13',
    x: '11565',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo14',
    x: '11565',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo15',
    x: '12985',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo16',
    x: '12985',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo17',
    x: '14405',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo18',
    x: '14405',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo19',
    x: '15825',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo20',
    x: '15825',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo21',
    x: '17245',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo22',
    x: '17245',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo23',
    x: '18665',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo24',
    x: '18665',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo25',
    x: '20085',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo26',
    x: '20085',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo27',
    x: '21505',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo28',
    x: '21505',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo29',
    x: '22925',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo30',
    x: '22925',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo31',
    x: '29360',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo32',
    x: '31330',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo33',
    x: '35395',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo34',
    x: '37090',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo35',
    x: '39060',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo36',
    x: '40755',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo37',
    x: '40755',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo38',
    x: '42450',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo39',
    x: '46515',
    y: '1039',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo40',
    x: '46515',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'whl_1000',
    x: '24265',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1001',
    x: '24265',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1002',
    x: '24265',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1003',
    x: '25365',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1004',
    x: '25365',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1005',
    x: '25365',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1006',
    x: '26635',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1007',
    x: '26635',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1008',
    x: '26635',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1009',
    x: '27735',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1010',
    x: '27735',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1011',
    x: '27735',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1012',
    x: '32945',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1013',
    x: '32945',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1014',
    x: '32945',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1015',
    x: '34045',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1016',
    x: '34045',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1017',
    x: '34045',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1018',
    x: '35390',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1019',
    x: '35390',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1020',
    x: '44065',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1021',
    x: '44065',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1022',
    x: '44065',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'whl_1023',
    x: '45165',
    y: '1039',
    z: '-1680'
  },
  {
    name: 'whl_1024',
    x: '45165',
    y: '2223',
    z: '-1680'
  },
  {
    name: 'whl_1025',
    x: '45165',
    y: '3433',
    z: '-1680'
  },
  {
    name: 'L1',
    x: '31322',
    y: '984',
    z: '-2069'
  },
  {
    name: 'L2',
    x: '37086',
    y: '984',
    z: '-2074'
  },
  {
    name: 'M1',
    x: '29357',
    y: '1025',
    z: '-1972'
  },
  {
    name: 'M2',
    x: '39048',
    y: '1025',
    z: '-1969'
  },
  {
    name: 'M3',
    x: '42436',
    y: '1025',
    z: '-1973'
  },
  {
    name: 'MC1',
    x: '9234',
    y: '1297',
    z: '2135'
  },
  {
    name: 'MC2',
    x: '16496',
    y: '1297',
    z: '2142'
  },
  {
    name: 'MC3',
    x: '23831',
    y: '1297',
    z: '2140'
  },
  {
    name: 'MC4',
    x: '31119',
    y: '1297',
    z: '2140'
  },
  {
    name: 'MC5',
    x: '38438',
    y: '1297',
    z: '2142'
  },
  {
    name: 'MC6',
    x: '45724',
    y: '1297',
    z: '2135'
  },
  {
    name: 'RFC',
    x: '4105',
    y: '1238',
    z: '2068'
  }
]

export const yTimeConfig = [
  {
    name: 'TP_DiZuo1',
    x: '3045',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo2',
    x: '3045',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo3',
    x: '4465',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo4',
    x: '4465',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo5',
    x: '5885',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo6',
    x: '5885',
    y: '3433',
    z: '-2051'
  },
  {
    name: 'TP_DiZuo7',
    x: '7305',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo8',
    x: '7305',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo9',
    x: '8725',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo10',
    x: '8725',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo11',
    x: '10145',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo12',
    x: '10145',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo13',
    x: '11565',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo14',
    x: '11565',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo15',
    x: '12985',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo16',
    x: '12985',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo17',
    x: '14405',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo18',
    x: '14405',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo19',
    x: '15825',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo20',
    x: '15825',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo21',
    x: '17245',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo22',
    x: '17245',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo23',
    x: '18665',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo24',
    x: '18665',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo25',
    x: '20085',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo26',
    x: '20085',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo27',
    x: '21505',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo28',
    x: '21505',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo29',
    x: '22925',
    y: '1039',
    z: '-2051',
    yTime: 0
  },
  {
    name: 'TP_DiZuo30',
    x: '22925',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo31',
    x: '29360',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo32',
    x: '31330',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo33',
    x: '35395',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo34',
    x: '37090',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo35',
    x: '39060',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo36',
    x: '40755',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo37',
    x: '40755',
    y: '1039',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo38',
    x: '42450',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo39',
    x: '46515',
    y: '1039',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'TP_DiZuo40',
    x: '46515',
    y: '3433',
    z: '-2051',
    yTime: 11.93
  },
  {
    name: 'whl_1000',
    x: '24265',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1001',
    x: '24265',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1002',
    x: '24265',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1003',
    x: '25365',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1004',
    x: '25365',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1005',
    x: '25365',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1006',
    x: '26635',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1007',
    x: '26635',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1008',
    x: '26635',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1009',
    x: '27735',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1010',
    x: '27735',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1011',
    x: '27735',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1012',
    x: '32945',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1013',
    x: '32945',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1014',
    x: '32945',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1015',
    x: '34045',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1016',
    x: '34045',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1017',
    x: '34045',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1018',
    x: '35390',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1019',
    x: '35390',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1020',
    x: '44065',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1021',
    x: '44065',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1022',
    x: '44065',
    y: '3433',
    z: '-1680',
    yTime: 11
  },
  {
    name: 'whl_1023',
    x: '45165',
    y: '1039',
    z: '-1680',
    yTime: 0
  },
  {
    name: 'whl_1024',
    x: '45165',
    y: '2223',
    z: '-1680',
    yTime: 8.84
  },
  {
    name: 'whl_1025',
    x: '45165',
    y: '3433',
    z: '-1680',
    yTime: 11
  }
]
