<template>
  <div class="container">
    <div class="content-wrap">
      <div class="top-wrap">
        <h1>订单</h1>
        <ul class="state-wrap">
          <li>
            <i style="background: #4BC693"></i>
            <span>就绪</span>
          </li>
          <li>
            <i style="background: #66D2FF"></i>
            <span>进行中</span>
          </li>
          <li>
            <i style="background: #FF5A7E"></i>
            <span>报废</span>
          </li>
          <li>
            <i style="background: #EAEAEA"></i>
            <span>未开始</span>
          </li>
        </ul>
      </div>
      <ul class="bottom-wrap">
        <li v-for="item in 8" :key="item">
          <div>
            <img src="../../assets/new2/11.png" alt />
            <span>3395289289123</span>
          </div>
          <div>
            <span v-if="item == 3">3395289289123</span>
            <span v-else></span>
          </div>
          <div>0/1</div>
          <div class="progress">
            <div></div>
          </div>
          <div class="time">
            <div>0d</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  .content-wrap {
    width: 1606px;
    height: 100%;
    background: #0a093e;
    border-radius: 10px;
    overflow-y: auto;
    .top-wrap {
      padding: 10px;
      position: relative;
      > h1 {
        font-size: 20px;
        font-family: Source Han Sans SC VF;
        font-weight: 500;
        color: #fffefe;
        line-height: 24px;
      }
      .state-wrap {
        width: 340px;
        position: absolute;
        left: 50%;
        top: 16px;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        > li {
          display: flex;
          align-items: center;
          > i {
            width: 20px;
            height: 9px;
            border-radius: 2px;
            margin-right: 5px;
          }
        }
      }
    }
    .bottom-wrap {
      padding-top: 30px;
      > li {
        height: 60px;
        display: flex;
        > div {
          width: 182px;
          height: 100%;
          padding: 0 30px;
          border-right: 1px solid #302e79;

          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-of-type(1) {
            width: 230px;
          }
          &:nth-of-type(3) {
            width: 90px;
          }
          > span {
            width: 124px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 18px;
            font-family: Source Han Sans SC VF;
            font-weight: 500;
            color: #fffefe;
          }
          > img {
            margin-right: 5px;
          }
        }
        .progress {
          width: 980px;
          padding: 0px 30px;
          border-right: 1px solid #302e79;
          display: flex;
          justify-content: center;
          align-items: center;
          > div {
            width: 100%;
            height: 12px;
            background: #66d2ff;
          }
        }
        .time {
          width: 134px;
          padding: 0px 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: none;
          > div {
            width: 66px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #4bc693;
            border-radius: 6px;
            font-size: 18px;
            font-family: Source Han Sans SC VF;
            font-weight: 500;
            color: #fffefe;
          }
        }
      }
    }
  }
}
</style>