<template>
  <ul class="list-container">
    <li v-for="item in listData" :key="item.label">
      <span class="label">{{ item.label }}:</span>
      <span v-if="item.label === '物料状态'">{{ item.value | materialStateTranscoding}}</span>
      <span v-else>{{ item.value }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      require: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
</style>