export const deviceProduct = [{
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "materialId": null,
    "processingprocedure": "0"
  },

  // 自己加的
  {
    "device": "fzj",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX1",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX2",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX3",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX4",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX5",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX6",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX7",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX8",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX9",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX10",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "CX11",
    "deviceNo": null,
    "deviceType": "",
    "status": 'Automatic',
    "materialId": null,
    "processingprocedure": "0"
  },

  {
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "materialId": "[\"test mc-6\"]",
    "processingprocedure": "0"
  },
  {
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "materialId": "[\"\"]",
    "processingprocedure": "0"
  },
  {
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "NoConnection",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  },
  {
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Unknown",
    "materialId": null,
    "processingprocedure": "0"
  }
]

// 根据"palletName": "pallets"  如果字段type是 Machine 就是普通托盘
// 如果字段type是 Material 就是物料托盘
// 其他就是专用托盘，比如加工中心托盘和去毛刺托盘
/********
 * 装载站-普通托盘 palletatloadingstation
 * 物料进出站-物料托盘 palletatmaterialstation
 * 堆垛机-普通托盘 palletatcrane
 * 加工中心-普通托盘 palletatmc  
 * ***********/
// 装载站托盘 palletatloadingstation
export const palletByType = [{
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1",
    "palletName": "palletatloadingstation",
    "type": "palletatloadingstation",
    "location": "L2.User",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.1",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1",
    "palletName": "pallets",
    "type": "Machine",
    "location": "L2.User",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "10",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.10",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"06gb\", \"PartDatas\": [], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1000",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1017",
    "partDatas": "[{\"PartId\": \"420*280*35\", \"MaterialId\": \"420*280*35\"}, {\"PartId\": \"850*340*100\", \"MaterialId\": \"850*340*100\"}]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1001",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1003",
    "partDatas": "[{\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}, {\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}, {\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}, {\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}, {\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}, {\"PartId\": \"JPR\", \"MaterialId\": \"JPR\"}]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1002",
    "palletName": "palletatmaterialstation",
    "type": "palletatmaterialstation",
    "location": "M1.User",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1002",
    "palletName": "pallets",
    "type": "Material",
    "location": "M1.User",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1003",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1022",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1004",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1006",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1005",
    "palletName": "pallets",
    "type": "Material",
    "location": "M3.User.2",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1006",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1007",
    "partDatas": "[{\"PartId\": \"1574584634813568746\", \"MaterialId\": \"1574584634813568746\"}, {\"PartId\": \"1574584634813568746\", \"MaterialId\": \"1574584634813568746\"}, {\"PartId\": \"Y20-5752211001-001\", \"MaterialId\": \"Y20-5752211001-001\"}]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1006",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1008",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1007",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1009",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1008",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1010",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1009",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1012",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1010",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1011",
    "partDatas": "[{\"PartId\": \"580*520*85\", \"MaterialId\": \"580*520*85\"}]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1011",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1013",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1012",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1014",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1013",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1004",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1014",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1016",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1015",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1018",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1016",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1001",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1017",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1021",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1018",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1020",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1019",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1002",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1020",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1023",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1021",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1024",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1022",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1025",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1023",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1005",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1024",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1019",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "1025",
    "palletName": "pallets",
    "type": "Material",
    "location": "Crane.Storage.1015",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "11",
    "palletName": "palletatcrane",
    "type": "palletatcrane",
    "location": "Crane.Forks",
    "partDatas": "[{\"PartId\": \"test mc-6\", \"OrderNbr\": \"test mc-6\", \"Operation\": \"10\", \"MaterialId\": \"test mc-6\", \"FactoryOrder\": \"\", \"SerialNumber\": \"SN88825917\"}]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "11",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Forks",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"TEST\", \"PartDatas\": [{\"PartId\": \"test mc-6\", \"MaterialId\": \"test mc-6\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "11",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.11",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"TEST\", \"PartDatas\": [{\"PartId\": \"test mc-6\", \"MaterialId\": \"test mc-6\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "13",
    "palletName": "palletatloadingstation",
    "type": "palletatloadingstation",
    "location": "L2.User",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "13",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.13",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "13",
    "palletName": "pallets",
    "type": "Machine",
    "location": "L2.User",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "17",
    "palletName": "palletatloadingstation",
    "type": "palletatloadingstation",
    "location": "L1.User",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "17",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.17",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "17",
    "palletName": "pallets",
    "type": "Machine",
    "location": "L1.User",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"janne\", \"PartDatas\": [], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "2",
    "palletName": "palletatmc",
    "type": "palletatmc",
    "location": "MC4.Table",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "2",
    "palletName": "pallets",
    "type": "Machine",
    "location": "MC4.Table",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "25",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.25",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "27",
    "palletName": "palletatmc",
    "type": "palletatmc",
    "location": "MC5.Table",
    "partDatas": "[{\"PartId\": \"gb11001\", \"OrderNbr\": \"test running 9.30\", \"Operation\": \"10\", \"MaterialId\": \"\", \"FactoryOrder\": \"\", \"SerialNumber\": \"\"}]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "27",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.27",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"07gb\", \"PartDatas\": [{\"PartId\": \"gb11001\", \"MaterialId\": \"gb11001\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "27",
    "palletName": "pallets",
    "type": "Machine",
    "location": "MC5.Table",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"07gb\", \"PartDatas\": [{\"PartId\": \"gb11001\", \"MaterialId\": \"gb11001\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "29",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.29",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"TESTROBO\", \"PartDatas\": [{\"PartId\": \"TESTROBO\", \"MaterialId\": \"TESTROBO\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "3",
    "palletName": "palletatmc",
    "type": "palletatmc",
    "location": "MC1.Table",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "3",
    "palletName": "pallets",
    "type": "Machine",
    "location": "MC1.Table",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "37",
    "palletName": "palletatmc",
    "type": "palletatmc",
    "location": "MC2.Table",
    "partDatas": "[{\"PartId\": \"CustomerTest\", \"OrderNbr\": \"TOOLTEST\", \"Operation\": \"10\", \"MaterialId\": \"\", \"FactoryOrder\": \"\", \"SerialNumber\": \"SN12002302\"}]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "37",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.37",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"janne\", \"PartDatas\": [{\"PartId\": \"janne\", \"MaterialId\": \"janne\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "37",
    "palletName": "pallets",
    "type": "Machine",
    "location": "MC2.Table",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"TEST\", \"PartDatas\": [{\"PartId\": \"CustomerTest\", \"MaterialId\": \"CustomerTest\"}], \"FixturePosition\": \"90.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "5",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.5",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "6",
    "palletName": "palletatmc",
    "type": "palletatmc",
    "location": "MC5.Table",
    "partDatas": "[]",
    "fixtures": null,
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-13 09:06:50",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "6",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.6",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "6",
    "palletName": "pallets",
    "type": "Machine",
    "location": "MC5.Table",
    "partDatas": "[]",
    "fixtures": "[]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "7",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.7",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"janne\", \"PartDatas\": [{\"PartId\": \"janne\", \"MaterialId\": \"janne\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "8",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.8",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"01SZ\", \"PartDatas\": [{\"PartId\": \"suozuo01\", \"MaterialId\": \"suozuo01\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  },
  {
    "searchValue": null,
    "createBy": null,
    "createTime": "2021-10-15 08:24:01",
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": null,
    "palletId": "9",
    "palletName": "pallets",
    "type": "Machine",
    "location": "Crane.Storage.9",
    "partDatas": "[]",
    "fixtures": "[{\"FixtureId\": \"janne\", \"PartDatas\": [{\"PartId\": \"janne\", \"MaterialId\": \"janne\"}], \"FixturePosition\": \"0.1\"}]",
    "partId": null,
    "partDescription": null,
    "materialPartId": null,
    "materialPartDescription": null
  }
]

// 堆垛机移动数据
export const craneData = [{
    "id": 15406,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:49Z"
  },
  // {
  //   "id": 12812,
  //   "device": "Crane",
  //   "device_no": null,
  //   "device_type": "cranestatus",
  //   "status": "Automatic",
  //   "location": "{\"forkX\": \"10145\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
  //   "move_description": null,
  //   "pallet_number": "1",
  //   "processingProcedureNum": null,
  //   "processingProcedure": null,
  //   "product_parameter": null,
  //   "duration_seconds": null,
  //   "duration": null,
  //   "fault_no": null,
  //   "fault_name": null,
  //   "error_type": null,
  //   "error_create_date": null,
  //   "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
  //   "error_level": null,
  //   "create_date": "2021-10-16T07:30:13Z"
  // },
  // {
  //   "id": 12812,
  //   "device": "Crane",
  //   "device_no": null,
  //   "device_type": "cranestatus",
  //   "status": "Automatic",
  //   "location": "{\"forkX\": \"10145\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
  //   "move_description": null,
  //   "pallet_number": "1",
  //   "processingProcedureNum": null,
  //   "processingProcedure": null,
  //   "product_parameter": null,
  //   "duration_seconds": null,
  //   "duration": null,
  //   "fault_no": null,
  //   "fault_name": null,
  //   "error_type": null,
  //   "error_create_date": null,
  //   "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
  //   "error_level": null,
  //   "create_date": "2021-10-16T07:30:13Z"
  // },
  //   "id": 12812,
  //   "device": "Crane",
  //   "device_no": null,
  //   "device_type": "cranestatus",
  //   "status": "Automatic",
  //   "location": "{\"forkX\": \"3046,255\", \"forkY\": \"1082,277\", \"forkZ\": \"-2050,708\", \"targetLocation\": \"Crane.Storage\"}",
  //   "move_description": null,
  //   "pallet_number": "1",
  //   "processingProcedureNum": null,
  //   "processingProcedure": null,
  //   "product_parameter": null,
  //   "duration_seconds": null,
  //   "duration": null,
  //   "fault_no": null,
  //   "fault_name": null,
  //   "error_type": null,
  //   "error_create_date": null,
  //   "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
  //   "error_level": null,
  //   "create_date": "2021-10-16T07:30:13Z"
  // },
  {
    "id": 12812,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"11565\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:13Z"
  },
  {
    "id": 15389,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:38Z"
  },
  {
    "id": 15389,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:38Z"
  },
  {
    "id": 12812,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"11565\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:13Z"
  },
  // {
  //   "id": 15389,
  //   "device": "Crane",
  //   "device_no": null,
  //   "device_type": "cranestatus",
  //   "status": "Automatic",
  //   "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"M2.User\"}",
  //   "move_description": null,
  //   "pallet_number": "",
  //   "processingProcedureNum": null,
  //   "processingProcedure": null,
  //   "product_parameter": null,
  //   "duration_seconds": null,
  //   "duration": null,
  //   "fault_no": null,
  //   "fault_name": null,
  //   "error_type": null,
  //   "error_create_date": null,
  //   "error_message": null,
  //   "error_level": null,
  //   "create_date": "2021-10-16T08:04:38Z"
  // },
  // 37号托盘
  
  // 物料盒
  {
    "id": 12812,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"25365\", \"forkY\": \"795\", \"forkZ\": \"-1680\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:13Z"
  },
  {
    "id": 15389,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"M2.User\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:38Z"
  },
  {
    "id": 15389,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"M2.User\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:38Z"
  },
  {
    "id": 15375,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24265\", \"forkY\": \"3188\", \"forkZ\": \"-1680\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:25Z"
  },
  {
    "id": 15361,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:13Z"
  },
  {
    "id": 15344,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:04:02Z"
  },
  {
    "id": 15331,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:03:49Z"
  },
  {
    "id": 15314,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:03:37Z"
  },
  {
    "id": 15305,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:03:26Z"
  },
  {
    "id": 15284,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:03:13Z"
  },
  {
    "id": 15269,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,179\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:03:01Z"
  },
  {
    "id": 15255,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,183\", \"forkZ\": \"-467,157\", \"targetLocation\": \"L1.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:02:50Z"
  },
  {
    "id": 15238,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"1087,249\", \"forkZ\": \"-2069,408\", \"targetLocation\": \"L1.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:02:37Z"
  },
  {
    "id": 15224,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"1088,968\", \"forkZ\": \"-0,118\", \"targetLocation\": \"L1.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:02:25Z"
  },
  {
    "id": 15211,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40718\", \"forkY\": \"1126,642\", \"forkZ\": \"-0,109\", \"targetLocation\": \"L1.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:02:14Z"
  },
  {
    "id": 15194,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,27\", \"forkY\": \"1132,992\", \"forkZ\": \"-2050,843\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:02:01Z"
  },
  {
    "id": 15182,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,28\", \"forkY\": \"1039,917\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:01:49Z"
  },
  {
    "id": 15166,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24466,87\", \"forkY\": \"1110,314\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:01:38Z"
  },
  {
    "id": 15155,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,4\", \"forkY\": \"1294,144\", \"forkZ\": \"2139,862\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:01:25Z"
  },
  {
    "id": 15134,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,4\", \"forkY\": \"1294,145\", \"forkZ\": \"2139,862\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:01:13Z"
  },
  {
    "id": 15119,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,43\", \"forkY\": \"1414,38\", \"forkZ\": \"2139,859\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:01:02Z"
  },
  {
    "id": 15106,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,43\", \"forkY\": \"1458,947\", \"forkZ\": \"2139,859\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:00:49Z"
  },
  {
    "id": 15091,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,43\", \"forkY\": \"1458,947\", \"forkZ\": \"2139,859\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:00:37Z"
  },
  {
    "id": 15075,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,43\", \"forkY\": \"1459,031\", \"forkZ\": \"2,051\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:00:26Z"
  },
  {
    "id": 15059,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23832,43\", \"forkY\": \"1459,031\", \"forkZ\": \"-0,115\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:00:13Z"
  },
  {
    "id": 15044,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23905,28\", \"forkY\": \"1458,945\", \"forkZ\": \"-0,115\", \"targetLocation\": \"MC3.Table\"}",
    "move_description": null,
    "pallet_number": "29",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T08:00:01Z"
  },
  {
    "id": 15031,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31323,1\", \"forkY\": \"1089,008\", \"forkZ\": \"-130,724\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:59:50Z"
  },
  {
    "id": 15016,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31323,1\", \"forkY\": \"1028,483\", \"forkZ\": \"-2069,612\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:59:37Z"
  },
  {
    "id": 15005,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31323,1\", \"forkY\": \"983,873\", \"forkZ\": \"-3,729\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:59:25Z"
  },
  {
    "id": 14984,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:59:14Z"
  },
  {
    "id": 14972,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:59:01Z"
  },
  {
    "id": 14954,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:58:49Z"
  },
  {
    "id": 14939,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:58:38Z"
  },
  {
    "id": 14930,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:58:25Z"
  },
  {
    "id": 14909,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:58:13Z"
  },
  {
    "id": 14899,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:58:02Z"
  },
  {
    "id": 14881,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:57:49Z"
  },
  {
    "id": 14864,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:57:37Z"
  },
  {
    "id": 14849,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:57:26Z"
  },
  {
    "id": 14835,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:57:13Z"
  },
  {
    "id": 14819,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:57:01Z"
  },
  {
    "id": 14804,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:56:50Z"
  },
  {
    "id": 14788,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,17\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:56:37Z"
  },
  {
    "id": 14773,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:56:25Z"
  },
  {
    "id": 14759,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:56:14Z"
  },
  {
    "id": 14744,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,171\", \"forkZ\": \"0,031\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:56:01Z"
  },
  {
    "id": 14729,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,189\", \"forkZ\": \"-1039,659\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": null,
    "error_level": null,
    "create_date": "2021-10-16T07:55:49Z"
  },
  {
    "id": 14715,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"1085,185\", \"forkZ\": \"-2073,68\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:55:38Z"
  },
  {
    "id": 14700,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,52\", \"forkY\": \"1085,095\", \"forkZ\": \"0,196\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:55:25Z"
  },
  {
    "id": 14684,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"22365,72\", \"forkY\": \"1085,051\", \"forkZ\": \"0,195\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:55:13Z"
  },
  {
    "id": 14668,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1459,982\", \"forkZ\": \"539,015\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:55:02Z"
  },
  {
    "id": 14653,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1391,382\", \"forkZ\": \"2141,981\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:54:49Z"
  },
  {
    "id": 14642,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,165\", \"forkZ\": \"2141,981\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:54:37Z"
  },
  {
    "id": 14629,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,165\", \"forkZ\": \"2141,981\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:54:26Z"
  },
  {
    "id": 14615,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,166\", \"forkZ\": \"1749,707\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:54:13Z"
  },
  {
    "id": 14600,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:54:01Z"
  },
  {
    "id": 14581,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,167\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:53:50Z"
  },
  {
    "id": 14564,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:53:37Z"
  },
  {
    "id": 14551,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:53:25Z"
  },
  {
    "id": 14534,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,167\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:53:14Z"
  },
  {
    "id": 14520,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:53:01Z"
  },
  {
    "id": 14504,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:52:49Z"
  },
  {
    "id": 14489,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:52:38Z"
  },
  {
    "id": 14474,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,167\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:52:25Z"
  },
  {
    "id": 14461,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:52:13Z"
  },
  {
    "id": 14444,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:52:02Z"
  },
  {
    "id": 14431,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:51:49Z"
  },
  {
    "id": 14414,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:51:37Z"
  },
  {
    "id": 14402,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:51:26Z"
  },
  {
    "id": 14386,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:51:13Z"
  },
  {
    "id": 14371,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:51:01Z"
  },
  {
    "id": 14357,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,168\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:50:50Z"
  },
  {
    "id": 14342,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,167\", \"forkZ\": \"2141,665\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:50:37Z"
  },
  {
    "id": 14330,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1309,423\", \"forkZ\": \"2141,665\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:50:25Z"
  },
  {
    "id": 14315,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1453,864\", \"forkZ\": \"2141,664\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:50:14Z"
  },
  {
    "id": 14294,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1459,905\", \"forkZ\": \"2141,664\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:50:01Z"
  },
  {
    "id": 14281,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1459,905\", \"forkZ\": \"2141,667\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:49:49Z"
  },
  {
    "id": 14266,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,19\", \"forkY\": \"1459,968\", \"forkZ\": \"141,115\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:49:38Z"
  },
  {
    "id": 14254,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16498,34\", \"forkY\": \"1459,964\", \"forkZ\": \"-0,118\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:49:25Z"
  },
  {
    "id": 14237,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"33226,14\", \"forkY\": \"1460,026\", \"forkZ\": \"-0,119\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:49:13Z"
  },
  {
    "id": 14222,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"1084,883\", \"forkZ\": \"-880,057\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:49:02Z"
  },
  {
    "id": 14204,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"997,622\", \"forkZ\": \"-2073,875\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:48:49Z"
  },
  {
    "id": 14189,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:48:37Z"
  },
  {
    "id": 14177,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:48:26Z"
  },
  {
    "id": 14159,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:48:13Z"
  },
  {
    "id": 14149,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:48:01Z"
  },
  {
    "id": 14132,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:47:50Z"
  },
  {
    "id": 14117,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:47:37Z"
  },
  {
    "id": 14105,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:47:25Z"
  },
  {
    "id": 14084,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:47:14Z"
  },
  {
    "id": 14069,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:47:01Z"
  },
  {
    "id": 14059,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:46:49Z"
  },
  {
    "id": 14042,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:46:38Z"
  },
  {
    "id": 14030,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:46:25Z"
  },
  {
    "id": 14011,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:46:13Z"
  },
  {
    "id": 13994,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:46:02Z"
  },
  {
    "id": 13979,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:45:49Z"
  },
  {
    "id": 13969,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:45:37Z"
  },
  {
    "id": 13952,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:45:26Z"
  },
  {
    "id": 13935,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:45:13Z"
  },
  {
    "id": 13925,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:45:01Z"
  },
  {
    "id": 13910,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:44:50Z"
  },
  {
    "id": 13895,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:44:37Z"
  },
  {
    "id": 13878,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:44:25Z"
  },
  {
    "id": 13860,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,128\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:44:14Z"
  },
  {
    "id": 13847,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:44:01Z"
  },
  {
    "id": 13829,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:43:49Z"
  },
  {
    "id": 13820,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:43:38Z"
  },
  {
    "id": 13805,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:43:25Z"
  },
  {
    "id": 13785,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:43:13Z"
  },
  {
    "id": 13775,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:43:02Z"
  },
  {
    "id": 13756,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:42:49Z"
  },
  {
    "id": 13740,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:42:37Z"
  },
  {
    "id": 13724,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,127\", \"forkZ\": \"0,037\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:42:26Z"
  },
  {
    "id": 13711,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,5\", \"forkY\": \"975,13\", \"forkZ\": \"-51,084\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:42:13Z"
  },
  {
    "id": 13700,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,47\", \"forkY\": \"1034,453\", \"forkZ\": \"-2073,708\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:42:01Z"
  },
  {
    "id": 13682,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,47\", \"forkY\": \"1085,129\", \"forkZ\": \"-584,486\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:41:50Z"
  },
  {
    "id": 13666,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"29532,38\", \"forkY\": \"1085,09\", \"forkZ\": \"-0,101\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:41:37Z"
  },
  {
    "id": 13655,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"6794,252\", \"forkY\": \"1084,99\", \"forkZ\": \"-0,101\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:41:25Z"
  },
  {
    "id": 13636,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045,993\", \"forkY\": \"1132,922\", \"forkZ\": \"-908,77\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:41:14Z"
  },
  {
    "id": 13622,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045,992\", \"forkY\": \"1048,772\", \"forkZ\": \"-2050,865\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:41:01Z"
  },
  {
    "id": 13610,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3519,499\", \"forkY\": \"1039,833\", \"forkZ\": \"0,059\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:40:49Z"
  },
  {
    "id": 13594,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"26372,1\", \"forkY\": \"1039,855\", \"forkZ\": \"0,058\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:40:38Z"
  },
  {
    "id": 13577,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1040,039\", \"forkZ\": \"0,058\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:40:25Z"
  },
  {
    "id": 13559,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1040,039\", \"forkZ\": \"0,058\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:40:13Z"
  },
  {
    "id": 13550,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1040,038\", \"forkZ\": \"0,058\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:40:02Z"
  },
  {
    "id": 13535,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1040,039\", \"forkZ\": \"0,058\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:39:49Z"
  },
  {
    "id": 13519,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1040,04\", \"forkZ\": \"-0,645\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:39:37Z"
  },
  {
    "id": 13505,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1083,864\", \"forkZ\": \"-2050,703\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:39:26Z"
  },
  {
    "id": 13484,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1133,066\", \"forkZ\": \"-200,952\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:39:13Z"
  },
  {
    "id": 13475,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1084,87\", \"forkZ\": \"-0,116\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:39:01Z"
  },
  {
    "id": 13460,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1084,962\", \"forkZ\": \"-1933,543\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:38:50Z"
  },
  {
    "id": 13444,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,14\", \"forkZ\": \"-1709,542\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:38:37Z"
  },
  {
    "id": 13430,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:38:25Z"
  },
  {
    "id": 13415,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:38:14Z"
  },
  {
    "id": 13396,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:38:01Z"
  },
  {
    "id": 13381,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:37:49Z"
  },
  {
    "id": 13370,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:37:38Z"
  },
  {
    "id": 13349,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:37:25Z"
  },
  {
    "id": 13334,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:37:13Z"
  },
  {
    "id": 13325,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:37:02Z"
  },
  {
    "id": 13306,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:36:49Z"
  },
  {
    "id": 13295,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:36:37Z"
  },
  {
    "id": 13274,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:36:26Z"
  },
  {
    "id": 13265,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:36:13Z"
  },
  {
    "id": 13250,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:36:01Z"
  },
  {
    "id": 13235,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:35:50Z"
  },
  {
    "id": 13216,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:35:37Z"
  },
  {
    "id": 13201,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:35:25Z"
  },
  {
    "id": 13185,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:35:14Z"
  },
  {
    "id": 13174,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:35:01Z"
  },
  {
    "id": 13157,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:34:49Z"
  },
  {
    "id": 13145,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:34:38Z"
  },
  {
    "id": 13124,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:34:25Z"
  },
  {
    "id": 13109,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:34:13Z"
  },
  {
    "id": 13100,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:34:02Z"
  },
  {
    "id": 13081,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:33:49Z"
  },
  {
    "id": 13069,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:33:37Z"
  },
  {
    "id": 13050,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:33:26Z"
  },
  {
    "id": 13040,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:33:13Z"
  },
  {
    "id": 13021,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:33:01Z"
  },
  {
    "id": 13010,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,135\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:32:50Z"
  },
  {
    "id": 12991,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:32:37Z"
  },
  {
    "id": 12979,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:32:25Z"
  },
  {
    "id": 12961,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,043\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:32:14Z"
  },
  {
    "id": 12947,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,136\", \"forkZ\": \"0,044\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:32:01Z"
  },
  {
    "id": 12929,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,159\", \"forkZ\": \"-1497,948\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:31:49Z"
  },
  {
    "id": 12920,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1085,068\", \"forkZ\": \"-2073,65\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:31:38Z"
  },
  {
    "id": 12901,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1084,98\", \"forkZ\": \"-0,163\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:31:25Z"
  },
  {
    "id": 12885,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,39\", \"forkY\": \"1132,878\", \"forkZ\": \"-262,038\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:31:13Z"
  },
  {
    "id": 12871,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,39\", \"forkY\": \"1131,335\", \"forkZ\": \"-2050,863\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:31:02Z"
  },
  {
    "id": 12856,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,39\", \"forkY\": \"1039,943\", \"forkZ\": \"-37,188\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:49Z"
  },
  {
    "id": 12839,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23716,62\", \"forkY\": \"1039,968\", \"forkZ\": \"0,009\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:37Z"
  },
  {
    "id": 12827,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3063,988\", \"forkY\": \"1039,904\", \"forkZ\": \"0,007\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:26Z"
  },
  {
    "id": 12812,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,255\", \"forkY\": \"1082,277\", \"forkZ\": \"-2050,708\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:13Z"
  },
  {
    "id": 12800,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,254\", \"forkY\": \"1132,904\", \"forkZ\": \"-459,165\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:30:01Z"
  },
  {
    "id": 12782,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"9515,133\", \"forkY\": \"1132,91\", \"forkZ\": \"-0,105\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:29:50Z"
  },
  {
    "id": 12770,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"34061,93\", \"forkY\": \"1132,969\", \"forkZ\": \"-0,106\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:29:37Z"
  },
  {
    "id": 12749,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,97\", \"forkY\": \"1084,877\", \"forkZ\": \"-1121,009\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:29:25Z"
  },
  {
    "id": 12740,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,95\", \"forkY\": \"1007,791\", \"forkZ\": \"-2073,883\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:29:14Z"
  },
  {
    "id": 12721,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37091,7\", \"forkY\": \"974,992\", \"forkZ\": \"0,024\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:29:01Z"
  },
  {
    "id": 12706,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,25\", \"forkY\": \"1040,062\", \"forkZ\": \"-1962,451\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:28:49Z"
  },
  {
    "id": 12695,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,25\", \"forkY\": \"1133,086\", \"forkZ\": \"-1949,132\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:28:38Z"
  },
  {
    "id": 12677,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40729,68\", \"forkY\": \"1133,073\", \"forkZ\": \"0,153\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:28:25Z"
  },
  {
    "id": 12662,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"22279,43\", \"forkY\": \"1132,989\", \"forkZ\": \"0,153\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:28:13Z"
  },
  {
    "id": 12650,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1460,005\", \"forkZ\": \"664,375\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:28:02Z"
  },
  {
    "id": 12631,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1390,618\", \"forkZ\": \"2141,897\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:27:49Z"
  },
  {
    "id": 12616,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1294,966\", \"forkZ\": \"2141,897\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:27:37Z"
  },
  {
    "id": 12602,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1294,966\", \"forkZ\": \"2141,897\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:27:26Z"
  },
  {
    "id": 12582,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1294,975\", \"forkZ\": \"1549,722\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:27:13Z"
  },
  {
    "id": 12570,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,24\", \"forkY\": \"1294,964\", \"forkZ\": \"0,035\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:27:01Z"
  },
  {
    "id": 12556,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"21955,07\", \"forkY\": \"1294,893\", \"forkZ\": \"0,035\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:26:50Z"
  },
  {
    "id": 12545,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,39\", \"forkY\": \"975,111\", \"forkZ\": \"0,034\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:26:37Z"
  },
  {
    "id": 12527,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,39\", \"forkY\": \"975,111\", \"forkZ\": \"0,035\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:26:25Z"
  },
  {
    "id": 12515,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,39\", \"forkY\": \"975,112\", \"forkZ\": \"0,035\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:26:14Z"
  },
  {
    "id": 12499,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,39\", \"forkY\": \"975,133\", \"forkZ\": \"-1179,998\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:26:01Z"
  },
  {
    "id": 12481,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,36\", \"forkY\": \"1085,215\", \"forkZ\": \"-2073,662\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:25:49Z"
  },
  {
    "id": 12466,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,36\", \"forkY\": \"1085,121\", \"forkZ\": \"-83,706\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:25:38Z"
  },
  {
    "id": 12455,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"23734,08\", \"forkY\": \"1085,104\", \"forkZ\": \"-0,135\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:25:25Z"
  },
  {
    "id": 12434,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3449,428\", \"forkY\": \"1085,005\", \"forkZ\": \"-0,136\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:25:13Z"
  },
  {
    "id": 12425,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,021\", \"forkY\": \"1132,923\", \"forkZ\": \"-1449,954\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:25:02Z"
  },
  {
    "id": 12407,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,021\", \"forkY\": \"1039,799\", \"forkZ\": \"-2042,221\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:24:49Z"
  },
  {
    "id": 12391,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"7596,039\", \"forkY\": \"1039,662\", \"forkZ\": \"-0,009\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:24:37Z"
  },
  {
    "id": 12376,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,164\", \"forkZ\": \"1067,952\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:24:26Z"
  },
  {
    "id": 12365,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1295,16\", \"forkZ\": \"2141,61\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:24:13Z"
  },
  {
    "id": 12350,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1345,179\", \"forkZ\": \"2141,608\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:24:01Z"
  },
  {
    "id": 12332,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1459,881\", \"forkZ\": \"2141,609\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:23:50Z"
  },
  {
    "id": 12317,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1459,882\", \"forkZ\": \"2141,609\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:23:37Z"
  },
  {
    "id": 12302,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1459,891\", \"forkZ\": \"1765,759\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:23:25Z"
  },
  {
    "id": 12290,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,16\", \"forkY\": \"1459,944\", \"forkZ\": \"-0,058\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:23:14Z"
  },
  {
    "id": 12275,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"19134,64\", \"forkY\": \"1459,784\", \"forkZ\": \"-0,058\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:23:01Z"
  },
  {
    "id": 12256,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37070,36\", \"forkY\": \"1086,781\", \"forkZ\": \"-0,059\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:22:49Z"
  },
  {
    "id": 12244,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,29\", \"forkY\": \"1084,974\", \"forkZ\": \"-1703,887\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:22:38Z"
  },
  {
    "id": 12224,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,26\", \"forkY\": \"975,167\", \"forkZ\": \"-2063,272\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:22:25Z"
  },
  {
    "id": 12211,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"32893,45\", \"forkY\": \"975,583\", \"forkZ\": \"0,033\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:22:13Z"
  },
  {
    "id": 12194,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"7215,987\", \"forkY\": \"974,977\", \"forkZ\": \"0,032\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:22:02Z"
  },
  {
    "id": 12178,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,281\", \"forkY\": \"1040,287\", \"forkZ\": \"-2050,689\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:21:49Z"
  },
  {
    "id": 12165,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,28\", \"forkY\": \"1132,88\", \"forkZ\": \"-1320,148\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:21:37Z"
  },
  {
    "id": 12151,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3501,318\", \"forkY\": \"1132,805\", \"forkZ\": \"0,155\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:21:26Z"
  },
  {
    "id": 12135,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1460\", \"forkZ\": \"60,241\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:21:13Z"
  },
  {
    "id": 12124,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,992\", \"forkZ\": \"2141,922\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:21:01Z"
  },
  {
    "id": 12110,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1316,33\", \"forkZ\": \"2141,923\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:20:50Z"
  },
  {
    "id": 12091,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1294,974\", \"forkZ\": \"2141,923\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:20:37Z"
  },
  {
    "id": 12077,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1294,974\", \"forkZ\": \"2141,923\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:20:25Z"
  },
  {
    "id": 12062,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1294,973\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:20:14Z"
  },
  {
    "id": 12044,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"17722,78\", \"forkY\": \"1294,913\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:20:01Z"
  },
  {
    "id": 12031,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,119\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:19:49Z"
  },
  {
    "id": 12016,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,118\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:19:38Z"
  },
  {
    "id": 12001,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,119\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:19:25Z"
  },
  {
    "id": 11986,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,118\", \"forkZ\": \"0,039\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:19:13Z"
  },
  {
    "id": 11971,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"975,118\", \"forkZ\": \"0,04\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:19:02Z"
  },
  {
    "id": 11954,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1035,326\", \"forkZ\": \"-2073,646\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:18:49Z"
  },
  {
    "id": 11945,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,77\", \"forkY\": \"1084,979\", \"forkZ\": \"-335,877\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:18:37Z"
  },
  {
    "id": 11927,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40646,67\", \"forkY\": \"1109,205\", \"forkZ\": \"-0,097\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "37",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:18:26Z"
  },
  {
    "id": 11915,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1133,04\", \"forkZ\": \"-2009,397\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:18:13Z"
  },
  {
    "id": 11897,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"40754,41\", \"forkY\": \"1039,947\", \"forkZ\": \"-1415,65\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:18:01Z"
  },
  {
    "id": 11879,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"34764,79\", \"forkY\": \"1039,919\", \"forkZ\": \"0,002\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:17:50Z"
  },
  {
    "id": 11867,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,15\", \"forkY\": \"1295,13\", \"forkZ\": \"328,005\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:17:37Z"
  },
  {
    "id": 11849,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,15\", \"forkY\": \"1295,125\", \"forkZ\": \"2141,669\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:17:25Z"
  },
  {
    "id": 11840,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,15\", \"forkY\": \"1303,039\", \"forkZ\": \"2141,669\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:17:14Z"
  },
  {
    "id": 11825,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,18\", \"forkY\": \"1459,908\", \"forkZ\": \"2141,668\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:17:01Z"
  },
  {
    "id": 11810,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,18\", \"forkY\": \"1459,908\", \"forkZ\": \"2141,668\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:16:49Z"
  },
  {
    "id": 11791,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,18\", \"forkY\": \"1459,908\", \"forkZ\": \"2141,668\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:16:38Z"
  },
  {
    "id": 11777,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,18\", \"forkY\": \"1459,971\", \"forkZ\": \"3,404\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:16:25Z"
  },
  {
    "id": 11759,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16535,98\", \"forkY\": \"1459,913\", \"forkZ\": \"-0,088\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:16:13Z"
  },
  {
    "id": 11744,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"33183,23\", \"forkY\": \"1460,038\", \"forkZ\": \"-0,089\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:16:02Z"
  },
  {
    "id": 11735,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"1084,924\", \"forkZ\": \"-1229,857\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:15:49Z"
  },
  {
    "id": 11717,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"991,901\", \"forkZ\": \"-2073,871\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:15:37Z"
  },
  {
    "id": 11705,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:15:26Z"
  },
  {
    "id": 11688,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:15:13Z"
  },
  {
    "id": 11669,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:15:01Z"
  },
  {
    "id": 11660,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:14:50Z"
  },
  {
    "id": 11639,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:14:37Z"
  },
  {
    "id": 11624,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:14:25Z"
  },
  {
    "id": 11609,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:14:14Z"
  },
  {
    "id": 11594,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:14:01Z"
  },
  {
    "id": 11585,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:13:49Z"
  },
  {
    "id": 11569,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:13:38Z"
  },
  {
    "id": 11551,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:13:25Z"
  },
  {
    "id": 11536,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:13:13Z"
  },
  {
    "id": 11525,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,119\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:13:02Z"
  },
  {
    "id": 11507,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:12:49Z"
  },
  {
    "id": 11490,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:12:37Z"
  },
  {
    "id": 11477,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:12:26Z"
  },
  {
    "id": 11465,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:12:13Z"
  },
  {
    "id": 11444,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:12:01Z"
  },
  {
    "id": 11434,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:11:50Z"
  },
  {
    "id": 11414,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:11:37Z"
  },
  {
    "id": 11405,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:11:25Z"
  },
  {
    "id": 11387,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,117\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:11:14Z"
  },
  {
    "id": 11370,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,119\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:11:01Z"
  },
  {
    "id": 11357,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:10:49Z"
  },
  {
    "id": 11342,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:10:38Z"
  },
  {
    "id": 11330,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:10:25Z"
  },
  {
    "id": 11313,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:10:13Z"
  },
  {
    "id": 11297,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:10:02Z"
  },
  {
    "id": 11281,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:09:49Z"
  },
  {
    "id": 11269,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:09:37Z"
  },
  {
    "id": 11255,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:09:26Z"
  },
  {
    "id": 11240,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:09:13Z"
  },
  {
    "id": 11224,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:09:01Z"
  },
  {
    "id": 11206,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,118\", \"forkZ\": \"0,046\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:08:50Z"
  },
  {
    "id": 11192,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,45\", \"forkY\": \"975,119\", \"forkZ\": \"0,047\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:08:37Z"
  },
  {
    "id": 11176,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,42\", \"forkY\": \"1008,708\", \"forkZ\": \"-2073,655\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:08:25Z"
  },
  {
    "id": 11161,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,42\", \"forkY\": \"1085,037\", \"forkZ\": \"-1118,787\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:08:14Z"
  },
  {
    "id": 11149,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"33068,78\", \"forkY\": \"1084,63\", \"forkZ\": \"0,171\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:08:01Z"
  },
  {
    "id": 11135,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1460,032\", \"forkZ\": \"0,172\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:07:49Z"
  },
  {
    "id": 11120,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,998\", \"forkZ\": \"1924,588\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:07:38Z"
  },
  {
    "id": 11105,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1327,764\", \"forkZ\": \"2141,885\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:07:25Z"
  },
  {
    "id": 11087,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,135\", \"forkZ\": \"2141,885\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:07:13Z"
  },
  {
    "id": 11071,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,135\", \"forkZ\": \"2141,885\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:07:02Z"
  },
  {
    "id": 11057,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:06:49Z"
  },
  {
    "id": 11039,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:06:37Z"
  },
  {
    "id": 11024,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:06:26Z"
  },
  {
    "id": 11011,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:06:13Z"
  },
  {
    "id": 10996,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:06:01Z"
  },
  {
    "id": 10981,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:05:50Z"
  },
  {
    "id": 10967,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:05:37Z"
  },
  {
    "id": 10949,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:05:25Z"
  },
  {
    "id": 10937,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:05:14Z"
  },
  {
    "id": 10921,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:05:01Z"
  },
  {
    "id": 10907,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:04:49Z"
  },
  {
    "id": 10895,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:04:38Z"
  },
  {
    "id": 10879,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:04:25Z"
  },
  {
    "id": 10861,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,139\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:04:13Z"
  },
  {
    "id": 10850,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,137\", \"forkZ\": \"2141,601\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:04:02Z"
  },
  {
    "id": 10840,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,17\", \"forkY\": \"1295,138\", \"forkZ\": \"2141,601\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:03:49Z"
  },
  {
    "id": 10814,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1407,047\", \"forkZ\": \"2141,6\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:03:37Z"
  },
  {
    "id": 10805,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,905\", \"forkZ\": \"2141,6\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:03:26Z"
  },
  {
    "id": 10784,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,905\", \"forkZ\": \"2141,6\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:03:13Z"
  },
  {
    "id": 10772,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,966\", \"forkZ\": \"530,436\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:03:01Z"
  },
  {
    "id": 10754,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,969\", \"forkZ\": \"-0,078\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:02:50Z"
  },
  {
    "id": 10740,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"16497,2\", \"forkY\": \"1459,968\", \"forkZ\": \"-0,078\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:02:37Z"
  },
  {
    "id": 10727,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"29250,67\", \"forkY\": \"1459,948\", \"forkZ\": \"-0,078\", \"targetLocation\": \"MC2.Table\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:02:25Z"
  },
  {
    "id": 10711,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"1084,872\", \"forkZ\": \"-421,36\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:02:14Z"
  },
  {
    "id": 10694,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"1019,915\", \"forkZ\": \"-2073,875\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:02:01Z"
  },
  {
    "id": 10682,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"-0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:01:49Z"
  },
  {
    "id": 10670,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:01:38Z"
  },
  {
    "id": 10651,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:01:25Z"
  },
  {
    "id": 10640,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:01:13Z"
  },
  {
    "id": 10622,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:01:02Z"
  },
  {
    "id": 10606,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:00:49Z"
  },
  {
    "id": 10592,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:00:37Z"
  },
  {
    "id": 10574,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:00:26Z"
  },
  {
    "id": 10559,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:00:13Z"
  },
  {
    "id": 10544,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T07:00:01Z"
  },
  {
    "id": 10529,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:59:50Z"
  },
  {
    "id": 10514,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:59:37Z"
  },
  {
    "id": 10505,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:59:25Z"
  },
  {
    "id": 10486,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:59:14Z"
  },
  {
    "id": 10472,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:59:01Z"
  },
  {
    "id": 10460,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:58:49Z"
  },
  {
    "id": 10439,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:58:38Z"
  },
  {
    "id": 10428,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:58:25Z"
  },
  {
    "id": 10414,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,146\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:58:13Z"
  },
  {
    "id": 10400,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:58:02Z"
  },
  {
    "id": 10383,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:57:49Z"
  },
  {
    "id": 10366,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:57:37Z"
  },
  {
    "id": 10355,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:57:26Z"
  },
  {
    "id": 10337,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:57:13Z"
  },
  {
    "id": 10321,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:57:01Z"
  },
  {
    "id": 10304,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:56:50Z"
  },
  {
    "id": 10291,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:56:37Z"
  },
  {
    "id": 10276,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:56:25Z"
  },
  {
    "id": 10259,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,147\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:56:14Z"
  },
  {
    "id": 10244,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,148\", \"forkZ\": \"0,04\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:56:01Z"
  },
  {
    "id": 10235,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,48\", \"forkY\": \"975,168\", \"forkZ\": \"-1140,019\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:55:49Z"
  },
  {
    "id": 10214,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,47\", \"forkY\": \"1085,226\", \"forkZ\": \"-2073,685\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:55:38Z"
  },
  {
    "id": 10205,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37085,47\", \"forkY\": \"1085,125\", \"forkZ\": \"-0,289\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:55:25Z"
  },
  {
    "id": 10190,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24080,22\", \"forkY\": \"1085,156\", \"forkZ\": \"-0,154\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:55:13Z"
  },
  {
    "id": 10171,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4357,773\", \"forkY\": \"1085,061\", \"forkZ\": \"-0,155\", \"targetLocation\": \"L2.User\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:55:02Z"
  },
  {
    "id": 10156,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,024\", \"forkY\": \"1132,971\", \"forkZ\": \"-1763,36\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:54:49Z"
  },
  {
    "id": 10145,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,023\", \"forkY\": \"1039,728\", \"forkZ\": \"-1974,996\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:54:37Z"
  },
  {
    "id": 10124,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"5508,979\", \"forkY\": \"1039,588\", \"forkZ\": \"0,05\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:54:26Z"
  },
  {
    "id": 10112,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"35390,57\", \"forkY\": \"1039,78\", \"forkZ\": \"0,05\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:54:13Z"
  },
  {
    "id": 10099,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"39047,52\", \"forkY\": \"1025,17\", \"forkZ\": \"-1968,771\", \"targetLocation\": \"M2.User\"}",
    "move_description": null,
    "pallet_number": "1001",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:54:01Z"
  },
  {
    "id": 10082,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"39047,51\", \"forkY\": \"1129,863\", \"forkZ\": \"-1024,545\", \"targetLocation\": \"M2.User\"}",
    "move_description": null,
    "pallet_number": "1001",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:53:50Z"
  },
  {
    "id": 10069,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"38018,29\", \"forkY\": \"2006,361\", \"forkZ\": \"-0,1\", \"targetLocation\": \"M2.User\"}",
    "move_description": null,
    "pallet_number": "1001",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:53:37Z"
  },
  {
    "id": 10049,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"26635,74\", \"forkY\": \"3517,938\", \"forkZ\": \"-10,86\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:53:25Z"
  },
  {
    "id": 10038,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"26635,74\", \"forkY\": \"3516,409\", \"forkZ\": \"-1679,952\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:53:14Z"
  },
  {
    "id": 10025,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"26635,74\", \"forkY\": \"3351,043\", \"forkZ\": \"0,071\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:53:01Z"
  },
  {
    "id": 10004,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,52\", \"forkY\": \"984,144\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:52:49Z"
  },
  {
    "id": 9992,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,144\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:52:38Z"
  },
  {
    "id": 9977,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,144\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:52:25Z"
  },
  {
    "id": 9964,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,144\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:52:13Z"
  },
  {
    "id": 9942,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,144\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:52:02Z"
  },
  {
    "id": 9929,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:43:30Z"
  },
  {
    "id": 9916,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:43:19Z"
  },
  {
    "id": 9899,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:43:06Z"
  },
  {
    "id": 9883,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:42:54Z"
  },
  {
    "id": 9870,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:42:43Z"
  },
  {
    "id": 9855,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "SafetyStop",
    "location": "{\"forkX\": \"31321,71\", \"forkY\": \"984,145\", \"forkZ\": \"0,07\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T06:42:05Z"
  },
  {
    "id": 9845,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,039\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:25:09Z"
  },
  {
    "id": 9824,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:24:58Z"
  },
  {
    "id": 9815,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:24:45Z"
  },
  {
    "id": 9796,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:24:33Z"
  },
  {
    "id": 9782,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:24:22Z"
  },
  {
    "id": 9770,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:24:09Z"
  },
  {
    "id": 9755,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:23:57Z"
  },
  {
    "id": 9734,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,039\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:23:46Z"
  },
  {
    "id": 9721,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:23:33Z"
  },
  {
    "id": 9710,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:23:21Z"
  },
  {
    "id": 9695,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:23:10Z"
  },
  {
    "id": 9679,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:22:57Z"
  },
  {
    "id": 9659,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:22:45Z"
  },
  {
    "id": 9647,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,039\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:22:34Z"
  },
  {
    "id": 9631,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:22:21Z"
  },
  {
    "id": 9617,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,04\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:22:09Z"
  },
  {
    "id": 9599,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,041\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:21:58Z"
  },
  {
    "id": 9590,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,041\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:21:45Z"
  },
  {
    "id": 9574,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,041\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:21:33Z"
  },
  {
    "id": 9556,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4105,239\", \"forkY\": \"1040,041\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:21:22Z"
  },
  {
    "id": 9541,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"4018,946\", \"forkY\": \"1040,053\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:21:09Z"
  },
  {
    "id": 9526,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,219\", \"forkY\": \"1040,05\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:20:57Z"
  },
  {
    "id": 9514,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,219\", \"forkY\": \"1040,051\", \"forkZ\": \"0,005\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:20:46Z"
  },
  {
    "id": 9494,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,219\", \"forkY\": \"1040,051\", \"forkZ\": \"0,006\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:20:33Z"
  },
  {
    "id": 9479,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,219\", \"forkY\": \"1084,37\", \"forkZ\": \"-2050,728\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:20:21Z"
  },
  {
    "id": 9464,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3046,218\", \"forkY\": \"1132,885\", \"forkZ\": \"-638,401\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:20:10Z"
  },
  {
    "id": 9451,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"12040,34\", \"forkY\": \"1132,895\", \"forkZ\": \"-0,084\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:19:57Z"
  },
  {
    "id": 9436,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"34450,92\", \"forkY\": \"1132,943\", \"forkZ\": \"-0,085\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "1",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:19:45Z"
  },
  {
    "id": 9421,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,91\", \"forkY\": \"1084,913\", \"forkZ\": \"-940,618\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:19:34Z"
  },
  {
    "id": 9409,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37086,91\", \"forkY\": \"994,85\", \"forkZ\": \"-2073,902\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:19:21Z"
  },
  {
    "id": 9394,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37218,86\", \"forkY\": \"974,956\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:19:09Z"
  },
  {
    "id": 9375,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,475\", \"forkZ\": \"-0,013\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:18:58Z"
  },
  {
    "id": 9359,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,475\", \"forkZ\": \"-0,013\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:18:45Z"
  },
  {
    "id": 9347,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,475\", \"forkZ\": \"-0,013\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:18:33Z"
  },
  {
    "id": 9335,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,474\", \"forkZ\": \"-0,013\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:18:22Z"
  },
  {
    "id": 9319,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,474\", \"forkZ\": \"-0,014\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:18:09Z"
  },
  {
    "id": 9299,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,461\", \"forkZ\": \"1753,206\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:17:57Z"
  },
  {
    "id": 9290,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1297,461\", \"forkZ\": \"2134,91\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:17:46Z"
  },
  {
    "id": 9272,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,95\", \"forkY\": \"1349,544\", \"forkZ\": \"2134,909\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:17:33Z"
  },
  {
    "id": 9254,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,95\", \"forkY\": \"1462,324\", \"forkZ\": \"2134,909\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:17:21Z"
  },
  {
    "id": 9240,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,95\", \"forkY\": \"1462,324\", \"forkZ\": \"2134,909\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:17:10Z"
  },
  {
    "id": 9224,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1462,365\", \"forkZ\": \"1289,411\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:16:57Z"
  },
  {
    "id": 9211,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1462,439\", \"forkZ\": \"-0,151\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:16:45Z"
  },
  {
    "id": 9196,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,94\", \"forkY\": \"1462,439\", \"forkZ\": \"-0,151\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:16:34Z"
  },
  {
    "id": 9181,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"33239,75\", \"forkY\": \"1462,727\", \"forkZ\": \"-0,151\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:16:21Z"
  },
  {
    "id": 9167,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"14454,31\", \"forkY\": \"1142,961\", \"forkZ\": \"-0,153\", \"targetLocation\": \"MC6.Table\"}",
    "move_description": null,
    "pallet_number": "17",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:16:09Z"
  },
  {
    "id": 9150,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"14404,44\", \"forkY\": \"1132,881\", \"forkZ\": \"-1678,74\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:15:58Z"
  },
  {
    "id": 9136,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"14404,41\", \"forkY\": \"1039,994\", \"forkZ\": \"-1527,567\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:15:45Z"
  },
  {
    "id": 9122,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"10306,6\", \"forkY\": \"1039,939\", \"forkZ\": \"0,063\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:15:33Z"
  },
  {
    "id": 9107,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"10145,7\", \"forkY\": \"1053,903\", \"forkZ\": \"-2050,593\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "11",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:15:22Z"
  },
  {
    "id": 9095,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"10145,7\", \"forkY\": \"1132,851\", \"forkZ\": \"-813,986\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "11",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:15:09Z"
  },
  {
    "id": 9074,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"14778,22\", \"forkY\": \"1132,76\", \"forkZ\": \"0,234\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "11",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:14:57Z"
  },
  {
    "id": 9064,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"35585,71\", \"forkY\": \"1132,892\", \"forkZ\": \"0,234\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "11",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:14:46Z"
  },
  {
    "id": 9044,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,73\", \"forkY\": \"1462,329\", \"forkZ\": \"497,186\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:14:33Z"
  },
  {
    "id": 9029,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,75\", \"forkY\": \"1412,306\", \"forkZ\": \"2135,223\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:14:21Z"
  },
  {
    "id": 9019,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,384\", \"forkZ\": \"2135,223\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:14:10Z"
  },
  {
    "id": 9002,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,384\", \"forkZ\": \"2135,223\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:13:57Z"
  },
  {
    "id": 8986,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,408\", \"forkZ\": \"1279,713\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:13:45Z"
  },
  {
    "id": 8972,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,413\", \"forkZ\": \"0,018\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:13:34Z"
  },
  {
    "id": 8954,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,413\", \"forkZ\": \"0,018\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:13:21Z"
  },
  {
    "id": 8939,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,413\", \"forkZ\": \"0,018\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:13:09Z"
  },
  {
    "id": 8930,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"45723,7\", \"forkY\": \"1297,414\", \"forkZ\": \"0,018\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:12:58Z"
  },
  {
    "id": 8909,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"37456,72\", \"forkY\": \"1297,542\", \"forkZ\": \"0,018\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:12:45Z"
  },
  {
    "id": 8900,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"11774,06\", \"forkY\": \"1097,778\", \"forkZ\": \"0,016\", \"targetLocation\": \"\"}",
    "move_description": null,
    "pallet_number": "",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:12:33Z"
  },
  {
    "id": 8882,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"11565,68\", \"forkY\": \"1053,311\", \"forkZ\": \"-2050,661\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "13",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:12:22Z"
  },
  {
    "id": 8867,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"11565,68\", \"forkY\": \"1132,876\", \"forkZ\": \"-824,282\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "13",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:12:09Z"
  },
  {
    "id": 8855,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"15258,67\", \"forkY\": \"1132,806\", \"forkZ\": \"-0,129\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "13",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:11:57Z"
  },
  {
    "id": 8834,
    "device": "Crane",
    "device_no": null,
    "device_type": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31321,61\", \"forkY\": \"1088,95\", \"forkZ\": \"-0,13\", \"targetLocation\": \"Crane.Storage\"}",
    "move_description": null,
    "pallet_number": "13",
    "processingProcedureNum": null,
    "processingProcedure": null,
    "product_parameter": null,
    "duration_seconds": null,
    "duration": null,
    "fault_no": null,
    "fault_name": null,
    "error_type": null,
    "error_create_date": null,
    "error_message": "[\"11.45.27 <DeviceAlarm\/Warning> PLC-21107: Speed override is set to: 80\"]",
    "error_level": null,
    "create_date": "2021-10-16T04:11:46Z"
  }
]

// 模拟数据
export const mData = {
  "1": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15222,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15223,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15224,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,179\", \"forkZ\": \"0,001\", \"targetLocation\": \"\"}",
    "moveDescription": null,
    "palletNumber": "37",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15225,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15226,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15227,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15228,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15229,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15230,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15231,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15232,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15233,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15234,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15235,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15236,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:25"
  }],
  "2": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15237,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15238,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
    "moveDescription": null,
    "palletNumber": "37",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15239,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15240,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15241,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15242,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15243,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15244,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15245,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15246,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15247,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15248,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15249,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15250,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15251,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:37"
  }],
  "3": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15252,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15253,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15254,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15255,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"L2.User\"}",
    "moveDescription": null,
    "palletNumber": "37",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15256,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15257,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15258,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15259,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15260,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15261,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15262,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15263,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15264,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15265,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15266,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:02:50"
  }],
  "4": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15269,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24265\", \"forkY\": \"3188\", \"forkZ\": \"-1680\", \"targetLocation\": \"Crane.Storage\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15282,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15283,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15285,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15286,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15287,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15288,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15289,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15290,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15291,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15292,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15293,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15294,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15295,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15296,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:13"
  }],
  "5": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15284,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24265\", \"forkY\": \"3188\", \"forkZ\": \"-1680\", \"targetLocation\": \"M2.User\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15297,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15298,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15299,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15300,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15301,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15302,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15303,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"ture\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15304,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15306,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15307,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15308,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15309,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15310,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15311,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:26"
  }],
  "6": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15305,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"31322,86\", \"forkY\": \"984,178\", \"forkZ\": \"0,001\", \"targetLocation\": \"L2.User\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15312,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15313,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15315,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15316,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15317,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15318,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15319,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15320,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"ture\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15321,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15322,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15323,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15324,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15325,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15326,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:37"
  }],
  "7": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15314,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"MC2.Table\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15327,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15328,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15329,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15330,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15332,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15333,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15334,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15335,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15336,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15337,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"ture\", \"programRunning\": \"ture\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15338,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15339,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15340,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15341,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:03:49"
  }],
  "8": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15331,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"MC2.Table\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15342,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15343,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15345,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15346,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15347,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15348,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15349,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15350,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15351,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15352,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15353,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15354,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15355,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15356,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:02"
  }],
  "9": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15344,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"Crane.Storage\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15357,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15358,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15359,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15360,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15362,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15363,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15364,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15365,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15366,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15367,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15368,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15369,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15370,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15371,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:13"
  }],
  "10": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15361,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15372,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15373,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15374,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15376,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15377,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15378,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15379,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15380,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15381,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15382,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15383,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15384,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15385,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15386,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:25"
  }],
  "11": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15387,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15388,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15389,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"3045\", \"forkY\": \"795\", \"forkZ\": \"-2051\", \"targetLocation\": \"M2.User\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15390,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15391,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15392,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15393,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15394,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15395,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15396,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15397,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"ture\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15398,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15399,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15400,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15401,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:38"
  }],
  "12": [{
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15375,
    "device": "Crane",
    "deviceNo": null,
    "deviceType": "cranestatus",
    "status": "Automatic",
    "location": "{\"forkX\": \"24265\", \"forkY\": \"3188\", \"forkZ\": \"-1680\", \"targetLocation\": \"Crane.Storage\"}",
    "moveDescription": null,
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15402,
    "device": "coolantsystemstatus",
    "deviceNo": null,
    "deviceType": "coolantsystemstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CoolantUnitEnabled\\\": false}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15403,
    "device": "ccrsstatus",
    "deviceNo": null,
    "deviceType": "ccrsstatus",
    "status": null,
    "location": null,
    "moveDescription": "{\"movement\": \"{  \\\"CCRSUnit1Enabled\\\": true,  \\\"CCRSUnit2Enabled\\\": true,  \\\"CCRSUnit3Enabled\\\": true,  \\\"CCRSUnit4Enabled\\\": true,  \\\"CCRSUnit5Enabled\\\": true,  \\\"CCRSUnit6Enabled\\\": true,  \\\"CCRSUnit7Enabled\\\": true,  \\\"CCRSUnit8Enabled\\\": true,  \\\"CCRSUnit9Enabled\\\": true,  \\\"CCRSUnit10Enabled\\\": true,  \\\"CCRSUnit11Enabled\\\": true}\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15404,
    "device": "L1",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15405,
    "device": "L2",
    "deviceNo": null,
    "deviceType": "loadingstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15407,
    "device": "RFC",
    "deviceNo": null,
    "deviceType": "rdcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15408,
    "device": "M1",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15409,
    "device": "M2",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15410,
    "device": "M3",
    "deviceNo": null,
    "deviceType": "materialstationstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\"}",
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15411,
    "device": "MC1",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15412,
    "device": "MC2",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15413,
    "device": "MC3",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Automatic",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "29",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15414,
    "device": "MC4",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Unknown",
    "location": null,
    "moveDescription": null,
    "palletNumber": null,
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": null,
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15415,
    "device": "MC5",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "6",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"10.37.00 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }, {
    "searchValue": null,
    "createBy": null,
    "createTime": null,
    "updateBy": null,
    "updateTime": null,
    "remark": null,
    "params": {},
    "id": 15416,
    "device": "MC6",
    "deviceNo": null,
    "deviceType": "mcstatus",
    "status": "Manual",
    "location": null,
    "moveDescription": "{\"doorOpen\": \"false\", \"programRunning\": \"false\"}",
    "palletNumber": "17",
    "processingprocedurenum": null,
    "processingprocedure": null,
    "productParameter": null,
    "durationSeconds": null,
    "duration": null,
    "faultNo": null,
    "faultName": null,
    "errorType": null,
    "errorCreateDate": null,
    "errorMessage": "[\"12.20.30 <DeviceAlarm/Warning> PLC-60000: Machine has an alarm\"]",
    "errorLevel": null,
    "createDate": "2021-10-16 16:04:49"
  }]
}