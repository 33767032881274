import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const device = {
  // 获取所有设备状态和生产进度信息
  ApiGetDeviceProduct(params) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getDeviceProduct`,
      params
    })
  },

  /*****************  获取设备详细信息  ************/
  // 获取其它设备详细信息
  ApiGetMachineMoveById(deviceid) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMachineMoveById/${deviceid}`
    })
  },
  // 获取加工中心设备与去毛刺单元详细信息
  ApiGetMDCInfo(deviceid) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMDCInfo/${deviceid}`
    })
  },

  // 获取设备历史故障信息
  ApiGetMachineErrorHistory(params) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMachineErrorHistory`,
      params
    })
  },

  /*****************  获取设备故障信息  ************/
  // 获取mms与其它设备
  ApiGetMachineErrorById(deviceid) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMachineErrorById/${deviceid}`
    })
  },
  // 获取mdc加工中心与去毛刺单元
  ApiGetMDCErrorById(deviceid) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMDCErrorById/${deviceid}`
    })
  },

  // 获取设备动画仿真视频
  ApiGetMachineAnimation(params) {
    return axios({
      method: 'post',
      url: `${base.host}/animated/video/getMachineAnimation`,
      params
    })
  },

  // 获取所有设备的实时数据
  ApiGetMachineAll(params) {
    return axios({
      method: 'get',
      url: `${base.host}/device/getMachineAll?` + qs.stringify(params)
    })
  },
}
export default device