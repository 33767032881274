<template>
  <div
    class="home-conatiner"
    v-loading="loading"
    element-loading-text="模型加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 页头 -->
    <Header ref="header"></Header>

    <!-- 模型容器 -->
    <div class="amodel">
      <Amodel ref="amodel" :percentage="percentage" :deviceAll="deviceAll"></Amodel>
    </div>

    <!-- 统计分析 -->
    <transition name="fade">
      <PanelTjfx v-if="isShowtjfx" class="panel-center"></PanelTjfx>
    </transition>

    <!-- 左侧图表面板 -->
    <!-- <transition name="leftSide">
      <PanelChartLeft v-if="isShowtjfx" :chartIndex="chartIndex" class="panel-left"></PanelChartLeft>
    </transition>-->

    <!-- 右侧图表面板 -->
    <!-- <transition name="rightSide">
      <PanelChartRight v-if="isShowtjfx" :chartIndex="chartIndex" class="panel-right"></PanelChartRight>
    </transition>-->

    <!-- 设备列表面板 -->
    <transition name="leftSide">
      <PanelSblb v-if="isShowPanelSblb" class="panel-left"></PanelSblb>
    </transition>

    <!-- 设备物料模块：设备信息、订单信息面板 -->
    <transition name="rightSide">
      <PanelSbxx v-if="isShowPanelSbxx" class="panel-right" ref="panelSbxx"></PanelSbxx>
    </transition>

    <!-- 订单信息模块：订单信息面板 -->
    <transition name="fade">
      <PanelLsgjxx v-if="isShowPanelLsgjxx" class="panel-center"></PanelLsgjxx>
    </transition>

    <!-- 数据备份 -->
    <transition name="fade">
      <PanelBf v-if="isShowPanelBf" class="panel-center" :step="step"></PanelBf>
    </transition>

    <!-- 提示备份数据 -->
    <transition name="bottomSide">
      <div class="bf-tip" v-if="isShowBfTip">
        <img src="../assets/img/tip-icon.png" alt />
        <span>请先备份数据，后进行清除数据操作</span>
      </div>
    </transition>

    <!-- 历史告警信息模块：告警详情面板 -->
    <transition name="rightSide">
      <PanelGjxq v-if="isShowPanelGjxq" :gjxqInfo="gjxqInfo" class="panel-right" ref="panelGjxq"></PanelGjxq>
    </transition>

    <!-- 托盘信息面板 -->
    <transition name="rightSide">
      <PanelTpxx v-if="isShowPanelTpxx"  class="panel-right" ref="panelTpxx" :tpInfo="tpInfo"></PanelTpxx>
    </transition>

    <!-- 加工视频弹窗 -->
    <transition name="fade">
      <PanelVideo v-if="isShowPanelVideo" class="panel-center" :videoObj="videoObj"></PanelVideo>
    </transition>
  </div>
</template>

<script>
import { modelConfig } from "@/utils/config.js";
import { deviceProduct, palletByType, mData } from "@/utils/data.js";
import Header from "@/components/Header";
import PanelChartLeft from "./PanelChartLeft";
import PanelChartRight from "./PanelChartRight";
import PanelSbxx from "./PanelSbxx";
import PanelLsgjxx from "./PanelLsgjxx";
import PanelGjxq from "./PanelGjxq";
import PanelSblb from "./PanelSblb";
import Amodel from "./Amodel";
import PanelBf from "./PanelBf";
import PanelVideo from "./PanelVideo";
import PanelTpxx from "./PanelTpxx";
import PanelTjfx from "./PanelTjfx";
import Progress from "@/components/Progress.vue";
export default {
  name: "Home",
  components: {
    Header,
    PanelChartLeft,
    PanelChartRight,
    PanelSbxx,
    PanelLsgjxx,
    PanelGjxq,
    PanelSblb,
    Amodel,
    PanelBf,
    Progress,
    PanelVideo,
    PanelTjfx,
    PanelTpxx
  },
  data() {
    return {
      isShowtjfx: false,
      isShowPanelSbxx: false,
      isShowPanelTpxx: false,
      isShowPanelLsgjxx: false,
      isShowPanelGjxq: false,
      isShowPanelSblb: false,
      isShowPanelBf: false,
      isShowBfTip: false,
      isShowPanelVideo: false,

      loading: false,

      step: 1,
      chartIndex: 1,
      tabIndex: 0,
      videoObj: {},
      deviceList: [],

      percentage: "0",

      deviceAll: [],
      gjxqInfo: {},
      tpInfo: {}
    };
  },
  mounted() {
    // this.isShowPanelVideo = true;

    // 监听关闭弹窗事件
    this.$bus.$on("on-close", (tab = "all") => {
      this.$refs.header.tab = "";
      this.close(tab);
    });

    // this.getPalletData();
    // this.getDeviceProduct();

    this.getMachineAll();
  },
  methods: {
    // 显示弹窗
    open(type, data) {
      this[type] = true;
      if (data) {
        // this.step = data.step; //数据备份相关
        this.videoObj.title = data.device; //加工视频相关
      }
    },
    // 关闭弹窗
    close(type, val) {
      if (type === "all") {
        for (let key in this.$data) {
          if (typeof this.$data[key] === "boolean" && val !== key) {
            this.$data[key] = false;
          } else {
            this.$data[key] = true;
          }
        }
      } else {
        this[type] = false;
      }
    },
    // 显示/隐藏一级菜单对应弹窗
    switchMenu(type) {
      const hash = {
        sbwl: "isShowPanelSblb",
        tjfx: "isShowtjfx",
        lsgjsj: "isShowPanelLsgjxx"
      };
      if (!this[hash[type]]) {
        this.close("all", hash[type]);
      } else {
        this.close("all");
      }
    },

    /******sbxx弹窗相关接口*******/
    // 获取物料信息
    getInfoByMaterialId(materialId, device, fn) {
      const params = { materialPartId: materialId, deviceName: device };
      this.$api.other.ApiGetInfoByMaterialId(params).then(res => {
        if (res.code === this.$code && res.data) {
          this.isShowPanelSbxx = true;
          const obj = res.data;
          this.$nextTick(() => {
            this.$refs.panelSbxx.listData1.forEach(item => {
              item.value = obj[item.prop] || "--";
            });
          });
          this.getProductById(materialId);

          fn && fn(device);
        }
      });
    },
    // 根据物料id获取生产进度接口
    getProductById(materialId) {
      this.$api.other.ApiGetProductById(materialId).then(res => {
        if (res.code === this.$code && res.data) {
          let {
            processingStep,
            percentage,
            processingprocedureStep
          } = res.data;
          this.percentage = percentage ? percentage * 100 : 0;
          const list = this.$refs.panelSbxx.proList.slice(
            0,
            processingStep + 1
          );
          list[0].value = this.percentage;
          processingprocedureStep = processingprocedureStep * 100;
          const num = Math.floor(processingprocedureStep / 100);
          for (let i = 1; i <= num; i++) {
            list[i].value = 100;
          }
          for (let i = num + 1; i < list; i++) {
            list[i].value = 0;
          }
          this.$refs.panelSbxx.proList = list;
        }
      });
    },
    // 获取其它设备详细信息
    getMachineMoveById(obj) {
      console.log("其它设备：", obj);
      this.isShowPanelSbxx = true;
      this.$nextTick(() => {
        const listData2 = [
          { label: "设备名称", value: "", prop: "deviceName" }
          // { label: "设备编号", value: "", prop: "deviceNo" },
          // { label: "设备类型", value: "", prop: "deviceType" },
          // { label: "状态", value: "", prop: "status" },
          // { label: "设备位置", value: "", prop: "location" },
          // { label: "进给速度", value: "", prop: "feedRate" },
          // { label: "主轴转速", value: "", prop: "spindleSpeed" },
          // { label: "程序名称", value: "", prop: "processingProcedureName" },
          // { label: "轴电流", value: "", prop: "shaftCurrent" }
        ];
        listData2.forEach(item => {
          item.value = obj[item.prop];
        });
        this.$refs.panelSbxx.listData2 = listData2;
        const listData1 = [
          // { label: "零件号", value: "", prop: "partId" },
          // { label: "零件说明", value: "", prop: "partDescription" },
          // { label: "设备编号", value: "", prop: "deviceId" },
          { label: "设备名称", value: "", prop: "deviceName" },
          // { label: "物料编号", value: "", prop: "materialPartId" },
          // { label: "物料类型", value: "", prop: "materialType" },
          { label: "图号", value: "", prop: "partDrawingNumber" },
          { label: "批次", value: "", prop: "batchNumber" },
          // { label: "物料说明", value: "", prop: "materialPartDescription" },
          { label: "订单号", value: "", prop: "productionOrder" },
          { label: "物料状态", value: "", prop: "type" }
        ];
        listData1.forEach(item => {
          item.value = obj[item.prop];
        });
        this.$refs.panelSbxx.listData1 = listData1;
        this.$refs.panelSbxx.proList = [];
      });

      // this.$api.device.ApiGetMachineMoveById(deviceid).then(res => {
      //   if (res.code === this.$code && res.data.length) {
      //     this.isShowPanelSbxx = true;
      //     const obj = res.data[0];
      //     this.$nextTick(() => {
      //       this.$refs.panelSbxx.tabIndex = this.tabIndex;
      //       this.$refs.panelSbxx.$refs.tab.tabIndex = this.tabIndex;
      //       this.$refs.panelSbxx.listData2.forEach(item => {
      //         item.value = obj[item.prop] || "--";
      //       });
      //     });
      //   }
      // });
    },
    // 获取加工中心设备与去毛刺单元详细信息
    getMDCInfo(info) {
      this.isShowPanelSbxx = true;
      this.$nextTick(() => {
        this.$refs.panelSbxx.tabIndex = this.tabIndex;
        this.$refs.panelSbxx.$refs.tab.tabIndex = this.tabIndex;
        this.percentage = this.$refs.panelSbxx.proList[0].value =
          info.param || "0";
        this.$refs.panelSbxx.listData1.forEach(item => {
          item.value = info[item.prop];
        });
        this.$refs.panelSbxx.listData2.forEach(item => {
          item.value = info[item.prop];
        });
      });
      // this.$api.device.ApiGetMDCInfo(deviceid).then((res) => {
      //   if (res.code === this.$code && res.data.length) {
      //     this.isShowPanelSbxx = true;
      //     const obj = res.data[0];
      //     if (obj.productParameter) {
      //       const {
      //         feed_rate: feedRate,
      //         shaft_current: shaftCurrent,
      //         spindle_speed: spindleSpeed,
      //       } = JSON.parse(obj.productParameter);
      //       obj.feedRate = feedRate;
      //       obj.shaftCurrent = shaftCurrent;
      //       obj.spindleSpeed = spindleSpeed;
      //     }

      //     this.$nextTick(() => {
      //       this.$refs.panelSbxx.tabIndex = this.tabIndex;
      //       this.$refs.panelSbxx.$refs.tab.tabIndex = this.tabIndex;
      //       this.$refs.panelSbxx.listData2.forEach((item) => {
      //         item.value = obj[item.prop] || "--";
      //       });
      //     });
      //   }
      // });
    },
    // 点击托盘时
    getDataByTp(obj) {
      console.log("托盘信息：", obj);
      this.isShowPanelTpxx = true;
      this.$nextTick(() => {
        this.tpInfo = obj;
      });
    },

    // 点击物料盒时
    getDataByMaterial(obj) {
      console.log("物料盒：", obj);
      this.isShowPanelGjxq = false;
      this.tabIndex = 0;
      this.isShowPanelSbxx = true;

      this.$nextTick(() => {
        const listData2 = [
          { label: "托盘编号", value: "", prop: "palletId" },
          { label: "托盘名称", value: "", prop: "palletName" },
          { label: "托盘类型", value: "", prop: "type" }
          // { label: "零件图号", value: "", prop: "partDrawingNumber", isChild: true },
          // { label: "订单号", value: "", prop: "productionOrder", isChild: true },
          // { label: "零件说明", value: "", prop: "partDescription" },
          // { label: "物料编号", value: "", prop: "materialPartId" },
          // { label: "物料说明", value: "", prop: "materialPartDescription" }
        ];
        listData2.forEach(item => {
          item.value = obj[item.prop];
        });
        this.$refs.panelSbxx.listData2 = listData2;
        const listData1 = [
          { label: "物料编号", value: "", prop: "MaterialIds" }
          // { label: "托盘名称", value: "", prop: "palletName" },
          // { label: "托盘类型", value: "", prop: "type" },
          // { label: "零件图号", value: "", prop: "partDrawingNumber" },
          // { label: "批次", value: "", prop: "batchNumber" },
          // { label: "订单号", value: "", prop: "productionOrder" }
          // { label: "零件说明", value: "", prop: "partDescription" },
          // { label: "物料编号", value: "", prop: "materialPartId" },
          // { label: "物料说明", value: "", prop: "materialPartDescription" }
        ];
        listData1.forEach(item => {
          const { partDatas } = obj;
          obj.MaterialIds = JSON.parse(partDatas)
            .map(m => m.MaterialId)
            .join("；");
          item.value = obj[item.prop];
        });
        this.$refs.panelSbxx.listData1 = listData1;
        this.$refs.panelSbxx.proList = [];
        // if (materialId) {
        //   this.getInfoByMaterialId(materialId, device, this.getMachineMoveById);
        // } else {
        // this.$message.error("没有物料id");
        // this.$refs.panelSbxx.listData1 = [];
        // this.$refs.panelSbxx.proList = [];
        // }
      });
    },
    // 点击设备时
    getDataByDevice(obj, type) {
      console.log("点击设备");
      console.log(obj, "obj");
      this.isShowPanelGjxq = false;
      this.tabIndex = 0;
      if (type === 1) {
        this.getMDCInfo(obj);
      } else {
        this.getMachineMoveById(obj);
      }

      // if (obj.materialId) {
      //   const matId = JSON.parse(obj.materialId);
      //   if (typeof matId === "object") {
      //     this.getInfoByMaterialId(matId[0], obj.device);
      //   } else {
      //     this.getInfoByMaterialId(matId, obj.device);
      //   }
      // } else {
      //   // this.$message.error("没有物料id");
      //   // this.$refs.panelSbxx.listData1 = [];
      //   // this.$refs.panelSbxx.proList = [];
      // }
    },

    /******gjxq弹窗相关接口*******/
    // 获取mms与其它设备
    getMachineErrorById(obj) {
      this.isShowPanelGjxq = true;
      this.gjxqInfo = {
        deviceName: obj.userData.deviceName,
        errorMsg: obj.userData.errorMsg
      };
      console.log(obj, "objobj");
      // this.$api.device.ApiGetMachineErrorById(obj.name).then(res => {
      //   if (res.code === this.$code && res.data.length) {
      //     this.isShowPanelGjxq = true;
      //     const obj = res.data[0];
      //     this.$nextTick(() => {
      //       this.$refs.panelGjxq.listData.forEach(item => {
      //         if (item.prop === "errorMessage") {
      //           const list = JSON.parse(obj[item.prop]);
      //           let str = "";
      //           if (list) {
      //             list.forEach(s => (str += s + "\n"));
      //             item.value = str;
      //           } else {
      //             item.value = "--";
      //           }
      //         } else {
      //           item.value = obj[item.prop] || "--";
      //         }
      //       });
      //     });
      //   }
      // });
    },
    // 获取mdc加工中心与去毛刺单元
    getMDCErrorById(obj) {
      this.$api.device.ApiGetMDCErrorById(obj.name).then(res => {
        if (res.code === this.$code && res.data.length) {
          this.isShowPanelGjxq = true;
          const obj = res.data[0];
          this.$nextTick(() => {
            this.$refs.panelGjxq.listData.forEach(item => {
              item.value = obj[item.prop] || "--";
            });
          });
        } else {
          this.isShowPanelGjxq = false;
          this.$message.warning("暂无信息");
        }
      });
    },

    // 获生产取所有设备状态和进度信息
    getDeviceProduct() {
      this.deviceList = [];
      return new Promise(resolve => {
        this.$api.device.ApiGetDeviceProduct().then(res => {
          if (res.code === this.$code) {

            // 单独处理排屑系统
            const ccrsstatus = res.data.find(
              f => f.deviceName === "ccrsstatus"
            );
            if (ccrsstatus) {
              const move = ccrsstatus.moveDescription;
              for (let i = 1; i <= 11; i++) {
                if (move.includes(`\\\"CCRSUnit${i}Enabled\\\": true`)) {
                  res.data.push({
                    deviceName: `CX${i}`,
                    batchNumber: null,
                    errorMsg: null,
                    param: null,
                    partDrawingNumber: null,
                    productionOrder: null,
                    status: true,
                    type: null
                  });
                } else if (
                  move.includes(`\\\"CCRSUnit${i}Enabled\\\": false`)
                ) {
                  res.data.push({
                    deviceName: `CX${i}`,
                    batchNumber: null,
                    errorMsg: null,
                    param: null,
                    partDrawingNumber: null,
                    productionOrder: null,
                    status: false,
                    type: null
                  });
                }
              }
            }
            // 单独处理冷却液系统
            const coolants = res.data.find(
              f => f.deviceName === "coolantsystemstatus"
            );
            if (coolants) {
              if (
                coolants.moveDescription.includes(
                  `\\\"CoolantUnitEnabled\\\": true`
                )
              ) {
                coolants.status = true;
              } else {
                coolants.status = false;
              }
            }

            modelConfig.forEach(item => {
              let obj = res.data.find(f => f.deviceName === item.name);
              if (obj) {
                obj = JSON.parse(JSON.stringify(obj));
                // obj.percentage = obj.percentage
                //   ? parseFloat(obj.percentage)
                //   : obj.percentage;
                obj.modelName = item.modelName;
                obj.name = item.name;
                if (item.isShowHot) {
                  obj.isShowHot = item.isShowHot;
                }
                this.deviceList.push(obj);
              }
            });
            resolve(this.deviceList);
          }
        });
      });
      // modelConfig.forEach((item) => {
      //   let obj = deviceProduct.find((f) => f.device === item.name);
      //   if (obj) {
      //     obj = JSON.parse(JSON.stringify(obj));
      //     obj.percentage = obj.percentage
      //       ? parseFloat(obj.percentage)
      //       : obj.percentage;
      //     obj.modelName = item.modelName;
      //     obj.name = item.name;
      //     if (item.isShowHot) {
      //       obj.isShowHot = item.isShowHot;
      //     }
      //     this.deviceList.push(obj);
      //   }
      // });
    },
    // 获取设备动画仿真视频
    getMachineAnimation() {
      const params = {
        partID: "",
        processingProcedureNum: ""
      };
      this.$api.device.ApiGetMachineAnimation(params).then(res => {
        if (res.code === this.$code) {
        }
      });
    },
    // 获取托盘信息，查询托盘表，返回所有托盘信息,用于前端加载库位、设备上是否有托盘，并且上面根据是否有物料盒子信息对应是否加载托盘上面的物料。
    // Machine 设备托盘、Material材料托盘 Machine
    getPalletData() {
      return new Promise(resolve => {
        const params = {
          palletName: null,
          palletId: null,
          type: null
        };
        this.$api.other.ApiGetPalletData(params).then(res => {
          if (res.code === this.$code) {
            resolve(res.data);
          }
        });
      });
    },
    // 获取所有设备实时数据
    getMachineAll() {
      const params = {
        params: {
          beginCreateDate: "2021-10-16 16:02:25",
          endCreateDate: "2021-10-16 16:04:49"
        }
      };
      // this.$api.device.ApiGetMachineAll(params).then((res) => {
      //   if (res.code === this.$code) {
      //     this.deviceAll = Object.values(res.data).map((item) => {
      //       const obj = {},
      //         list = [
      //           "Crane",
      //           "coolantsystemstatus",
      //           "ccrsstatus",
      //           "L1",
      //           "L2",
      //           "RFC",
      //           "M1",
      //           "M2",
      //           "M3",
      //           "MC1",
      //           "MC2",
      //           "MC3",
      //           "MC4",
      //           "MC5",
      //           "MC6",
      //         ];
      //       list.forEach(key => {
      //         obj[key] = item.find(f => f.device === key);
      //       })
      //       return obj;
      //     });
      //   }
      // });
      this.deviceAll = Object.values(mData).map(item => {
        const obj = {},
          list = [
            "Crane",
            "coolantsystemstatus",
            "ccrsstatus",
            "L1",
            "L2",
            "RFC",
            "M1",
            "M2",
            "M3",
            "MC1",
            "MC2",
            "MC3",
            "MC4",
            "MC5",
            "MC6"
          ];
        list.forEach(key => {
          obj[key] = item.find(f => f.device === key);
        });
        return obj;
      });
      console.log(this.deviceAll, "this.deviceAll");
    }
  },
  beforeDestroy() {
    this.$bus.$off("on-close");
  }
};
</script>
<style lang="scss" scoped>
.home-conatiner {
  width: 100%;
  height: 100%;
  background: url("../assets/img/bj_gz.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  .amodel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .panel-left {
    position: absolute;
    top: 100px;
    left: 20px;
  }
  .panel-right {
    position: absolute;
    top: 100px;
    right: 20px;
  }
  .panel-center {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    /deep/ .panel-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .bf-tip {
    position: fixed;
    left: 50%;
    top: 102px;
    transform: translateX(-50%);
    z-index: 9999;
    width: 284px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(93, 10, 0, 0.63);
    > img {
      margin-right: 10px;
    }
    > span {
      color: $font-color3;
    }
  }
  .leftSide-enter-active,
  .leftSide-leave-active {
    transition: transform 0.3s linear;
  }
  .leftSide-leave-active {
    transform: translateX(-200%);
  }
  .leftSide-enter {
    transform: translateX(-200%);
  }

  .rightSide-enter-active,
  .rightSide-leave-active {
    transition: transform 0.3s linear;
  }
  .rightSide-leave-active {
    transform: translateX(200%);
  }
  .rightSide-enter {
    transform: translateX(200%);
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s linear;
  }
  .bottomSide-enter-active,
  .bottomSide-leave-active {
    transition: transform 0.3s linear;
  }
  .bottomSide-leave-active {
    transform: translate(-50%, 200%);
  }
  .bottomSide-enter {
    transform: translate(-50%, 200%);
  }
}
</style>